import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faPen,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";

const List = ({ list, deleteContent, setUpdateData, lang }) => {
  return (
    <>
      {list &&
        list
          .sort((a, b) => (a.id > b.id ? 1 : -1))
          .map((task, index) => {
            return (
              <React.Fragment key={task.id}>
                <tr>
                  <td>{index + 1}</td>
                  <td>{task.evidenceType}</td>
                  <td>{task.evidenceDescription}</td>
                  <td>{lang === "en" ? "Attachment" : "अटैचमेंट"}</td>
                  <td>
                    <div className="iconsWrap">
                      {task.status ? null : (
                        <span
                          title="Edit"
                          onClick={() =>
                            setUpdateData({
                              id: task.id,
                              evidenceType: task.evidenceType,
                              evidenceDescription: task.evidenceDescription,
                              evidenceFile: task.evidenceFile,
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </span>
                      )}

                      <span
                        title="Delete"
                        onClick={() => deleteContent(task.id)}
                        style={{ marginLeft: "1rem" }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </span>
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
    </>
  );
};

export default List;
