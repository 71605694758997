const ShowBlog = ({blog}) => {
    return (
        <div className="blog_container">
            <div className="blog_content">
            <div className="left_content">
            <div style={{paddingTop: "1em"}}>
            {
                blog.bannerImageName && <div
                className="w-100"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  backgroundColor: "#dddddd",
                }}
              >
                <img
                  className=""
                  style={{ maxHeight: "18em" }}
                  src={`${process.env.REACT_APP_API}/blogs/banner/${blog.bannerImageId}${blog.bannerImageExt}`}
                />
              </div>
            }
    
    <div
      style={{ maxWidth: "700px", top: blog.bannerImageName ? "-80px" : "" }}
      className="mx-auto text-secondary position-relative"
    >
      <div className="text-center mb-4">
        {
          blog.authorImageName && <img
          src={`${process.env.REACT_APP_API}/blogs/author/${blog.authorImageId}${blog.authorImageExt}`}
          style={{
            width: "120px",
            border: "5px solid #eee",
            backgroundColor: "white",
          }}
          className="rounded-circle mx-auto text-center"
        />
        }
        
        <div>
          <small>
            <p href="#" className="text-primary">
              {blog.tag}
            </p>
          </small>
        </div>
        <h1 className="fs-4 text-center font-weight-bold text-dark">
          {blog.title}
        </h1>
        {
          blog.authors && <div>
          <small className="text-dark">
            Written by{" "}
            <p href="#" className="text-primary">
              {blog.authors}
            </p>
          </small>
        </div>
        }
        
      </div>
           
        <div dangerouslySetInnerHTML={{__html: blog.content}}>
            
        </div>
      
      <br />
      {
        blog.source && <div className="my-3">
        <small>
          <a
            target="_blank"
            href={blog.source}
            className="text-primary"
          >
            Original Source
          </a>
        </small>
      </div>
      }
      
    </div>
    </div>
            </div>
            </div>
        </div>
        
    )
}

export default ShowBlog;