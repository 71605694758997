import React, { useState, useEffect } from "react";
import axios from 'axios';
import locale from "../locale/mapping.json";
import "./Counter.css";
import "./About.css";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const Hero = ({ lang }) => {
  const [content, setContent] = useState(locale[lang].locations);
  const [CounterOn, setCounterOn] = useState(false);
  const [stats, setStats] = useState({});
  useEffect(() => {
    setContent(locale[lang].locations);
  }, [lang]);

  useEffect(()=>{
    axios
    .get(`${process.env.REACT_APP_API}/site/getStats`)
    .then((res) => {
      if (res.status === 200) {
        const JsonData = res.data.response
        if (Object.keys(JsonData).length) {
          setStats({...JsonData})
        }
      } else {
        setStats({})
      }
    })
    .catch((err) => {
      console.log(err)
    });
  },[])

  return (
    <>
      <div
        className="about_sec section__margin"
        data-aos="fade-up"
        data-aos-offset="100"
        data-aos-easing="ease"
        data-aos-duration="500"
      >
        <div className="about_sec_heading">
          <h1 className="gradient__text">{content.title}</h1>
        </div>
        <div className="about_container row">
          <section
            id="counter-stats"
            className="wow fadeInRight col"
            data-wow-duration="1.4s"
          >
            <div className="container">
              <ScrollTrigger
                onEnter={() => {
                  setCounterOn(true);
                }}
                onExit={() => {
                  setCounterOn(false);
                }}
                className="location"
              >
                <div className="counter_row">
                  <div className="col stats">
                    <h1>
                      {CounterOn && (
                        <CountUp start={0} end={stats.districts} duration={1.5} delay={0} />
                      )}
                    </h1>
                    <h5>{content.l1}</h5>
                  </div>

                  <div className="col stats">
                    <h1>
                      {CounterOn && (
                        <CountUp start={0} end={stats.states} duration={1.5} delay={0} />
                      )}
                    </h1>
                    <h5>{content.l2}</h5>
                  </div>

                  <div className="col stats">
                    <h1>
                      {CounterOn && (
                        <CountUp start={0} end={stats.ut} duration={1.5} delay={0} />
                      )}
                    </h1>
                    <h5>{content.l3}</h5>
                  </div>
                </div>
              </ScrollTrigger>
            </div>
          </section>
          <img src="images/location2.svg" alt="" className="col cnt-img" />
        </div>
      </div>
    </>
  );
};

export default Hero;

// old section
{
  /* <section
        id="counter-stats"
        className="wow fadeInRight"
        data-wow-duration="1.4s"
      >
        <div className="container">
          <ScrollTrigger
            onEnter={() => {
              setCounterOn(true);
            }}
            onExit={() => {
              setCounterOn(false);
            }}
          >
            <div className="row">
              <div className="col-lg-3 stats">
                <h1>Districts</h1>
                <h5>Employees Trained In Making Workplaces Safe</h5>
              </div>

              <div className="col-lg-3 stats">
                <h1>States</h1>
                <h5>Institutions Trained</h5>
              </div>

              <div className="col-lg-3 stats">
                <h1>Union Territories</h1>
                <h5>Youth Collectivised To Address VAWG</h5>
              </div>
            </div>
          </ScrollTrigger>
        </div>
      </section> */
}
