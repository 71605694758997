import React, { useState, useEffect } from "react";
import locale from "../locale/mapping.json";
import "./About.css";
import { Link } from "react-router-dom";

const About = ({ lang }) => {
  const [content, setContent] = useState(locale[lang].mainAbout);
  useEffect(() => {
    setContent(locale[lang].mainAbout);
  }, [lang]);
  return (
    <div
      className="about_sec section__margin"
      data-aos="fade-up"
      data-aos-offset="100"
      data-aos-easing="ease"
      data-aos-duration="500"
    >
      <div className="about_sec_heading">
        <h1 className="gradient__text">{content.title}</h1>
      </div>
      <div className="about_container row">
        <div
          className="column video"
          data-aos="slide-right"
          data-aos-easing="ease"
          data-aos-duration="800"
          style={{ display: "flex", alignItems: "center" }}
        >
          <iframe
            src="https://www.youtube-nocookie.com/embed/Z7bgbMGomiU"
            height="100%"
            width="100%"
            style={{ maxHeight: "25em" }}
          ></iframe>
        </div>
        {lang === "en" ? (
          <div
            className="column content"
            data-aos="slide-left"
            data-aos-easing="ease"
            data-aos-duration="800"
          >
            {content.d1} <br />
            <br />
            {content.d2}
            <br />
            <br />
            {content.d3} <br />
            <br />
            {content.d4} <br />
            <br />
            {content.d5}
            <Link to="/login" style={{ color: "blue" }}>
              {" "}
              {content.d6}{" "}
            </Link>{" "}
            {content.d7}
            <br />
            <br />
            <button
              type="button"
              className="btn btn-primary"
              style={{ backgroundColor: "#7371ee" }}
              data-bs-toggle="modal"
              data-bs-target="#about_readmore"
            >
              {content.d8}
            </button>
          </div>
        ) : (
          <div
            className="column content"
            data-aos="slide-left"
            data-aos-easing="ease"
            data-aos-duration="800"
          >
            {content.d1} <br />
            <br />
            {content.d2}
            <br />
            <br />
            <a
              href="https://surakshachakra.myambar.org/complaint-form"
              target="_blank"
              style={{ color: "blue" }}
            >
              {" "}
              {content.d3}{" "}
            </a>{" "}
            {content.d4}
            <br />
            <br />
            <button
              type="button"
              className="btn btn-primary"
              style={{ backgroundColor: "#7371ee" }}
              data-bs-toggle="modal"
              data-bs-target="#about_readmore"
            >
              {content.d5}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default About;
