import React from "react";
import axios from 'axios';
import "./ResourceCenter.css";
import { useState, useEffect } from "react";
import { VideosList } from "./Resources";

const Videos = () => {
  const [resource, setResource] = useState([]);
  const [videos, setVideos] = useState([]);
  const [fabdisplay, setfabdisplay] = useState(false);

  const handleChecked = () => {
    setfabdisplay(!fabdisplay);
  };

  const handleResource = (data) => {
    setResource(data);
    handleChecked();
  };

  const mapData = (data) => {
    return data.map((d,i) => {
      return {
        id: d.id,
        sid: i+1,
        title: d.title,
        thumbnail: d.thumbnail,
        url: d.embedURL,
        date: new Date(d.date).toLocaleDateString('en-US', {
          year: 'numeric', month: 'long', day: 'numeric'
        }),
        description: d.description
      }
    })
  }

  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_API}/videos/readAll`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.response?.length) {
          const data = mapData(res.data.response)
          setVideos([...data])
          setResource({...data[0]})
        }
      } else {
        setVideos([])
      }
    })
    .catch((err) => {
      console.log(err)
    });
  }, [])

  useEffect(() => {
    if (fabdisplay || screen.width >= 1000)
      document.getElementsByClassName("order-last")[0].style.display = "block";
    else
      document.getElementsByClassName("order-last")[0].style.display = "none";

    videos.map((element, index) => {
      if (resource.sid - 1 === index) {
        document.querySelectorAll(".sidebar.widget ul li")[
          index
        ].style.outlineColor = "#009688";
        // document.querySelectorAll(".sidebar.widget ul li")[index].style.color =
        //   "white";
        // document.querySelectorAll(".sidebar.widget ul li span")[
        //   index
        // ].style.color = "white";
        // document.querySelectorAll(".sidebar-meta span i")[index].style.color =
        //   "white";
      } else {
        document.querySelectorAll(".sidebar.widget ul li")[
          index
        ].style.outlineColor = "transparent";
        // document.querySelectorAll(".sidebar.widget ul li")[index].style.color =
        //   "black";
        // document.querySelectorAll(".sidebar.widget ul li span")[
        //   index
        // ].style.color = "black";
        // document.querySelectorAll(".sidebar-meta span i")[index].style.color =
        //   "#2996bde";
      }
    });
  }, [fabdisplay, resource]);

  return (
    <>
      <div
        className="resource vid"
        data-aos="fade-up"
        data-aos-offset="100"
        data-aos-easing="ease"
        data-aos-duration="500"
      >
        <div className="row">
          <div className="col-8 order-first">
            <div className="video-container">
              <iframe
                className="resource-video"
                id="re"
                src={resource.url}
                width="560"
                height="315"
                allow="encrypted-media"
                allowFullScreen=""
                key={resource.url}
              ></iframe>
              <h3 style={{ fontSize: "revert" }}>{resource.title}</h3>
              {resource.description ? (
                <div>
                  <h3 style={{ fontSize: "revert", display: "block" }}>Description</h3>
                  <br />
                  <p>{resource.description}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-4 order-last">
            <div className="sidebar widget">
              <div style={{position: "sticky", top: "0", zIndex: 20000}}>
                <h4>Videos</h4>
              </div>

              <ul>
                {videos.map((data, index) => {
                  return (
                    <VideosList
                      data={data}
                      handleResource={handleResource}
                      key={index}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="wrapper" onClick={handleChecked}>
          <div className="fas fa-chevron-circle-up"></div>
        </div>
      </div>
    </>
  );
};

export default Videos;
