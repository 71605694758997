import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import locale from '../locale/mapping.json';
import axios from 'axios';
import './FAQ.css';

const liVariants = {
  show: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 100, velocity: -100 },
    },
  },
  hide: {
    y: 100,
    opacity: 0,
    transition: {
      y: { stiffness: 100 },
    },
  },
};

const pvariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const FAQ = ({ lang }) => {
  const [content, setContent] = useState(locale[lang].faq);
  const [search, setSearch] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);
  const [groupedData, setGroupedData] = useState({});
  const [faqData, setFaqData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [tagMap, setTagMap] = useState({});

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/faq/getTags`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.response?.length) {
            setTagData(res.data.response);
          }
        } else {
          setTagData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [faqData]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/faq/readAll`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.response?.length) {
            setFaqData([...res.data.response]);
          }
        } else {
          setFaqData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const tempp = {};
    const tagKey = `name_${lang}`;

    for (const tag of tagData) {
      const imgUrl = `${process.env.REACT_APP_API}/faqTags/${tag.tagImageId}${tag.tagImageExt}`;
      const faqs = [];

      for (const faq of faqData) {
        const tagId = faq[`tag_${lang}`];
        if (tagId === tag[tagKey]) {
          faqs.push({ ...faq });
        }
      }

      tempp[tag[tagKey]] = { imgUrl, qna: [...faqs] };
    }

    setGroupedData({ ...tempp });
  }, [tagData]);

  useEffect(() => {
    setContent(locale[lang].faq);
  }, [lang]);

  useEffect(() => {
    const allTags = tagData;
    allTags.map((currTag) => {
      tagMap[currTag.name] = `${currTag.tagImageId}${currTag.tagImageExt}`;
    });
  }, [tagData]);

  useEffect(() => {
    const quesKey = `question_${lang}`;
    const ansKey = `answer_${lang}`;
    const filteredItems = faqData.filter(
      (item) =>
        item[quesKey].toLowerCase().includes(search.toLowerCase()) &&
        search.length
    );
    const results = filteredItems.map((item) => ({
      id: item.id,
      ques: item[quesKey],
      ans: item[ansKey],
    }));
    setSearchResult([...results]);

    if (searchResult.length === 0) {
      setShowAnswer(false);
    }
  }, [search]);

  const handleClick = (e) => {
    Array.from(document.querySelectorAll('.c-faq')).forEach((ele, ind) => {
      ele.classList.remove('c-faq--active');
    });
    e.target.parentNode.classList.add('c-faq--active');
  };

  return (
    <div
      data-aos="fade-up"
      data-aos-offset="100"
      data-aos-easing="ease"
      data-aos-duration="500"
      className="faq"
    >
      <div className="about_main">
        <div className="about_main-content">
          <h1>{content.title}</h1>
        </div>
      </div>
      <div className="about-container">
        <div
          className="about_sec section__margin"
          style={{ padding: '0px', marginTop: '0px' }}
        >
          <div className="container">
            <div className="about_sec_heading help-heading">
              <h1 className="gradient__text ">{content.subTitle}</h1>
            </div>
            <div className="main-search mb-8 rounded-lg shadow-lg px-6 py-3 w-full flex items-center space-x-6 border border-gray-200 border-opacity-75">
              <button className="focus:outline-none ">
                <svg
                  className="w-6 h-6 text-gray-500"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </button>
              <input
                x-ref="search"
                type="text"
                placeholder={content.searchText}
                className="ml-8 text-base w-full bg-transparent focus:outline-none"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            {searchResult.length ? (
              <motion.ul
                animate={searchResult.length ? 'show' : 'hide'}
                initial="hide"
                style={{ marginBottom: '20px', listStyle: "none" }}
              >
                {searchResult.map((item, index) => (
                  <motion.li
                    key={index}
                    className="searchlist"
                    variants={liVariants}
                    whileHover={{ scale: 1.05 }}
                    onClick={() => setShowAnswer((prev) => !prev)}
                  >
                    <b>{item.ques}</b>

                    {showAnswer && (
                      <div
                        className="accordion-body"
                        dangerouslySetInnerHTML={{
                          __html: item.ans,
                        }}
                        style={{ marginTop: '5px' }}
                      ></div>
                    )}
                  </motion.li>
                ))}
              </motion.ul>
            ) : (
              ''
            )}
            {/* <div className="main-images mb-8">
              <div className="images grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="image bg-white rounded-lg shadow-lg overflow-hidden">
                  <a href="#" style={{ textAlign: '-webkit-center' }}>
                    <img
                      src="images/Complainant support.jpg"
                      alt="Contact with Customer support"
                      title="Contact with Customer support"
                      style={{ maxHeight: '9em' }}
                    />
                    <span className="text-center p-2 text-gray-700 text-sm inline-block w-full">
                      {content.img1}
                    </span>
                  </a>
                </div>

                <div className="image bg-white rounded-lg shadow-lg overflow-hidden">
                  <a href="#" style={{ textAlign: '-webkit-center' }}>
                    <img
                      src="images/Send Message.jpg"
                      alt="Send message to support disk"
                      title="Send message to support disk"
                      style={{ maxHeight: '9em' }}
                    />
                    <span className="text-center p-2 text-gray-700 text-sm inline-block w-full">
                      {content.img2}
                    </span>
                  </a>
                </div>

                <div className="image bg-white rounded-lg shadow-lg overflow-hidden">
                  <a href="#" style={{ textAlign: '-webkit-center' }}>
                    <img
                      src="images/Write Feedback.png"
                      alt="Write your feedback"
                      title="Write your feedback"
                      style={{ maxHeight: '9em' }}
                    />
                    <span className="text-center p-2 text-gray-700 text-sm inline-block w-full">
                      {content.img3}
                    </span>
                  </a>
                </div>
              </div>
            </div> */}
            <div className="container">
              <div className="container bg-white rounded p-4 pt-2">
                <div>
                  {Object.keys(groupedData).map((tag, index) => (
                    <div key={tag}>
                      {groupedData[tag].qna.length > 0 && (
                        <>
                          <h2
                            style={{
                              color: '#258d65',
                            }}
                            className="tag_heading my-5 mb-2 text-2xl"
                          >
                            {groupedData[tag].imgUrl && (
                              <img src={groupedData[tag].imgUrl} />
                            )}
                            <div className="tag_name">{tag}</div>
                          </h2>

                          <div
                            className="accordion"
                            id="accordionPanelsStayOpenExample"
                          >
                            {groupedData[tag]?.qna.map((faq) => (
                              <div key={faq.id} className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id={`panelsStayOpen-heading${index}${faq.id}`}
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    style={{
                                      backgroundColor: '#80CBC4',
                                      color: 'black',
                                    }}
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#panelsStayOpen-collapse${index}${faq.id}`}
                                    aria-expanded="false"
                                    aria-controls={`panelsStayOpen-collapse${index}${faq.id}`}
                                  >
                                    {faq[`question_${lang}`]}
                                  </button>
                                </h2>
                                <div
                                  id={`panelsStayOpen-collapse${index}${faq.id}`}
                                  className="accordion-collapse collapse"
                                  aria-labelledby={`panelsStayOpen-heading${index}${faq.id}`}
                                  data-bs-parent="#accordionPanelsStayOpenExample"
                                >
                                  <div
                                    className="accordion-body"
                                    dangerouslySetInnerHTML={{
                                      __html: faq[`answer_${lang}`],
                                    }}
                                  ></div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;

// {window.innerWidth >= 780 ? (
//   <div className="c-faqs-cont">
//     <div className="left-column">
//       <Box
//         img_src="images/complaint.png"
//         uid="#qa1"
//         data={content.qa1}
//         currId="qa1"
//         currData="<p>currentData</p>"
//       />
//       <Box
//         img_src="images/process.png"
//         uid="#qa2"
//         data={content.qa2}
//       />
//       <Box img_src="images/law.png" uid="#qa3" data={content.qa3} />

//       <Box
//         img_src="images/troubleshoot.png"
//         uid="#qa7"
//         data={content.qa7}
//       />
//       <Box
//         img_src="images/profile.png"
//         uid="#qa5"
//         data={content.qa5}
//       />
//       <Box img_src="images/app.png" uid="#qa6" data={content.qa6} />
//       <Box
//         img_src="images/security.png"
//         uid="#qa4"
//         data={content.qa4}
//       />
//       <Box
//         img_src="images/contact.png"
//         uid="#qa8"
//         data={content.qa8}
//       />
//     </div>
//     <div className="right-column">
//       <Box
//         img_src="images/profile.png"
//         uid="#qa5"
//         data={content.qa5}
//       />
//       <Box img_src="images/app.png" uid="#qa6" data={content.qa6} />
//       <Box
//         img_src="images/security.png"
//         uid="#qa4"
//         data={content.qa4}
//       />
//       <Box
//         img_src="images/contact.png"
//         uid="#qa8"
//         data={content.qa8}
//       />
//     </div>
//   </div>
// ) : (
//   <div className="c-faqs">
//     <div className="row">
//       <Box
//         img_src="images/complaint.png"
//         uid="#qa1"
//         data={content.qa1}
//       />
//       <Box
//         img_src="images/process.png"
//         uid="#qa2"
//         data={content.qa2}
//       />
//     </div>
//     <div className="row">
//       <Box img_src="images/law.png" uid="#qa3" data={content.qa3} />
//       <Box
//         img_src="images/security.png"
//         uid="#qa4"
//         data={content.qa4}
//       />
//     </div>
//     <div className="row">
//       <Box
//         img_src="images/profile.png"
//         uid="#qa5"
//         data={content.qa5}
//       />
//       <Box img_src="images/app.png" uid="#qa6" data={content.qa6} />
//     </div>
//     <div className="row">
//       <Box
//         img_src="images/troubleshoot.png"
//         uid="#qa7"
//         data={content.qa7}
//       />
//       <Box
//         img_src="images/contact.png"
//         uid="#qa8"
//         data={content.qa8}
//       />
//     </div>
//   </div>
// )}
