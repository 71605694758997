import React from "react";

const Wform = ({
  wname,
  wnum,
  setWname,
  setWnum,
  waddContent,
  error,
  setError,
  lang
}) => {
  return (
    <form
      name="add_witness_form"
      id="add_witness_form"
      encType="multipart/form-data"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{lang === "en" ? "Add Witness" : "गवाह डाले"}</h5>
          <button
            className="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div className="form-group form-group-multi row">
            <label
              htmlFor="group"
              className="col-md-3 col-form-label text-md-right"
            >
              {
                lang === "en" ? "Witness Name" : "गवाह का नाम"
              }
            </label>
            <div className="col-md-8">
              <input
                className="form-control"
                id="witness_name"
                name="witness_name"
                type="text"
                value={wname}
                onChange={(e) => {
                  const value = e.target.value;
                  const name = e.target.id || e.target.name;
                  if (error[name].validation.test(value)) {
                    setError({
                      ...error,
                      [name]: { ...error[name], isValid: true },
                    });
                  } else {
                    setError({
                      ...error,
                      [name]: { ...error[name], isValid: false },
                    });
                  }
                  setWname(e.target.value);
                }}
              />
              {error.witness_name.isValid === false && (
                <div
                  className="invalid-feedback"
                  style={{
                    display: "block",
                    marginTop: "-1.5rem",
                    marginBottom: "0.2rem",
                  }}
                >
                  {error.witness_name.inValid}
                </div>
              )}
            </div>
          </div>
          <div className="form-group form-group-multi row">
            <label
              htmlFor="group"
              className="col-md-3 col-form-label text-md-right"
            >
              {lang === "en" ? "Contact Number" : "संपर्क संख्या"}
            </label>
            <div className="col-md-8">
              <input
                className="form-control"
                id="witness_contact_number"
                name="witness_contact_number"
                type="text"
                value={wnum}
                onChange={(e) => {
                  const value = e.target.value;
                  const name = e.target.id || e.target.name;
                  if (error[name].validation.test(value)) {
                    setError({
                      ...error,
                      [name]: { ...error[name], isValid: true },
                    });
                  } else {
                    setError({
                      ...error,
                      [name]: { ...error[name], isValid: false },
                    });
                  }
                  setWnum(e.target.value);
                }}
              />
              {error.witness_contact_number.isValid === false && (
                <div
                  className="invalid-feedback"
                  style={{
                    display: "block",
                    marginTop: "-1.5rem",
                    marginBottom: "0.2rem",
                  }}
                >
                  {error.witness_contact_number.inValid}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          {/* <button
            className="btn btn-light"
            type="button"
            id="TableModalClose"
            data-dismiss="modal"
          >
            Close
          </button> */}
          <button
            onClick={() => waddContent()}
            type="button"
            className="btn btn-primary"
          >
            {
              lang === "en" ? "Add Witness" : "गवाह जोड़ें"
            }
          </button>
        </div>
      </div>
    </form>
  );
};

export default Wform;
