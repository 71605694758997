import React, { useState, useEffect } from "react";
import locale from "../locale/mapping.json";
import "./Partners.css";

const Partners = ({ lang }) => {
  const [content, setContent] = useState(locale[lang].partners);
  useEffect(() => {
    setContent(locale[lang].partners);
  }, [lang]);
  return (
    <div
      className="partners"
      data-aos="fade-up"
      data-aos-offset="100"
      data-aos-easing="ease"
      data-aos-duration="500"
    >
      <h1 className="text-center">{content.title}</h1>
      <div className="container">
        <div
          className="card"
          data-aos-delay="300"
          data-aos-offset="0"
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-duration="700"
        >
          <div className="face face1">
            <div className="content">
              <img src="images/vilogo.png" />
              <h3>{content.p1}</h3>
            </div>
          </div>
          <div className="face face2">
            <div className="content">
              <h1>Vodafone Idea Foundation</h1>
              <a
                href="https://www.learningwithvodafoneidea.in/"
                target="_blank"
              >
                Know More
              </a>
            </div>
          </div>
        </div>
        <div
          className="card"
          data-aos-delay="500"
          data-aos-offset="0"
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-duration="700"
        >
          <div className="face face1">
            <div className="content">
              <img src="images/MFFLogo.jpg" />
              <h3>{content.p3}</h3>
            </div>
          </div>
          <div className="face face2">
            <div className="content">
              <h1>Martha Farrell Foundation</h1>
              <a
                href="https://www.marthafarrellfoundation.org/"
                target="_blank"
              >
                Know More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
