import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";
import "./TrackComplaint.css";

const TrackComplaint = ({ lang }) => {
  const navigate = useNavigate();
  const mffUser = JSON.parse(window.sessionStorage.getItem("mffUser"));
  const [case_id, setCaseId] = useState("");
  const [complaints, setComplaints] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filteredComplaints, setFilteredComplaints] = useState([...complaints]);
  const handleChange = (e) => {
    setCaseId(e.target.value);
    const temp = complaints.filter((c) =>
      c.case_id.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredComplaints(temp);
  };

  useEffect(() => {
    const user_id = mffUser.user_id;
    const token = mffUser.token;
    axios
      .post("https://backoffice.myambar.org/api/complaint-details", {
        user_id,
        token,
      })
      .then((response) => {
        if (response.data.status_code === 440) {
          navigate("/login");
          window.sessionStorage.removeItem("mffUser");
        }
        setComplaints([...response.data?.data]);
      })
      .catch((error) => {
        setComplaints([]);
      });
  }, []);

  useEffect(() => {
    setFilteredComplaints([...complaints]);
  }, [complaints]);

  const downloadReport = (case_id) => {
    setLoader(true);
    const user_id = mffUser.user_id;
    const token = mffUser.token;
    axios
      .post("https://backoffice.myambar.org/api/download-complaint-receipt", {
        user_id,
        token,
        case_id,
      })
      .then((response) => {
        const url = response.data;
        if (!url) {
          console.log("Unable To download receipt");
        } else {
          const link = document.createElement("a");
          link.href = url;
          link.target = "__blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        setLoader(false);
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <div className="track-complaint">
      {!loader ? (
        <>
          <div className="about_main container" style={{ marginTop: "0px" }}>
            <div
              href="#"
              className="float"
              onClick={() => navigate("/user-menu")}
            >
              <i className="fa-solid fa-house-user my-float fa-2x"></i>
              {lang === "en" ? "Return Home" : "होमपेज पर वापस जाएँ "}
            </div>
            <div className="" style={{ paddingTop: "2em" }}>
              <h1>
                {lang === "en" ? "Track a Complaint" : "शिकायत ट्रैक करें"}
              </h1>
            </div>
          </div>
          <div
            className="container"
            style={{
              width: "100vw",
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
            }}
          >
            {/* {complaints.map((complaint) => (
          <ComplaintCard
            key={complaint.id}
            date={complaint.date}
            name={complaint.name}
            caseId={complaint.caseId}
            status={complaint.status}
            report={complaint.report}
          />
        ))} */}
            <table>
              <thead>
                <tr>
                  <td style={{ padding: "0px" }}>
                    <div className="case-search">
                      <input
                        className="case-search-input"
                        type="text"
                        name=""
                        value={case_id}
                        onChange={handleChange}
                        placeholder={lang === "en" ? "Case Id" : "केस आईडी"}
                      />
                    </div>
                  </td>
                </tr>
                {lang === "en" ? (
                  <tr>
                    <th>Date of Complaint</th>
                    <th>Name of Complainant</th>
                    <th>Case ID</th>
                    <th>Status</th>
                    <th>Download Acknowledgement</th>
                  </tr>
                ) : (
                  <tr>
                    <th>शिकायत की तिथि</th>
                    <th>शिकायतकर्ता का नाम</th>
                    <th>केस आईडी</th>
                    <th>स्थिति</th>
                    <th>पावती डाउनलोड करें</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {filteredComplaints.length ? (
                  filteredComplaints.map((complaint, index) => (
                    <tr key={index}>
                      <td>
                        <span className="label">
                          {lang === "en"
                            ? "Date of Complaint"
                            : "शिकायत की तिथि"}
                        </span>{" "}
                        {new Date(
                          complaint.created_at.replace(/\s[AP]M$/i, "")
                        ).toDateString()}
                      </td>
                      <td>
                        <span className="label">
                          {lang === "en"
                            ? "Name of Complainant"
                            : "शिकायतकर्ता का नाम"}
                        </span>{" "}
                        {complaint.full_name}
                      </td>
                      <td>
                        <span className="label">
                          {lang === "en" ? "Case ID" : "केस आईडी"}
                        </span>{" "}
                        {complaint.case_id}
                      </td>
                      <td>
                        <div className={`status-icon`}>{complaint.status}</div>
                      </td>
                      <td>
                        <button
                          className={`report-icon`}
                          onClick={() => downloadReport(complaint.case_id)}
                        >
                          <i className="fa fa-download" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5}>
                      {lang === "en"
                        ? "No Records Available"
                        : "कोई रिकॉर्ड उपलब्ध नहीं है"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <SyncLoader
            color="#fff"
            style={{
              display: "flex",
              height: "100vh",
              width: "100vw",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
          <div
            style={{
              marginBottom: "10rem",
              marginTop: "-18rem",
              color: "#fff",
              fontSize: "1.2rem",
            }}
          >
            {lang === "en"
              ? "Your file will be downloaded soon..."
              : "आपकी फ़ाइल जल्द ही डाउनलोड हो जाएगी..."}
          </div>
        </div>
      )}
    </div>
  );
};

const ComplaintCard = ({ date, name, caseId, status, report }) => {
  return (
    <motion.div
      className="card"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="card-header">
        <h3 className="card-title">Complaint Information</h3>
        <div className="card-icons">
          <i className="fas fa-download"></i>
        </div>
      </div>
      <div className="card-body">
        <p>
          <span>Date of Complaint: </span>
          {date}
        </p>
        <p>
          <span>Name of Complainant: </span>
          {name}
        </p>
        <p>
          <span>Case ID: </span>
          {caseId}
        </p>
        <p>
          <span>Check Status: </span>
          {status}
        </p>
        <p>
          <span>Download Acknowledgement: </span>
          {report}
        </p>
      </div>
    </motion.div>
  );
};

export default TrackComplaint;
