import "./Register.css";
import { useState, useEffect } from "react";
// import stateDistricts from './resources/stateDistrict';
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SyncLoader from "react-spinners/SyncLoader";
import locale from "../locale/mapping.json";

const options = [
  {
    label: "File a complaint",
    label_hi: "शिकायत दर्ज करें",
    link: "/complaint-form",
    task: () => {},
    icon: (
      <i className="fa-solid fa-user-pen" style={{ fontSize: "2.5rem" }}></i>
    ),
  },
  {
    label: "View filed complaints",
    label_hi: "दर्ज की गई शिकायतें देखें",
    link: "/track-complaint",
    task: () => {},
    icon: (
      <i className="fa-regular fa-id-card" style={{ fontSize: "2.5rem" }}></i>
    ),
  },
  {
    label: "Get support",
    label_hi: "मदद चाहते हैं?",
    link: "#",
    task: (e) => {
      window.location.href = "mailto:surakshachakra@myambar.org";
      e.preventDefault();
    },
    icon: (
      <i className="fa-solid fa-headset" style={{ fontSize: "2.5rem" }}></i>
    ),
  },
];
const Timer = ({ duration, count }) => {
  const [timer, setTimer] = useState(duration);

  useEffect(() => {
    let intervalId = null;
    if (count !== 0) {
      intervalId = setInterval(() => {
        const minutes = parseInt(timer / 60, 10);
        const seconds = parseInt(timer % 60, 10);
        const display = `${minutes < 10 ? "0" : ""}${minutes}:${
          seconds < 10 ? "0" : ""
        }${seconds}`;
        setTimer((prevTimer) => prevTimer - 1);
        if (timer < 0) {
          clearInterval(intervalId);
          window.location.reload(true);
        }
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [count, timer]);

  return <div id="time">{`${Math.floor(timer / 60)}:${timer % 60}`}</div>;
};

const Register = ({ lang }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const toggle = () => {
    navigate("/user-menu");
  };
  const [content, setContent] = useState(locale[lang].register);
  useEffect(() => {
    setContent(locale[lang].register);
  }, [lang]);

  useEffect(() => {
    if (location.pathname === "/login") {
      document.getElementById("container").classList.add("sign-in");
      document.getElementById("container").classList.remove("user-board");
      document.getElementById("add-margin").classList.add("add-margin");
      document.getElementById("container").classList.remove("increase-height");
    } else {
      document.getElementById("container").classList.remove("sign-in");
      document.getElementById("container").classList.add("user-board");
      document.getElementById("add-margin").classList.remove("add-margin");
      document.getElementById("container").classList.add("increase-height");
    }
  }, []);
  // useEffect(() => {

  //   setTimeout(() => {
  //     document.getElementById("container").classList.toggle("sign-in");
  //   }, 200);
  // }, []);

  // const [states, setStates] = useState(Object.keys(stateDistricts));
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isTimer, setIsTimer] = useState(false);
  const [OTPError, setOTPError] = useState("");
  const [OTPMsg, setOTPMsg] = useState("");
  const [VerifyError, setVerifyError] = useState("");
  const [caseId, setCaseId] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedTab, setSelectedTab] = useState("login");
  const [error, setError] = useState({
    first_name: {
      valid: "",
      inValid: "Can only contain characters!",
      inValid_hi: "केवल वर्ण हो सकते हैं!",
      isValid: undefined,
      validation: /^[a-zA-Z ,.'-]+$/,
    },
    last_name: {
      valid: "",
      inValid: "Can only contain characters!",
      inValid_hi: "केवल वर्ण हो सकते हैं!",
      isValid: undefined,
      validation: /^[a-zA-Z ,.'-]+$/,
    },
    phone: {
      valid: "",
      inValid: "Enter correct Phone Number!",
      inValid_hi: "सही फ़ोन नंबर दर्ज करें!",
      isValid: undefined,
      validation: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    },
    otp: {
      valid: "",
      inValid: "Enter correct OTP!",
      inValid_hi: "सही ओटीपी दर्ज करें!",
      isValid: undefined,
      validation: /^[0-9]{6}$/,
    },
  });
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    otp: "",
  });
  // const handleState = (e) => {
  //   setDistricts(stateDistricts[e.target.value]);
  // };

  function handleChange(event) {
    const { name, value } = event.target;
    if (error[name].validation.test(value)) {
      setError({ ...error, [name]: { ...error[name], isValid: true } });
    } else {
      setError({ ...error, [name]: { ...error[name], isValid: false } });
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // console.log(formData);
  }

  const getOTP = () => {
    axios
      .post("https://backoffice.myambar.org/api/otp-generate", {
        personal_mobile: formData.phone,
      })
      .then((response) => {
        setOTPMsg(
          lang == "en"
            ? response.data?.message
            : "आपके मोबाइल नंबर पर ओटीपी भेजा गया"
        );
        setIsTimer(true);
      })
      .catch((error) => {
        const err =
          lang === "en"
            ? "Unable to generate OTP"
            : "ओटीपी जनरेट करने में असमर्थ";
        setOTPError(err);
        setIsTimer(false);
      });
  };

  const logOut = () => {
    window.sessionStorage.removeItem("mffUser");
    window.location.reload();
  };

  const handleStatusCheck = () => {
    setLoader(true);
    setIsTimer(false);
    axios
      .post(
        "https://backoffice.myambar.org/api/check-status-download-complaint-receipt",
        {
          mobile_number: formData.phone,
          case_id: caseId,
        }
      )
      .then((response) => {
        if (response.data?.result === false) {
          if (lang === "en") setVerifyError(response.data?.message);
          else
            setVerifyError(
              "दिए गए मोबाइल नंबर या केस आईडी के साथ कोई केस दर्ज नहीं किया गया है।"
            );
        } else {
          const url = response.data?.data?.url || response.data.url;
          if (!url) {
            console.log("Unable To download receipt");
          } else {
            const link = document.createElement("a");
            link.href = url;
            link.target = "__blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
        setCaseId("");
        setFormData({ ...formData, phone: "" });
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setVerifyError(error);
      });
  };

  const verifyUser = () => {
    axios
      .post("https://backoffice.myambar.org/api/otp-validate", {
        personal_mobile: formData.phone,
        otp: formData.otp,
        first_name: formData.first_name,
        last_name: formData.last_name,
      })
      .then((response) => {
        if (response.data?.result === false) {
          setVerifyError(response.data?.message);
        } else {
          const feed = response.data?.data[0];
          window.sessionStorage.setItem(
            "mffUser",
            JSON.stringify({
              ...feed,
            })
          );
          toggle();
        }
      })
      .catch((error) => {
        const err =
          lang === "en"
            ? "Unable to Verify User"
            : "उपयोगकर्ता को सत्यापित करने में असमर्थ";
        setVerifyError(err);
      });
  };
  function handleSubmit(event) {
    event.preventDefault();
    setFormErrors(validateForm(formData));
    setIsSubmit(true);
  }
  // useEffect(() => {
  //   console.log(formErrors);
  //   if (Object.keys(formErrors).length === 0 && isSubmit) {
  //     console.log(formData);
  //   }
  // }, [formErrors]);
  function validateForm(values) {
    const errors = {};
    const email_regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const phone_regex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;
    if (!values.first_name) {
      errors.first_name = "First Name is required!";
      errors.first_name_hi = "पहला नाम आवश्यक है!";
    }
    if (!values.last_name) {
      errors.last_name = "Last Name is required!";
      errors.last_name_hi = "अंतिम नाम आवश्यक है!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
      errors.email_hi = "ईमेल की जरूरत है!";
    } else if (!email_regex.test(values.email)) {
      errors.email = "Please enter a valid email address";
      errors.email_hi = "कृपया एक मान्य ईमेल पता प्रविष्ट करें";
    }
    if (!values.phone) {
      errors.phone = "Phone Number is required!";
      errors.phone_hi = "फ़ोन नंबर आवश्यक है!";
    } else if (!phone_regex.test(values.phone)) {
      errors.phone = "Please enter a valid Phone Number";
      errors.phone_hi = "एक मान्य दूरभाष क्रमांक दर्ज करे";
    }
    if (!values.otp) {
      errors.otp = "OTP is required!";
      errors.otp_hi = "ओटीपी आवश्यक है!";
    }

    return errors;
  }
  return (
    <div className="register">
      <div id="container" className="container">
        {/* FORM SECTION */}
        <div className="row">
          {/* USER SECTION */}
          <div
            className="col align-items-center flex-col user-board"
            id="add-margin"
          >
            <div className="form-wrapper align-items-center">
              <div className="form user-board">
                <div className="main-page">
                  <h1 className="reg_title">
                    {lang === "en" ? "Choose an Option" : "एक विकल्प चुनें"}
                  </h1>
                  <motion.div
                    className="options-container"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    {options.map((option, index) => (
                      <motion.a
                        key={index}
                        className="option"
                        href={option.link}
                        onClick={option.task}
                        whileHover={{
                          scale: 1.1,
                          // y: -5, // move the element up slightly
                        }}
                        // whileTap={{
                        //   scale: 0.9,
                        //   y: 5, // move the element down slightly
                        //   rotate: -2, // add a slight rotation in the opposite direction
                        // }}
                        transition={{ duration: 0.2 }}
                      >
                        {option.icon}
                        {lang === "en" ? option.label : option.label_hi}
                      </motion.a>
                    ))}
                    <p
                      className="option"
                      style={{
                        transform: "none",
                        cursor: "default",
                        boxShadow: "none",
                        outlineColor: "none",
                        outlineOffset: "0px",
                        border: "none",
                      }}
                    >
                      {lang === "en"
                        ? "If you want to reach out to your Local Committee in person, kindly contact your DC/DM Office."
                        : "यदि आप व्यक्तिगत रूप से अपनी स्थानीय समिति तक पहुंचना चाहते हैं तो कृपया अपने डीसी/डीएम कार्यालय से संपर्क करें।"}
                    </p>
                  </motion.div>
                  <div className="logout-btn">
                    <button id="logout-btn" type="button" onClick={logOut}>
                      {lang === "en" ? "Log out" : "लॉग आउट"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="form-wrapper align-items-center">
              <div className="form user-board">
                <div className="input-group">
                  <i className="bx bxs-user" />
                  <input type="text" placeholder="First Name" />
                </div>
                <div className="input-group">
                  <i className="bx bxs-user" />
                  <input type="text" placeholder="Last Name" />
                </div>
                <div className="input-group">
                  <i className="fas fa-map-marker"></i>
                  <select className="form-control" onChange={handleState}>
                    <option value="state" defaultValue>
                      Select State
                    </option>
                    {states.map((state) => (
                      <option value={state} key={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-group">
                  <i className="fas fa-map-marker"></i>
                  <select className="form-control">
                    <option value="district" defaultValue>
                      Select District
                    </option>
                    {districts.map((district) => (
                      <option value={district} key={district}>
                        {district}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-group">
                  <i className="fas fa-user"></i>
                  <select className="myInput">
                    <option value="LC">Select Position</option>
                    <option value="">LC Chairperson</option>
                    <option value="">Local Committee</option>
                    <option value="">Nodal Officer</option>
                    <option value="">District Authority</option>
                    <option value="">MFF Admin</option>
                  </select>
                </div>
                <div className="input-group">
                  <i className="bx bxs-user" />
                  <input type="text" placeholder="Address" />
                </div>
                <div className="input-group">
                  <i className="bx bxs-user" />
                  <input type="tel" placeholder="Phone Number" />
                </div>
                <div className="input-group">
                  <i className="bx bxs-user" />
                  <input type="email" placeholder="Email" />
                </div>
                <div className="input-group">
                  <i className="bx bxs-lock-alt" />
                  <input type="password" placeholder="Password" />
                </div>
                <div className="input-group">
                  <i className="bx bxs-lock-alt" />
                  <input type="password" placeholder="Confirm Password" />
                </div>
                <button>Register</button>
              </div>
            </div> */}
          </div>
          {/* END USER SECTION */}
          {/* SIGN IN */}
          {loader ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
                zIndex: "999",
                position: "absolute",
              }}
            >
              <SyncLoader
                color="#fff"
                style={{
                  display: "flex",
                  height: "100vh",
                  width: "100vw",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <div
                style={{
                  marginBottom: "10rem",
                  marginTop: "-18rem",
                  color: "#fff",
                  fontSize: "1.2rem",
                }}
              >
                {lang === "en"
                  ? "Your file will be downloaded soon..."
                  : "आपकी फ़ाइल जल्द ही डाउनलोड हो जाएगी..."}
              </div>
            </div>
          ) : (
            <div className="col align-items-center flex-col sign-in">
              <Tabs
                defaultActiveKey="login"
                className="form-wrapper"
                onSelect={(v) => {
                  setSelectedTab(v);
                  setVerifyError("");
                }}
                activeKey={selectedTab}
                fill
              >
                <Tab
                  eventKey="login"
                  title={lang === "en" ? "Login" : "लॉग इन"}
                >
                  <form
                    onSubmit={handleSubmit}
                    className="form-wrapper align-items-center"
                    style={{ marginTop: "0px" }}
                  >
                    <div className="form sign-in">
                      <div className="input-group f-input">
                        <i className="bx bxs-user" />
                        <input
                          type="text"
                          placeholder={
                            lang === "en" ? "First Name" : "पहला नाम"
                          }
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleChange}
                        />
                        {error.first_name.isValid === false && (
                          <div
                            className="invalid-feedback"
                            style={{
                              display: "block",
                              marginTop: "-1.5rem",
                              marginBottom: "0.2rem",
                            }}
                          >
                            {lang === "en"
                              ? error.first_name.inValid
                              : error.first_name.inValid_hi}
                          </div>
                        )}
                      </div>
                      <div className="input-group f-input">
                        <i className="bx bxs-user" />
                        <input
                          type="text"
                          placeholder={lang === "en" ? "Last Name" : "उपनाम"}
                          name="last_name"
                          value={formData.last_name}
                          onChange={handleChange}
                        />
                        {error.last_name.isValid === false && (
                          <div
                            className="invalid-feedback"
                            style={{
                              display: "block",
                              marginTop: "-1.5rem",
                              marginBottom: "0.2rem",
                            }}
                          >
                            {lang === "en"
                              ? error.last_name.inValid
                              : error.last_name.inValid_hi}
                          </div>
                        )}
                      </div>
                      <div className="input-group f-input">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            type="text"
                            className="w-auto"
                            placeholder={
                              lang === "en" ? "Phone Number" : "फ़ोन नंबर"
                            }
                            name="phone"
                            style={{ maxWidth: "15em" }}
                            value={formData.phone}
                            onChange={handleChange}
                          />
                          <button
                            className="btn btn-outline-secondary btn-otp"
                            type="button"
                            id="button-addon2"
                            onClick={() => {
                              setIsTimer(false);
                              setOTPMsg("");
                              getOTP();
                            }}
                            disabled={!error.phone.isValid}
                          >
                            {lang === "en" ? "Get OTP" : "ओटीपी प्राप्त करें"}
                          </button>
                        </div>
                        {error.phone.isValid === false && (
                          <div
                            className="invalid-feedback"
                            style={{
                              display: "contents",
                              marginTop: "-1.5rem",
                              marginBottom: "0.2rem",
                            }}
                          >
                            {lang === "en"
                              ? error.phone.inValid
                              : error.phone.inValid_hi}
                          </div>
                        )}
                        {isTimer ? <Timer duration={60} count={60} /> : ""}
                        {OTPError.length ? OTPError : OTPMsg}
                      </div>
                      <div className="input-group ">
                        <input
                          type="text"
                          className="w-auto"
                          placeholder={
                            lang === "en" ? "Type OTP" : "ओटीपी टाइप करें"
                          }
                          aria-label="Type OTP"
                          aria-describedby="button-addon2"
                          name="otp"
                          value={formData.otp}
                          onChange={handleChange}
                        />
                      </div>
                      {error.otp.isValid === false && (
                        <div
                          className="invalid-feedback"
                          style={{
                            display: "contents",
                            marginTop: "-1.5rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          {lang === "en"
                            ? error.otp.inValid
                            : error.otp.inValid_hi}
                        </div>
                      )}
                      <button
                        className="btn reg_button"
                        onClick={() => {
                          setIsTimer(false);
                          verifyUser();
                        }}
                        type="button"
                        disabled={!error.phone.isValid || !error.otp.isValid}
                      >
                        {lang === "en" ? "Verify" : "सत्यापित करें"}
                      </button>
                      {VerifyError.length ? VerifyError : ""}
                    </div>
                  </form>
                </Tab>
                <Tab
                  eventKey="status"
                  title={lang === "en" ? "Check Status" : "स्थिति जाँचिए"}
                >
                  <form
                    className="form-wrapper align-items-center"
                    style={{ marginTop: "0px" }}
                  >
                    <div className="form sign-in">
                      <div className="input-group f-input">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            type="text"
                            className="w-auto"
                            placeholder={
                              lang === "en" ? "Phone Number" : "फ़ोन नंबर"
                            }
                            name="phone"
                            style={{ maxWidth: "15em" }}
                            value={formData.phone}
                            onChange={handleChange}
                          />
                        </div>
                        {error.phone.isValid === false && (
                          <div
                            className="invalid-feedback"
                            style={{
                              display: "contents",
                              marginTop: "-1.5rem",
                              marginBottom: "0.2rem",
                            }}
                          >
                            {lang === "en"
                              ? error.phone.inValid
                              : error.phone.inValid_hi}
                          </div>
                        )}
                      </div>
                      <div className="input-group f-input">
                        <input
                          type="text"
                          placeholder={lang === "en" ? "Case Id" : "केस आईडी"}
                          name="case_id"
                          value={caseId}
                          onChange={(e) => setCaseId(e.target.value)}
                        />
                      </div>

                      <button
                        className="btn reg_button"
                        onClick={handleStatusCheck}
                        type="button"
                        disabled={!error.phone.isValid}
                      >
                        {lang === "en" ? "Submit" : "सबमिट"}
                      </button>
                      {VerifyError.length ? VerifyError : ""}
                    </div>
                  </form>
                </Tab>
              </Tabs>
            </div>
          )}

          {/* END SIGN IN */}
        </div>
        {/* END FORM SECTION */}
        {/* CONTENT SECTION */}
        <div className="row content-row">
          {/* SIGN IN CONTENT */}
          <div className="col align-items-center flex-col">
            <div className="text sign-in">
              <h2>{lang === "en" ? "Welcome User!" : "आपका स्वागत है"}</h2>
            </div>
            <div className="img sign-in">
              <img src="images/UserRegister.svg" alt="" />
            </div>
          </div>
          {/* END SIGN IN CONTENT */}
          {/* SIGN UP CONTENT */}
          <div className="col align-items-center flex-col">
            {window.innerWidth > 425 ? (
              <div className="img user-board">
                <img src="images/LCRegister.svg" alt="" />
              </div>
            ) : (
              ""
            )}

            <div className="text user-board">
              <h2>
                {lang === "en"
                  ? "How We Can Help You?"
                  : "हम आपकी सहायता कैसे कर सकते हैं?"}
              </h2>
            </div>
            {window.innerWidth <= 425 ? (
              <div className="img user-board">
                <img src="images/LCRegister.svg" alt="" />
              </div>
            ) : (
              ""
            )}
          </div>
          {/* END SIGN UP CONTENT */}
        </div>
        {/* END CONTENT SECTION */}
      </div>
    </div>
  );
};

export default Register;
