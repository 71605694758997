import React from "react";
import axios from 'axios';
import "./ResourceCenter.css";
import { useState, useEffect } from "react";
import { DocumentsList } from "./Resources";

const Documents = () => {
  const [resource, setResource] = useState([]);
  const [articles, setArticles] = useState([]);
  const [fabdisplay, setfabdisplay] = useState(false);

  const handleChecked = () => {
    setfabdisplay(!fabdisplay);
  };

  const handleResource = (data) => {
    setResource(data);
    handleChecked();
  };

  const mapData = (data) => {
    return data.map((d,i) => {
      return {
        id: d.id,
        sid: i+1,
        title: d.title,
        url: process.env.REACT_APP_API + "/articles/" + d.fileId + d.fileExt + "#zoom=75&view=fit",
      }
    })
  }

  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_API}/articles/readAll`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.response?.length) {
          const data = mapData(res.data.response)
          setArticles([...data])
          setResource({...data[0]})
        }
      } else {
        setArticles([])
      }
    })
    .catch((err) => {
      console.log(err)
    });
  }, [])

  useEffect(() => {
    if (fabdisplay || screen.width >= 1000)
      document.getElementsByClassName("order-last")[0].style.display = "block";
    else
      document.getElementsByClassName("order-last")[0].style.display = "none";

    articles.map((element, index) => {
      if (resource.sid - 1 === index) {
        document.querySelectorAll(".sidebar.widget ul li")[
          index
        ].style.outlineColor = "#009688";
        // document.querySelectorAll(".sidebar.widget ul li")[index].style.color =
        //   "white";
        // document.querySelectorAll(".share-btn")[index].style.color = "white";
        // document.querySelectorAll(".sidebar.widget ul li span")[
        //   index
        // ].style.color = "white";
        // document.querySelectorAll(".sidebar-meta span i")[index].style.color =
        //   "white";
      } else {
        document.querySelectorAll(".sidebar.widget ul li")[
          index
        ].style.outlineColor = "transparent";
        // document.querySelectorAll(".sidebar.widget ul li")[index].style.color =
        //   "black";
        // document.querySelectorAll(".share-btn")[index].style.color = "#4CAF50";
        // document.querySelectorAll(".sidebar.widget ul li span")[
        //   index
        // ].style.color = "black";
        // document.querySelectorAll(".sidebar-meta span i")[index].style.color =
        //   "#2996bde";
      }
    });
  }, [fabdisplay, resource]);

  return (
    <>
      <div
        className="resource doc"
        data-aos="fade-up"
        data-aos-offset="100"
        data-aos-easing="ease"
        data-aos-duration="500"
      >
        <div className="row">
          <div className="col-8 order-first">
            <h3>{resource.title}</h3>
            <iframe
              className="resource-doc"
              id="re"
              src={resource.url}
              width="100%"
              height="100%"
              allow="encrypted-media"
              allowFullScreen=""
              key={resource.url}
            ></iframe>
          </div>
          <div className="col-4 order-last">
            <div className="sidebar widget">
              <div style={{position: "sticky", top: "0", zIndex: 20000}}>
                <h4>Articles</h4>
              </div>
              <ul>
                {articles.map((data, index) => {
                  return (
                    <DocumentsList
                      data={data}
                      handleResource={handleResource}
                      key={index}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="wrapper" onClick={handleChecked}>
          <div className="fas fa-chevron-circle-up"></div>
        </div>
      </div>
    </>
  );
};

export default Documents;
