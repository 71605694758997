import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Dropdown({ toggle, content }) {
  return (
    <>
      <ul className="nav__submenu">
        <Link onClick={toggle} to="/laws" className="nav__submenu-item">
          {content.laws}
        </Link>
        <Link onClick={toggle} to="/research" className="nav__submenu-item">
          {content.research}
        </Link>
        <Link onClick={toggle} to="/docs" className="nav__submenu-item">
          {content.docs}
        </Link>
        <Link onClick={toggle} to="/videos" className="nav__submenu-item">
          {content.videos}
        </Link>
        <Link onClick={toggle} to="/blogs" className="nav__submenu-item">
          {content.blogs}
        </Link>
        <Link onClick={toggle} to="/articles" className="nav__submenu-item">
          {content.articles}
        </Link>
      </ul>
    </>
  );
}

export default Dropdown;
