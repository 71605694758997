import React from 'react';

const Modal = (props) => {
  return (
    <div
      className="modal fade"
      id={props.id}
      data-bs-backdrop="static"
      data-bs-keyboard="true"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header" style={{ backgroundColor: '#00796B' }}>
            <h5
              className="modal-title"
              id="staticBackdropLabel"
              style={{ color: 'white', fontWeight: '600' }}
            >
              Q & A
            </h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          {/* <div className="modal-body" style={{ textAlign: 'justify' }}>
            <div dangerouslySetInnerHTML={{ __html: props.data }}> </div>
          </div> */}
          <div
            className="modal-body"
            style={{ textAlign: 'justify' }}
            dangerouslySetInnerHTML={{ __html: props.data }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
