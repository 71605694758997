import "./Background.css";

const Background = () => {
  return (
    <div className="area">
      {/*<div className="ripple-background">
        <div className="circle xxlarge shade1"></div>
        <div className="circle xlarge shade2"></div>
        <div className="circle large shade3"></div>
        <div className="circle mediun shade4"></div>
        <div className="circle small shade5"></div>
      </div>*/}
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};

export default Background;

// <li>#WomenEmpowerment</li>
//         <li>#WomenEducation</li>
//         <li>#WomenSafety</li>
//         <li>#Feminism</li>
//         <li>#ladkibachaoladkipadao</li>
//         <li>#Queens</li>
