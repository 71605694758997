const stateDistricts = {
  AndraPradesh: [
    "Anantapur",
    "Chittoor",
    "East Godavari",
    "Guntur",
    "Kadapa",
    "Krishna",
    "Kurnool",
    "Prakasam",
    "Nellore",
    "Srikakulam",
    "Visakhapatnam",
    "Vizianagaram",
    "West Godavari",
  ],
  ArunachalPradesh: [
    "Anjaw",
    "Changlang",
    "Dibang Valley",
    "East Kameng",
    "East Siang",
    "Kra Daadi",
    "Kurung Kumey",
    "Lohit",
    "Longding",
    "Lower Dibang Valley",
    "Lower Subansiri",
    "Namsai",
    "Papum Pare",
    "Siang",
    "Tawang",
    "Tirap",
    "Upper Siang",
    "Upper Subansiri",
    "West Kameng",
    "West Siang",
    "Itanagar",
  ],
  Assam: [
    "Baksa",
    "Barpeta",
    "Biswanath",
    "Bongaigaon",
    "Cachar",
    "Charaideo",
    "Chirang",
    "Darrang",
    "Dhemaji",
    "Dhubri",
    "Dibrugarh",
    "Goalpara",
    "Golaghat",
    "Hailakandi",
    "Hojai",
    "Jorhat",
    "Kamrup Metropolitan",
    "Kamrup (Rural)",
    "Karbi Anglong",
    "Karimganj",
    "Kokrajhar",
    "Lakhimpur",
    "Majuli",
    "Morigaon",
    "Nagaon",
    "Nalbari",
    "Dima Hasao",
    "Sivasagar",
    "Sonitpur",
    "South Salmara Mankachar",
    "Tinsukia",
    "Udalguri",
    "West Karbi Anglong",
  ],
  Bihar: [
    "Araria",
    "Arwal",
    "Aurangabad",
    "Banka",
    "Begusarai",
    "Bhagalpur",
    "Bhojpur",
    "Buxar",
    "Darbhanga",
    "East Champaran",
    "Gaya",
    "Gopalganj",
    "Jamui",
    "Jehanabad",
    "Kaimur",
    "Katihar",
    "Khagaria",
    "Kishanganj",
    "Lakhisarai",
    "Madhepura",
    "Madhubani",
    "Munger",
    "Muzaffarpur",
    "Nalanda",
    "Nawada",
    "Patna",
    "Purnia",
    "Rohtas",
    "Saharsa",
    "Samastipur",
    "Saran",
    "Sheikhpura",
    "Sheohar",
    "Sitamarhi",
    "Siwan",
    "Supaul",
    "Vaishali",
    "West Champaran",
  ],
  Chhattisgarh: [
    "Balod",
    "Baloda Bazar",
    "Balrampur",
    "Bastar",
    "Bemetara",
    "Bijapur",
    "Bilaspur",
    "Dantewada",
    "Dhamtari",
    "Durg",
    "Gariaband",
    "Janjgir Champa",
    "Jashpur",
    "Kabirdham",
    "Kanker",
    "Kondagaon",
    "Korba",
    "Koriya",
    "Mahasamund",
    "Mungeli",
    "Narayanpur",
    "Raigarh",
    "Raipur",
    "Rajnandgaon",
    "Sukma",
    "Surajpur",
    "Surguja",
  ],
  Goa: ["North Goa", "South Goa"],
  Gujarat: [
    "Ahmedabad",
    "Amreli",
    "Anand",
    "Aravalli",
    "Banaskantha",
    "Bharuch",
    "Bhavnagar",
    "Botad",
    "Chhota Udaipur",
    "Dahod",
    "Dang",
    "Devbhoomi Dwarka",
    "Gandhinagar",
    "Gir Somnath",
    "Jamnagar",
    "Junagadh",
    "Kheda",
    "Kutch",
    "Mahisagar",
    "Mehsana",
    "Morbi",
    "Narmada",
    "Navsari",
    "Panchmahal",
    "Patan",
    "Porbandar",
    "Rajkot",
    "Sabarkantha",
    "Surat",
    "Surendranagar",
    "Tapi",
    "Vadodara",
    "Valsad",
  ],
  Haryana: [
    "Ambala",
    "Bhiwani",
    "Charkhi Dadri",
    "Faridabad",
    "Fatehabad",
    "Gurugram",
    "Hisar",
    "Jhajjar",
    "Jind",
    "Kaithal",
    "Karnal",
    "Kurukshetra",
    "Mahendragarh",
    "Mewat",
    "Palwal",
    "Panchkula",
    "Panipat",
    "Rewari",
    "Rohtak",
    "Sirsa",
    "Sonipat",
    "Yamunanagar",
  ],
  HimachalPradesh: [
    "Bilaspur",
    "Chamba",
    "Hamirpur",
    "Kangra",
    "Kinnaur",
    "Kullu",
    "Lahaul Spiti",
    "Mandi",
    "Shimla",
    "Sirmaur",
    "Solan",
    "Una",
  ],
  JammuKashmir: [
    "Anantnag",
    "Bandipora",
    "Baramulla",
    "Budgam",
    "Doda",
    "Ganderbal",
    "Jammu",
    "Kargil",
    "Kathua",
    "Kishtwar",
    "Kulgam",
    "Kupwara",
    "Leh",
    "Poonch",
    "Pulwama",
    "Rajouri",
    "Ramban",
    "Reasi",
    "Samba",
    "Shopian",
    "Srinagar",
    "Udhampur",
  ],
  Jharkhand: [
    "Bokaro",
    "Chatra",
    "Deoghar",
    "Dhanbad",
    "Dumka",
    "East Singhbhum",
    "Garhwa",
    "Giridih",
    "Godda",
    "Gumla",
    "Hazaribagh",
    "Jamtara",
    "Khunti",
    "Koderma",
    "Latehar",
    "Lohardaga",
    "Pakur",
    "Palamu",
    "Ramgarh",
    "Ranchi",
    "Sahebganj",
    "Seraikela Kharsawan",
    "Simdega",
    "West Singhbhum",
  ],
  Karnataka: [
    "Bagalkot",
    "Bangalore Rural",
    "Bangalore Urban",
    "Belgaum",
    "Bellary",
    "Bidar",
    "Vijayapura",
    "Chamarajanagar",
    "Chikkaballapur",
    "Chikkamagaluru",
    "Chitradurga",
    "Dakshina Kannada",
    "Davanagere",
    "Dharwad",
    "Gadag",
    "Gulbarga",
    "Hassan",
    "Haveri",
    "Kodagu",
    "Kolar",
    "Koppal",
    "Mandya",
    "Mysore",
    "Raichur",
    "Ramanagara",
    "Shimoga",
    "Tumkur",
    "Udupi",
    "Uttara Kannada",
    "Yadgir",
  ],
  Kerala: [
    "Alappuzha",
    "Ernakulam",
    "Idukki",
    "Kannur",
    "Kasaragod",
    "Kollam",
    "Kottayam",
    "Kozhikode",
    "Malappuram",
    "Palakkad",
    "Pathanamthitta",
    "Thiruvananthapuram",
    "Thrissur",
    "Wayanad",
  ],
  MadhyaPradesh: [
    "Agar Malwa",
    "Alirajpur",
    "Anuppur",
    "Ashoknagar",
    "Balaghat",
    "Barwani",
    "Betul",
    "Bhind",
    "Bhopal",
    "Burhanpur",
    "Chhatarpur",
    "Chhindwara",
    "Damoh",
    "Datia",
    "Dewas",
    "Dhar",
    "Dindori",
    "Guna",
    "Gwalior",
    "Harda",
    "Hoshangabad",
    "Indore",
    "Jabalpur",
    "Jhabua",
    "Katni",
    "Khandwa",
    "Khargone",
    "Mandla",
    "Mandsaur",
    "Morena",
    "Narsinghpur",
    "Neemuch",
    "Panna",
    "Raisen",
    "Rajgarh",
    "Ratlam",
    "Rewa",
    "Sagar",
    "Satna",
    "Sehore",
    "Seoni",
    "Shahdol",
    "Shajapur",
    "Sheopur",
    "Shivpuri",
    "Sidhi",
    "Singrauli",
    "Tikamgarh",
    "Ujjain",
    "Umaria",
    "Vidisha",
  ],
  Maharashtra: [
    "Ahmednagar",
    "Akola",
    "Amravati",
    "Aurangabad",
    "Beed",
    "Bhandara",
    "Buldhana",
    "Chandrapur",
    "Dhule",
    "Gadchiroli",
    "Gondia",
    "Hingoli",
    "Jalgaon",
    "Jalna",
    "Kolhapur",
    "Latur",
    "Mumbai City",
    "Mumbai Suburban",
    "Nagpur",
    "Nanded",
    "Nandurbar",
    "Nashik",
    "Osmanabad",
    "Palghar",
    "Parbhani",
    "Pune",
    "Raigad",
    "Ratnagiri",
    "Sangli",
    "Satara",
    "Sindhudurg",
    "Solapur",
    "Thane",
    "Wardha",
    "Washim",
    "Yavatmal",
  ],
  Manipur: [
    "Bishnupur",
    "Chandel",
    "Churachandpur",
    "Imphal East",
    "Imphal West",
    "Jiribam",
    "Kakching",
    "Kamjong",
    "Kangpokpi",
    "Noney",
    "Pherzawl",
    "Senapati",
    "Tamenglong",
    "Tengnoupal",
    "Thoubal",
    "Ukhrul",
  ],
  Meghalaya: [
    "East Garo Hills",
    "East Jaintia Hills",
    "East Khasi Hills",
    "North Garo Hills",
    "Ri Bhoi",
    "South Garo Hills",
    "South West Garo Hills",
    "South West Khasi Hills",
    "West Garo Hills",
    "West Jaintia Hills",
    "West Khasi Hills",
  ],
  Mizoram: [
    "Aizawl",
    "Champhai",
    "Kolasib",
    "Lawngtlai",
    "Lunglei",
    "Mamit",
    "Saiha",
    "Serchhip",
  ],
  Nagaland: [
    "Dimapur",
    "Kiphire",
    "Kohima",
    "Longleng",
    "Mokokchung",
    "Mon",
    "Peren",
    "Phek",
    "Tuensang",
    "Wokha",
    "Zunheboto",
  ],
  Odisha: [
    "Angul",
    "Balangir",
    "Balasore",
    "Bargarh",
    "Bhadrak",
    "Boudh",
    "Cuttack",
    "Debagarh",
    "Dhenkanal",
    "Gajapati",
    "Ganjam",
    "Jagatsinghpur",
    "Jajpur",
    "Jharsuguda",
    "Kalahandi",
    "Kandhamal",
    "Kendrapara",
    "Kendujhar",
    "Khordha",
    "Koraput",
    "Malkangiri",
    "Mayurbhanj",
    "Nabarangpur",
    "Nayagarh",
    "Nuapada",
    "Puri",
    "Rayagada",
    "Sambalpur",
    "Subarnapur",
    "Sundergarh",
  ],
  Punjab: [
    "Amritsar",
    "Barnala",
    "Bathinda",
    "Faridkot",
    "Fatehgarh Sahib",
    "Fazilka",
    "Firozpur",
    "Gurdaspur",
    "Hoshiarpur",
    "Jalandhar",
    "Kapurthala",
    "Ludhiana",
    "Mansa",
    "Moga",
    "Mohali",
    "Muktsar",
    "Pathankot",
    "Patiala",
    "Rupnagar",
    "Sangrur",
    "Shaheed Bhagat Singh Nagar",
    "Tarn Taran",
  ],
  Rajasthan: [
    "Ajmer",
    "Alwar",
    "Banswara",
    "Baran",
    "Barmer",
    "Bharatpur",
    "Bhilwara",
    "Bikaner",
    "Bundi",
    "Chittorgarh",
    "Churu",
    "Dausa",
    "Dholpur",
    "Dungarpur",
    "Ganganagar",
    "Hanumangarh",
    "Jaipur",
    "Jaisalmer",
    "Jalore",
    "Jhalawar",
    "Jhunjhunu",
    "Jodhpur",
    "Karauli",
    "Kota",
    "Nagaur",
    "Pali",
    "Pratapgarh",
    "Rajsamand",
    "Sawai Madhopur",
    "Sikar",
    "Sirohi",
    "Tonk",
    "Udaipur",
  ],
  Sikkim: ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"],
  TamilNadu: [
    "Ariyalur",
    "Chennai",
    "Coimbatore",
    "Cuddalore",
    "Dharmapuri",
    "Dindigul",
    "Erode",
    "Kanchipuram",
    "Kanyakumari",
    "Karur",
    "Krishnagiri",
    "Madurai",
    "Nagapattinam",
    "Namakkal",
    "Nilgiris",
    "Perambalur",
    "Pudukkottai",
    "Ramanathapuram",
    "Salem",
    "Sivaganga",
    "Thanjavur",
    "Theni",
    "Thoothukudi",
    "Tiruchirappalli",
    "Tirunelveli",
    "Tiruppur",
    "Tiruvallur",
    "Tiruvannamalai",
    "Tiruvarur",
    "Vellore",
    "Viluppuram",
    "Virudhunagar",
  ],
  Telangana: [
    "Adilabad",
    "Bhadradri Kothagudem",
    "Hyderabad",
    "Jagtial",
    "Jangaon",
    "Jayashankar",
    "Jogulamba",
    "Kamareddy",
    "Karimnagar",
    "Khammam",
    "Komaram Bheem",
    "Mahabubabad",
    "Mahbubnagar",
    "Mancherial",
    "Medak",
    "Medchal",
    "Nagarkurnool",
    "Nalgonda",
    "Nirmal",
    "Nizamabad",
    "Peddapalli",
    "Rajanna Sircilla",
    "Ranga Reddy",
    "Sangareddy",
    "Siddipet",
    "Suryapet",
    "Vikarabad",
    "Wanaparthy",
    "Warangal Rural",
    "Warangal Urban",
    "Yadadri Bhuvanagiri",
  ],
  Tripura: [
    "Dhalai",
    "Gomati",
    "Khowai",
    "North Tripura",
    "Sepahijala",
    "South Tripura",
    "Unakoti",
    "West Tripura",
  ],
  UttarPradesh: [
    "Agra",
    "Aligarh",
    "Allahabad",
    "Ambedkar Nagar",
    "Amethi",
    "Amroha",
    "Auraiya",
    "Azamgarh",
    "Baghpat",
    "Bahraich",
    "Ballia",
    "Balrampur",
    "Banda",
    "Barabanki",
    "Bareilly",
    "Basti",
    "Bhadohi",
    "Bijnor",
    "Budaun",
    "Bulandshahr",
    "Chandauli",
    "Chitrakoot",
    "Deoria",
    "Etah",
    "Etawah",
    "Faizabad",
    "Farrukhabad",
    "Fatehpur",
    "Firozabad",
    "Gautam Buddha Nagar",
    "Ghaziabad",
    "Ghazipur",
    "Gonda",
    "Gorakhpur",
    "Hamirpur",
    "Hapur",
    "Hardoi",
    "Hathras",
    "Jalaun",
    "Jaunpur",
    "Jhansi",
    "Kannauj",
    "Kanpur Dehat",
    "Kanpur Nagar",
    "Kasganj",
    "Kaushambi",
    "Kheri",
    "Kushinagar",
    "Lalitpur",
    "Lucknow",
    "Maharajganj",
    "Mahoba",
    "Mainpuri",
    "Mathura",
    "Mau",
    "Meerut",
    "Mirzapur",
    "Moradabad",
    "Muzaffarnagar",
    "Pilibhit",
    "Pratapgarh",
    "Raebareli",
    "Rampur",
    "Saharanpur",
    "Sambhal",
    "Sant Kabir Nagar",
    "Shahjahanpur",
    "Shamli",
    "Shravasti",
    "Siddharthnagar",
    "Sitapur",
    "Sonbhadra",
    "Sultanpur",
    "Unnao",
    "Varanasi",
  ],
  Uttarakhand: [
    "Almora",
    "Bageshwar",
    "Chamoli",
    "Champawat",
    "Dehradun",
    "Haridwar",
    "Nainital",
    "Pauri",
    "Pithoragarh",
    "Rudraprayag",
    "Tehri",
    "Udham Singh Nagar",
    "Uttarkashi",
  ],
  WestBengal: [
    "Alipurduar",
    "Bankura",
    "Birbhum",
    "Cooch Behar",
    "Dakshin Dinajpur",
    "Darjeeling",
    "Hooghly",
    "Howrah",
    "Jalpaiguri",
    "Jhargram",
    "Kalimpong",
    "Kolkata",
    "Malda",
    "Murshidabad",
    "Nadia",
    "North 24 Parganas",
    "Paschim Bardhaman",
    "Paschim Medinipur",
    "Purba Bardhaman",
    "Purba Medinipur",
    "Purulia",
    "South 24 Parganas",
    "Uttar Dinajpur",
  ],
  AndamanNicobar: ["Nicobar", "North Middle Andaman", "South Andaman"],
  Chandigarh: ["Chandigarh"],
  DadraHaveli: ["Dadra Nagar Haveli"],
  DamanDiu: ["Daman", "Diu"],
  Delhi: [
    "Central Delhi",
    "East Delhi",
    "New Delhi",
    "North Delhi",
    "North East Delhi",
    "North West Delhi",
    "Shahdara",
    "South Delhi",
    "South East Delhi",
    "South West Delhi",
    "West Delhi",
  ],
  Lakshadweep: ["Lakshadweep"],
  Puducherry: ["Karaikal", "Mahe", "Puducherry", "Yanam"],
};

const stateDistricts_hi = {
  "आंध्रप्रदेश": [
    "अनंतपुर",
    "चित्तूर",
    "पूर्वी गोदावरी",
    "गुंटूर",
    "कडपा",
    "कृष्णा",
    "कुरनूल",
    "प्रकाशम",
    "नेल्लोर",
    "श्रीकाकुलम",
    "विशाखापत्तनम",
    "विजयनगरम",
    "पश्चिम गोदावरी",
  ],
  "अरुणाचल प्रदेश": [
    "अंजाव",
    "चांगलांग",
    "दिबांग घाटी",
    "पूर्वी कामेंग",
    "पूर्वी सियांग",
    "क्रा दादी",
    "कुरुंग कुमे",
    "लोहित",
    "लालसा",
    "निचली दिबांग घाटी",
    "निचला सुबनसिरी",
    "नमसाई",
    "पापुम पारे",
    "सियांग",
    "तवांग",
    "तिरप",
    "ऊपरी सियांग",
    "ऊपरी सुबनसिरी",
    "पश्चिम कामेंग",
    "पश्चिम सियांग",
    "ईटानगर",
  ],
  "असम": [
    "बक्सा",
    "बारपेटा",
    "विश्वनाथ",
    "बोंगाईगाँव",
    "कछार",
    "चारैदेव",
    "चिरांग",
    "डारंग",
    "धेमाजी",
    "धुबरी",
    "डिब्रूगढ़",
    "गोलपारा",
    "गोलाघाट",
    "हैलाकांडी",
    "होजई",
    "जोरहाट",
    "कामरूप महानगर",
    "कामरूप (ग्रामीण)",
    "कार्बी आंगलोंग",
    "करीमगंज",
    "कोकराझार",
    "लखीमपुर",
    "माजुली",
    "मोरीगांव",
    "नगांव",
    "नलबाड़ी",
    "दिमा हसाओ",
    "शिवसागर",
    "सोनितपुर",
    "दक्षिण सालमारा मनकचर",
    "तिनसुकिया",
    "उदलगुरी",
    "वेस्ट कार्बी आंगलोंग",
  ],
  "बिहार": [
    "अररिया",
    "अरवल",
    "औरंगाबाद",
    "बांका",
    "बेगूसराय",
    "भागलपुर",
    "भोजपुर",
    "बक्सर",
    "दरभंगा",
    "पूर्वी चंपारण",
    "गया",
    "गोपालगंज",
    "जमुई",
    "जहानाबाद",
    "कैमूर",
    "कटिहार",
    "खगड़िया",
    "किशनगंज",
    "लखीसराय",
    "मधेपुरा",
    "मधुबनी",
    "मुंगेर",
    "मुजफ्फरपुर",
    "नालंदा",
    "नवादा",
    "पटना",
    "पूर्णिया",
    "रोहतास",
    "सहरसा",
    "समस्तीपुर",
    "सरन",
    "शेखपुरा",
    "शिवहर",
    "सीतामढ़ी",
    "सीवान",
    "सुपौल",
    "वैशाली",
    "पश्चिम चंपारण",
  ],
  "छत्तीसगढ़": [
    "बालोद",
    "बलौदा बाजार",
    "बलरामपुर",
    "बस्तर",
    "बेमेतरा",
    "बीजापुर",
    "बिलासपुर",
    "दंतेवाड़ा",
    "धमतरी",
    "दुर्ग",
    "गरियाबंद",
    "जांजगीर चांपा",
    "जशपुर",
    "कबीरधाम",
    "कांकेर",
    "कोंडागांव",
    "कोरबा",
    "कोरिया",
    "महासमुंद",
    "मुंगेली",
    "नारायणपुर",
    "रायगढ़",
    "रायपुर",
    "राजनांदगांव",
    "सुकमा",
    "सूरजपुर",
    "सरगुजा",
  ],
  "गोवा": ["उत्तरी गोवा", "दक्षिणी गोवा"],
  "गुजरात": [
    "अहमदाबाद",
    "अमरेली",
    "आनंद",
    "अरावली",
    "बनसकांठा",
    "भरूच",
    "भावनगर",
    "बोटाद",
    "छोटा उदयपुर",
    "दाहोद",
    "डांग",
    "देवभूमि द्वारिका",
    "गांधीनगर",
    "गिर सोमनाथ",
    "जामनगर",
    "जूनागढ़",
    "खेड़ा",
    "कच्छ",
    "महिसागर",
    "मेहसाना",
    "मोरबी",
    "नर्मदा",
    "नवसारी",
    "पंचमहल",
    "पाटन",
    "पोरबंदर",
    "राजकोट",
    "साबरकांठा",
    "सूरत",
    "सुरेंद्रनगर",
    "तापी",
    "वडोदरा",
    "वलसाड",
  ],
  "हरयाणा": [
    "अंबाला",
    "भिवानी",
    "चरखी दादरी",
    "फरीदाबाद",
    "फतेहाबाद",
    "गुरुग्राम",
    "हिसार",
    "झज्जर",
    "जींद",
    "कैथल",
    "करनाल",
    "कुरुक्षेत्र",
    "महेंद्रगढ़",
    "मेवात",
    "पलवल",
    "पंचकुला",
    "पानीपत",
    "रेवाड़ी",
    "रोहतक",
    "सिरसा",
    "सोनीपत",
    "यमुनानगर",
  ],
  "हिमाचल प्रदेश": [
    "बिलासपुर",
    "चंबा",
    "हमीरपुर",
    "कांगड़ा",
    "किन्नौर",
    "कुल्लू",
    "लाहौल स्पीति",
    "मंडी",
    "शिमला",
    "सिरमौर",
    "सोलन",
    "ऊना",
  ],
  "जम्मू कश्मीर": [
    "अनंतनाग",
    "बांदीपोरा",
    "बारामूला",
    "बडगाम",
    "डोडा",
    "गांदरबल",
    "जम्मू",
    "कारगिल",
    "कठुआ",
    "किश्तवाड़",
    "कुलगाम",
    "कुपवाड़ा",
    "लेह",
    "पुंछ",
    "पुलवामा",
    "राजौरी",
    "रामबन",
    "रियासी",
    "सांबा",
    "शोपियां",
    "श्रीनगर",
    "उधमपुर",
  ],
  "झारखंड": [
    "बोकारो",
    "चतरा",
    "देवघर",
    "धनबाद",
    "दुमका",
    "पूर्वी सिंहभूम",
    "गढ़वा",
    "गिरिडीह",
    "गोड्डा",
    "गुमला",
    "हजारीबाग",
    "जामताड़ा",
    "खूंटी",
    "कोडरमा",
    "लातेहार",
    "लोहरदगा",
    "पाकुड़",
    "पलामू",
    "रामगढ़",
    "रांची",
    "साहेबगंज",
    "सराइकेला-खरसावाँ",
    "सिमडेगा",
    "पश्चिम सिंहभूम",
  ],
  "कर्नाटक": [
    "बागलकोट",
    "बैंगलोर ग्रामीण",
    "बैंगलोर अर्बन",
    "बेलगाम",
    "बेल्लारी",
    "बीदर",
    "विजयपुरा",
    "चामराजनगर",
    "चिक्काबल्लापुर",
    "चिक्कमगलुरु",
    "चित्रदुर्ग",
    "दक्षिण कन्नड़",
    "दावणगेरे",
    "धारवाड़",
    "गदग",
    "गुलबर्गा",
    "हसन",
    "हावेरी",
    "कोडागु",
    "कोलार",
    "कोप्पल",
    "मंड्या",
    "मैसूर",
    "रायचुर",
    "रामनगर",
    "शिमोगा",
    "तुमकुर",
    "उडुपी",
    "उत्तर कन्नड़",
    "यादगीर",
  ],
  "केरल": [
    "अलपुझा",
    "एर्नाकुलम",
    "इडुक्की",
    "कन्नूर",
    "कासरगोड",
    "कोल्लम",
    "कोट्टायम",
    "कोझिकोड",
    "मलप्पुरम",
    "पलक्कड़",
    "पथनमथिट्टा",
    "तिरुवनंतपुरम",
    "त्रिशूर",
    "वायनाड",
  ],
  "मध्य प्रदेश": [
    "आगर मालवा",
    "अलीराजपुर",
    "अनूपपुर",
    "अशोकनगर",
    "बालाघाट",
    "बड़वानी",
    "बैतूल",
    "भिंड",
    "भोपाल",
    "बुरहानपुर",
    "छतरपुर",
    "छिंदवाड़ा",
    "दमोह",
    "दतिया",
    "देवास",
    "धार",
    "डिंडोरी",
    "गुना",
    "ग्वालियर",
    "हरदा",
    "होशंगाबाद",
    "इंदौर",
    "जबलपुर",
    "झाबुआ",
    "कटनी",
    "खंडवा",
    "खरगोन",
    "मंडला",
    "मंदसौर",
    "मुरैना",
    "नरसिंहपुर",
    "नीमच",
    "पन्ना",
    "रायसेन",
    "राजगढ़",
    "रतलाम",
    "रीवा",
    "सागर",
    "सतना",
    "सीहोर",
    "सिवनी",
    "शहडोल",
    "शाजापुर",
    "श्योपुर",
    "शिवपुरी",
    "सीधी",
    "सिंगरौली",
    "टीकमगढ़",
    "उज्जैन",
    "उमरिया",
    "विदिशा",
  ],
  "महाराष्ट्र": [
    "अहमदनगर",
    "अकोला",
    "अमरावती",
    "औरंगाबाद",
    "बीड",
    "भंडारा",
    "बुलढाणा",
    "चंद्रपुर",
    "धुले",
    "गढ़चिरौली",
    "गोंदिया",
    "हिंगोली",
    "जलगाँव",
    "जालना",
    "कोल्हापुर",
    "लातूर",
    "मुंबई शहर",
    "मुंबई उपनगर",
    "नागपुर",
    "नांदेड़",
    "नंदुरबार",
    "नासिक",
    "उस्मानाबाद",
    "पालघर",
    "परभणी",
    "पुणे",
    "रायगढ़",
    "रत्नागिरी",
    "सांगली",
    "सतारा",
    "सिंधुदुर्ग",
    "सोलापुर",
    "ठाणे",
    "वर्धा",
    "वाशिम",
    "यवतमाल",
  ],
  "मणिपुर": [
    "बिष्णुपुर",
    "चंदेल",
    "चूराचांदपुर",
    "इम्फाल पूर्व",
    "इम्फाल पश्चिम",
    "जिरिबाम",
    "काकचिंग",
    "कामजोंग",
    "कांगपोकपी",
    "नहीं",
    "फेरज़ावल",
    "सेनापति",
    "तमेंगलोंग",
    "तेंगनोपाल",
    "थौबल",
    "उखरुल",
  ],
  "मेघालय": [
    "पूर्वी गारो हिल्स",
    "ईस्ट जयंतिया हिल्स",
    "पूर्वी खासी हिल्स",
    "नॉर्थ गारो हिल्स",
    "री भोई",
    "साउथ गारो हिल्स",
    "दक्षिण पश्चिम गारो हिल्स",
    "दक्षिण पश्चिम खासी हिल्स",
    "वेस्ट गारो हिल्स",
    "वेस्ट जयंतिया हिल्स",
    "पश्चिम खासी हिल्स",
  ],
  "मिजोरम": [
    "आइज़ोल",
    "चम्फाई",
    "कोलासिब",
    "लॉंगत्लाई",
    "लुंगलेई",
    "ममित",
    "सइहा",
    "सेरछिप",
  ],
  "नागालैंड": [
    "दीमापुर",
    "किफिर",
    "कोहिमा",
    "लॉन्गलेंग",
    "मोकोकचुंग",
    "सोमवार",
    "पेरेन",
    "फेक",
    "तुएनसांग",
    "वोखा",
    "जुन्हेबोटो",
  ],
  "ओडिशा": [
    "अंगुल",
    "बलांगीर",
    "बालासोर",
    "बरगढ़",
    "भद्रक",
    "बौध",
    "कटक",
    "देबगढ़",
    "ढेंकनाल",
    "गजपति",
    "गंजम",
    "जगतसिंहपुर",
    "जाजपुर",
    "झारसुगुड़ा",
    "कालाहांडी",
    "कंधमाल",
    "केंद्रपारा",
    "केंदुझार",
    "खोरधा",
    "कोरापुट",
    "मलकानगिरी",
    "मयूरभंज",
    "नबरंगपुर",
    "नयागढ़",
    "नुआपाड़ा",
    "पुरी",
    "रायगढ़",
    "संबलपुर",
    "सुबर्णपुर",
    "सुंदरगढ़",
  ],
  "पंजाब": [
    "अमृतसर",
    "बरनाला",
    "बठिंडा",
    "फरीदकोट",
    "फतेहगढ़ साहिब",
    "फाजिल्का",
    "फ़िरोज़पुर",
    "गुरदासपुर",
    "होशियारपुर",
    "जालंधर",
    "कपूरथला",
    "लुधियाना",
    "मनसा",
    "मोगा",
    "मोहाली",
    "मुक्तसर",
    "पठानकोट",
    "पटियाला",
    "रूपनगर",
    "संगरूर",
    "शहीद भगत सिंह नगर",
    "तरन तारन",
  ],
  "राजस्थान": [
    "अजमेर",
    "अलवर",
    "बांसवाड़ा",
    "बरन",
    "बाड़मेर",
    "भरतपुर",
    "भीलवाड़ा",
    "बीकानेर",
    "बूंदी",
    "चित्तौड़गढ़",
    "चूरू",
    "दौसा",
    "धौलपुर",
    "डूंगरपुर",
    "गंगानगर",
    "हनुमानगढ़",
    "जयपुर",
    "जैसलमेर",
    "जालोर",
    "झालावाड़",
    "झुंझुनू",
    "जोधपुर",
    "करौली",
    "कोटा",
    "नागौर",
    "पाली",
    "प्रतापगढ़",
    "राजसमंद",
    "सवाई माधोपुर",
    "सीकर",
    "सिरोही",
    "टोंक",
    "उदयपुर",
  ],
  "सिक्किम": ["पूर्वी सिक्किम", "उत्तर सिक्किम", "दक्षिण सिक्किम", "पश्चिम सिक्किम"],
  "तमिलनाडु": [
    "अरियालुर",
    "चेन्नई",
    "कोयंबटूर",
    "कुड्डालोर",
    "धर्मपुरी",
    "डिंडीगुल",
    "इरोड",
    "कांचीपुरम",
    "कन्याकूमारी",
    "करूर",
    "कृष्णागिरी",
    "मदुरै",
    "नागपट्टिनम",
    "नमक्कल",
    "नीलगिरी",
    "पेरम्बलुर",
    "पुदुक्कोट्टई",
    "रामनाथपुरम",
    "सलेम",
    "शिवगंगा",
    "तंजावुर",
    "तब मैं",
    "थूथुकुडी",
    "तिरुचिरापल्ली",
    "तिरुनेलवेली",
    "तिरुपुर",
    "तिरुवल्लुर",
    "तिरुवन्नामलाई",
    "तिरुवरूर",
    "वेल्लोर",
    "विलुप्पुरम",
    "विरुधुनगर",
  ],
  "तेलंगाना": [
    "आदिलाबाद",
    "भद्राद्री कोठागुडेम",
    "हैदराबाद",
    "जगतियाल",
    "जंगों",
    "जयशंकर",
    "जोगुलम्बा",
    "कामारेड्डी",
    "करीमनगर",
    "खम्मम",
    "कोमाराम भीम",
    "महबूबाबाद",
    "महबूबनगर",
    "मैनचेरियल",
    "मेडक",
    "मेडचल",
    "नगरकुर्नूल",
    "नलगोंडा",
    "निर्मल",
    "निजामाबाद",
    "पेड्डापल्ली",
    "राजन्ना सिरसिला",
    "रंगा रेड्डी",
    "संगारेड्डी",
    "सिद्दीपेट",
    "सूर्यपेट",
    "विकाराबाद",
    "वानापार्थी",
    "वारंगल ग्रामीण",
    "वारंगल अर्बन",
    "यदाद्रि भुवनगिरि",
  ],
  "त्रिपुरा": [
    "धलाई",
    "गोमती",
    "खोवाई",
    "उत्तर त्रिपुरा",
    "सेपाहिजला",
    "दक्षिण त्रिपुरा",
    "उनाकोटी",
    "पश्चिम त्रिपुरा",
  ],
  "उतार प्रदेश": [
    "आगरा",
    "अलीगढ़",
    "इलाहाबाद",
    "अंबेडकर नगर",
    "अमेठी",
    "अमरोहा",
    "औरैया",
    "आजमगढ़",
    "बागपत",
    "बहराइच",
    "बलिया",
    "बलरामपुर",
    "बांदा",
    "बाराबंकी",
    "बरेली",
    "बस्ती",
    "भदोही",
    "बिजनौर",
    "बदायूं",
    "बुलंदशहर",
    "चंदौली",
    "चित्रकूट",
    "देवरिया",
    "एटा",
    "इटावा",
    "फैजाबाद",
    "फर्रुखाबाद",
    "फतेहपुर",
    "फ़िरोज़ाबाद",
    "गौतम बुद्ध नगर",
    "गाज़ियाबाद",
    "गाजीपुर",
    "गोंडा",
    "गोरखपुर",
    "हमीरपुर",
    "हापुड़",
    "हरदोई",
    "हाथरस",
    "जालौन",
    "जौनपुर",
    "झांसी",
    "कन्नौज",
    "कानपुर देहात",
    "कानपुर नगर",
    "कासगंज",
    "कौशाम्बी",
    "खीरी",
    "कुशीनगर",
    "ललितपुर",
    "लखनऊ",
    "महाराजगंज",
    "महोबा",
    "मैनपुरी",
    "मथुरा",
    "मऊ",
    "मेरठ",
    "मिर्जापुर",
    "मुरादाबाद",
    "मुजफ्फरनगर",
    "पीलीभीत",
    "प्रतापगढ़",
    "रायबरेली",
    "रामपुर",
    "सहारनपुर",
    "संभल",
    "संत कबीर नगर",
    "शाहजहाँपुर",
    "शामली",
    "श्रावस्ती",
    "सिद्धार्थनगर",
    "सीतापुर",
    "सोनभद्र",
    "सुल्तानपुर",
    "उन्नाव",
    "वाराणसी",
  ],
  "उत्तराखंड": [
    "अल्मोड़ा",
    "बागेश्वर",
    "चमोली",
    "चम्पावत",
    "देहरादून",
    "हरिद्वार",
    "नैनीताल",
    "पौड़ी",
    "पिथौरागढ़",
    "रुद्रप्रयाग",
    "टिहरी",
    "उधम सिंह नगर",
    "उत्तरकाशी",
  ],
  "पश्चिम बंगाल": [
    "अलीपुरद्वार",
    "बांकुरा",
    "बीरभूम",
    "कूचबिहार",
    "दक्षिण दिनाजपुर",
    "दार्जिलिंग",
    "हुगली",
    "हावड़ा",
    "जलपाईगुड़ी",
    "झारग्राम",
    "कालिम्पोंग",
    "कोलकाता",
    "मालदा",
    "मुर्शिदाबाद",
    "नादिया",
    "उत्तरी 24 परगना",
    "पश्चिम बर्धमान",
    "पश्चिम मेदिनीपुर",
    "पूरब बर्धमान",
    "पूरब मेदिनीपुर",
    "पुरुलिया",
    "दक्षिण 24 परगना",
    "उत्तर दिनाजपुर",
  ],
  "अंडमान निकोबार": ["निकोबार", "उत्तरी मध्य अंडमान", "दक्षिणी अंडमान"],
  "चंडीगढ़": ["चंडीगढ़"],
  "दादरा हवेली": ["दादरा नगर हवेली"],
  "दमनदिउ": ["दमन", "दीव"],
  "दिल्ली": [
    "सेंट्रल दिल्ली",
    "पूर्वी दिल्ली",
    "नयी दिल्ली",
    "उत्तरी दिल्ली",
    "उत्तर पूर्वी दिल्ली",
    "उत्तर पश्चिम दिल्ली",
    "शाहदरा",
    "दक्षिण दिल्ली",
    "दक्षिण पूर्व दिल्ली",
    "दक्षिण पश्चिम दिल्ली",
    "पश्चिम दिल्ली",
  ],
  "लक्षद्वीप": ["लक्षद्वीप"],
  "पुडुचेरी": ["कराइकल", "माहे", "पुडुचेरी", "यानम"],
};

export {stateDistricts, stateDistricts_hi};
