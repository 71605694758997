import React, { useState } from "react";

const UpdateEvidenceForm = ({ updateContent, setUpdateData, updateData, lang }) => {
  const [evidenceDescription, setEvidenceDescription] = useState(
    updateData.evidenceDescription
  );
  const [evidenceType, setEvidenceType] = useState(updateData.evidenceType);
  const [evidenceFile, setEvidenceFile] = useState(updateData.evidenceFile);
  return (
    <form
      name="add_evidence_form"
      id="add_evidence_form"
      encType="multipart/form-data"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{lang === "en" ? "Update Evidence" : "साक्ष्य अद्यतन"}</h5>
          <button
            className="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
          >
            {/* <span aria-hidden="true">×</span> */}
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group form-group-multi row">
            <label
              htmlFor="group"
              className="col-md-3 col-form-label text-md-right"
            >
              {
                lang === "en" ? "Evidence Type" : "साक्ष्य प्रकार"
              }
            </label>
            <div className="col-md-8">
              <select
                className="form-control"
                id="evidence_type"
                name="evidence_type"
                value={evidenceType}
                onChange={(e) => {
                  setEvidenceType(e.target.value);
                }}
              >
                <option value="Please select" selected disabled>
                  {
                    lang === "en" ? "Please select" : "कृपया चयन कीजिए"
                  }
                </option>
                <option value="Screenshot">{lang === "en" ? "Screenshot" : "स्क्रीनशॉट"}</option>
                <option value="Photo">{lang === "en" ? "Photo" : "तस्वीर"}</option>
                <option value="Video">{lang === "en" ? "Video" : "वीडियो"}</option>
                <option value="Letter">{lang === "en" ? "Letter" : "पत्र"}</option>
                <option value="Any Other">{lang === "en" ? "Any Other" : "कोई और"}</option>
              </select>
              {/* {error.evidence_type.isValid === false && (
                <div
                  className="invalid-feedback"
                  style={{
                    display: 'block',
                    marginTop: '-1.5rem',
                    marginBottom: '0.2rem',
                  }}
                >
                  {error.evidence_type.inValid}
                </div>
              )} */}
            </div>
          </div>
          <div className="form-group form-group-multi row">
            <label
              htmlFor="group"
              className="col-md-3 col-form-label text-md-right"
            >
              {lang === "en" ? "Attach File" : "फ़ाइल जोड़ें"}
            </label>
            <div className="col-md-8">
              {(evidenceType === "Please select" ||
                evidenceType === "Any Other") && (
                <>
                  <input
                    className="form-control"
                    id="evidence_document"
                    name="evidence_document"
                    type="file"
                    onChange={(e) => setEvidenceFile(e.target.files[0])}
                  />
                  <small style={{ color: "red" }}>
                    (jpg/png/audio/pdf/docs)
                  </small>
                </>
              )}
              {(evidenceType === "Photo" || evidenceType === "Screenshot") && (
                <>
                  <input
                    className="form-control"
                    id="evidence_document"
                    name="evidence_document"
                    type="file"
                    onChange={(e) => setEvidenceFile(e.target.files[0])}
                    accept="image/*"
                  />
                  <small style={{ color: "red" }}>(jpg/png)</small>
                </>
              )}
              {evidenceType === "Video" && (
                <>
                  <input
                    className="form-control"
                    id="evidence_document"
                    name="evidence_document"
                    type="file"
                    onChange={(e) => setEvidenceFile(e.target.files[0])}
                    accept="video/*"
                  />
                  <small style={{ color: "red" }}>(mp4)</small>
                </>
              )}
              {evidenceType === "Letter" && (
                <>
                  <input
                    className="form-control"
                    id="evidence_document"
                    name="evidence_document"
                    type="file"
                    onChange={(e) => setEvidenceFile(e.target.files[0])}
                    accept="text/plain, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                  <small style={{ color: "red" }}>(pdf/docs)</small>
                </>
              )}
            </div>
          </div>
          {
            evidenceType !== "Any Other" &&
          <div className="form-group form-group-multi row">
            <label
              htmlFor="group"
              className="col-md-3 col-form-label text-md-right"
            >
              {lang === "en" ? "Preview" : "पूर्व दर्शन"}
            </label>
            <div className="col-md-8">
              {evidenceType === "Photo" || evidenceType === "Screenshot" ? (
                <img
                  src={evidenceFile ? URL.createObjectURL(evidenceFile) : ""}
                  alt=""
                  style={{ height: "10em", width: "auto" }}
                />
              ) : (
                ""
              )}
              {evidenceType === "Video" ? (
                <video
                  src={evidenceFile ? URL.createObjectURL(evidenceFile) : ""}
                  alt=""
                  style={{ height: "10em", width: "auto" }}
                  controls
                />
              ) : (
                ""
              )}
              {evidenceType === "Letter" ? (
                <iframe
                  src={evidenceFile ? URL.createObjectURL(evidenceFile) : ""}
                  alt=""
                  style={{ height: "10em", width: "auto" }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          }
          <div className="form-group form-group-multi row">
            <label
              htmlFor="group"
              className="col-md-3 col-form-label text-md-right"
            >
              {lang === "en" ? "Evidence description" : "साक्ष्य विवरण"}
            </label>
            <div className="col-md-8">
              <textarea
                className="form-control"
                id="evidence_description"
                name="evidence_description"
                placeholder={lang === "en" ? "Description of Evidence" : "साक्ष्य का विवरण"}
                value={evidenceDescription}
                onChange={(e) => setEvidenceDescription(e.target.value)}
              />
              {/* {error.evidence_description.isValid === false && (
                <div
                  className="invalid-feedback"
                  style={{
                    display: 'block',
                    marginTop: '-1.5rem',
                    marginBottom: '0.2rem',
                  }}
                >
                  {error.evidence_description.inValid}
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-light"
            type="button"
            id="TableModalClose"
            data-dismiss="modal"
            onClick={() => setUpdateData("")}
          >
            {
              lang === "en" ? "Cancel" : "रद्द करे"
            }
          </button>
          <button
            onClick={() => updateContent(evidenceType, evidenceDescription)}
            type="button"
            className="btn btn-primary"
          >
            {
              lang === "en" ? "Update" : "अद्यतन करें"
            }
          </button>
        </div>
      </div>
    </form>
  );
};

export default UpdateEvidenceForm;
