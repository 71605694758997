import "./Resources.css";

export const VideosList = ({ data, handleResource }) => {
  return (
    <li onClick={() => handleResource(data)} style={{cursor: "pointer"}}>
      <span style={{ display: "flex" }}>
        <div className="sidebar-thumb">
          <img className="animated rollIn" src={data.thumbnail} alt="" />
        </div>
        <div className="sidebar-content">
          <h5 className="animated bounceInRight">{data.title}</h5>
          <div className="sidebar-meta">
          <span className="time">
            <i className="fa fa-clock-o"></i>
            {data.date}
          </span>
        </div>
        </div>
      </span>
    </li>
  );
};

export const DocumentsList = ({ data, handleResource }) => {
  const share = (e) => {
    Array.from(document.getElementsByClassName("share-icons")).map(
      (ele, ind) => {
        if (ind == e.target.id - 1 && !ele.classList.contains("active")) {
          ele.classList.add("active");
        } else if (ele.classList.contains("active")) {
          ele.classList.remove("active");
        } else {
          ele.classList.remove("active");
        }
      }
    );
  };
  return (
    <li>
      <div className="sidebar-content"  onClick={() => handleResource(data)} style={{cursor: "pointer"}}>
        <div style={{ width: "100%" }}>
          <h5 className="animated bounceInRight">{data.title}</h5>
        </div>

        <div className="floating-share">
          <button className="share-btn" id="share-btn">
            <i className="fa fa-share-alt" id={data.sid} onClick={share}></i>
          </button>
          <div className="share-icons">
            <a
              className="share-icon"
              href={"https://twitter.com/share?url=" + data.url}
              target="_blank"
            >
              <i className="fa fa-twitter"></i>
            </a>
            <a
              className="share-icon"
              href={"https://www.facebook.com/share.php?u=" + data.url}
              target="_blank"
            >
              <i className="fa fa-facebook"></i>
            </a>
            <a
              className="share-icon"
              href={"https://www.linkedin.com/shareArticle?url=" + data.url}
              target="_blank"
            >
              <i className="fa fa-linkedin"></i>
            </a>
            <a
              className="share-icon"
              href={"whatsapp://send?text=" + data.url}
              data-action="share/whatsapp/share"
              target="_blank"
            >
              <i className="fa fa-whatsapp"></i>
            </a>
          </div>
        </div>
      </div>
      {/*<div className="sidebar-meta">
        <span className="time">
          <i className="fa fa-user"></i>
          Author: {data.authors}
        </span>
      </div>*/}
    </li>
  );
};
