import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faPen,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";

const WList = ({ wlist, wdeleteContent, setWUpdateData }) => {
  return (
    <>
      {wlist &&
        wlist
          .sort((a, b) => (a.id > b.id ? 1 : -1))
          .map((task, index) => {
            return (
              <React.Fragment key={task.id}>
                <tr>
                  <td>{index + 1}</td>
                  <td>{task.wname}</td>
                  <td>{task.wnum}</td>
                  <td>
                    <div className="iconsWrap">
                      {task.status ? null : (
                        <span
                          title="Edit"
                          onClick={() =>
                            setWUpdateData({
                              id: task.id,
                              wname: task.wname,
                              wnum: task.wnum,
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </span>
                      )}

                      <span
                        title="Delete"
                        onClick={() => wdeleteContent(task.id)}
                        style={{ marginLeft: "1rem" }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </span>
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
    </>
  );
};

export default WList;
