import React, { useState, useEffect } from "react";
import locale from "../locale/mapping.json";
import "./Info.css";

const About = ({ lang }) => {
  const [content, setContent] = useState(locale[lang].info);
  useEffect(() => {
    setContent(locale[lang].info);
  }, [lang]);
  useEffect(() => {}, []);
  return (
    <div
      className="about_sec section__margin"
      data-aos="fade-up"
      data-aos-offset="100"
      data-aos-easing="ease"
      data-aos-duration="500"
    >
      <div className="about_sec_heading">
        <h1 className="gradient__text">{content.title}</h1>
      </div>
      <div className="about_container row">
        <div className="row">
          {window.innerWidth < 1000 ? (
            <div className="column img">
              <img
                src="images/howitworks1.svg"
                alt=""
                data-aos="slide-left"
                data-aos-easing="ease"
                data-aos-duration="900"
              />
            </div>
          ) : (
            ""
          )}
          <div className="column content">
            <ol className="gradient-list">
              <li
                data-aos="slide-up"
                data-aos-easing="ease"
                data-aos-duration="700"
                seq={1}
              >
                {" "}
                {content.step1}
              </li>
              <li
                data-aos="slide-up"
                data-aos-easing="ease"
                data-aos-duration="900"
                seq={2}
              >
                {content.step2}
              </li>
            </ol>
          </div>
          {window.innerWidth >= 1000 ? (
            <div className="column img">
              <img
                src="images/howitworks1.svg"
                alt=""
                data-aos="slide-left"
                data-aos-easing="ease"
                data-aos-duration="900"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="column img">
            <img
              src="images/howitworks2.svg"
              alt=""
              data-aos="slide-right"
              data-aos-easing="ease"
              data-aos-duration="900"
            />
          </div>
          <div className="column content">
            <ol className="gradient-list">
              <li
                data-aos="slide-up"
                data-aos-easing="ease"
                data-aos-duration="700"
                seq={3}
              >
                {content.step3}
              </li>
              <li
                data-aos="slide-up"
                data-aos-easing="ease"
                data-aos-duration="900"
                seq={4}
              >
                {content.step4}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
