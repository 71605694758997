import React, { useState, useEffect, useRef, useCallback } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import Dropdown from "./Dropdown";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import locale from "../locale/mapping.json";

import "./Navbar.css";

const theme1 = {
  navbar: "#7B1FA2",
  background: "#9C27B0",
  footer: "#4A148C",
};
const theme2 = {
  navbar: "#1565C0",
  background: "#1976D2",
  footer: "#0D47A1",
};
const theme3 = {
  navbar: "#00796B",
  background: "#009688",
  footer: "#004D40",
};

const ulVariants = {
  open: {
    clipPath: "inset(0% 0% 0% 0% round 10px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.7,
      staggerChildren: 0.07,
      delayChildren: 0.2,
    },
  },
  closed: {
    clipPath: "inset(10% 50% 90% 50% round 10px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.3,
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const liVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="#ffffff"
    strokeLinecap="round"
    {...props}
  />
);

const Navbar = ({ setLang, lang, setPage }) => {
  const [navbarToggle, setNavbarToggle] = useState(false);
  const [langDropDownToggle, setLangDropDownToggle] = useState(false);
  const [resourceDropDownToggle, setResourceDropDownToggle] = useState(false);
  const theme = useRef(theme3);
  const [content, setContent] = useState(locale[lang].navbar);
  useEffect(() => {
    setContent(locale[lang].navbar);
  }, [lang]);

  const containerRef = useRef(null);

  const onScroll = useCallback(() => {
    // var myNav = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      // myNav.classList.add("nav-colored");
      // myNav.classList.remove("nav-transparent");
      document.getElementById("navbar").style.backgroundColor =
        theme.current.navbar;
    } else {
      // myNav.classList.add("nav-transparent");
      // myNav.classList.remove("nav-colored");
      document.getElementById("navbar").style.backgroundColor = "transparent";
    }
  }, [theme]);

  const handleThemeChange = (e) => {
    const val = e.target.value;
    if (val === "theme2") {
      document.getElementsByTagName("body")[0].style.backgroundColor =
        theme2.background;
      document.getElementsByClassName("footer")[0].style.backgroundColor =
        theme2.footer;
      // document.getElementById("navbar").style.backgroundColor = theme2.navbar
      theme.current = theme2;
    } else if (val === "theme3") {
      document.getElementsByTagName("body")[0].style.backgroundColor =
        theme3.background;
      document.getElementsByClassName("footer")[0].style.backgroundColor =
        theme3.footer;
      // document.getElementById("navbar").style.backgroundColor = theme3.navbar
      theme.current = theme3;
    } else {
      document.getElementsByTagName("body")[0].style.backgroundColor =
        theme1.background;
      document.getElementsByClassName("footer")[0].style.backgroundColor =
        theme1.footer;
      // document.getElementById("navbar").style.backgroundColor = theme1.navbar
      theme.current = theme1;
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  return (
    <div className="header" id="navbar">
      <motion.nav
        initial={false}
        animate={
          window.innerWidth < 1000 ? (navbarToggle ? "open" : "closed") : "open"
        }
        ref={containerRef}
        className="navbar"
      >
        <a href="/" className="logo">
          <img
            src={window.origin + "/images/navlogo.png"}
            alt="logo"
            loading="eager"
            style={{ transform: "scale(1.3)" }}
          />
        </a>
        <div
          className="hamburger"
          onClick={() => setNavbarToggle(!navbarToggle)}
        >
          <svg width="23" height="23" viewBox="0 0 23 23">
            <Path
              variants={{
                closed: { d: "M 2 2.5 L 20 2.5" },
                open: { d: "M 3 16.5 L 17 2.5" },
              }}
            />
            <Path
              d="M 2 9.423 L 20 9.423"
              variants={{
                closed: { opacity: 1 },
                open: { opacity: 0 },
              }}
              transition={{ duration: 0.1 }}
            />
            <Path
              variants={{
                closed: { d: "M 2 16.346 L 20 16.346" },
                open: { d: "M 3 2.5 L 17 16.346" },
              }}
            />
          </svg>
          {/*{navbarToggle ? (
            <FaTimes size={30} style={{ color: "#ffffff" }} />
          ) : (
            <FaBars size={30} style={{ color: "#ffffff" }} />
          )}*/}
        </div>
        <motion.ul
          className={navbarToggle ? "nav-menu nav_active" : "nav-menu"}
          variants={window.innerWidth < 1000 ? ulVariants : {}}
        >
          {/*<li className="nav-item nav__menu-item">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={handleThemeChange}
            >
              <option value="theme1" defaultValue>
                Theme 1
              </option>
              <option value="theme2">Theme 2</option>
              <option value="theme3">Theme 3</option>
            </select>
          </li>*/}
          <motion.li
            className="nav-item nav__menu-item"
            variants={liVariants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link
              to="/"
              onClick={() => setNavbarToggle(!navbarToggle)}
              className="navigator"
            >
              {content.home}
            </Link>
          </motion.li>
          <motion.li
            className="nav-item nav__menu-item"
            variants={liVariants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link
              to="/about"
              onClick={() => setNavbarToggle(!navbarToggle)}
              className="navigator"
            >
              {content.about}
            </Link>
          </motion.li>
          <motion.li
            className="nav-item"
            variants={liVariants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <span
              className="navigator nav__menu-item"
              onClick={() => setResourceDropDownToggle(!resourceDropDownToggle)}
            >
              {content.knowledgeBlock} <i className="fas fa-caret-down"></i>
              {resourceDropDownToggle ? (
                <Dropdown
                  toggle={() => setNavbarToggle(!navbarToggle)}
                  content={content}
                />
              ) : (
                ""
              )}
            </span>
          </motion.li>
          <motion.li
            className="nav-item"
            variants={liVariants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <span
              to="/"
              className="navigator nav__menu-item"
              onClick={() => setLangDropDownToggle(!langDropDownToggle)}
            >
              {lang === "en" ? content.lang1 : content.lang2}{" "}
              <i className="fas fa-caret-down"></i>
              {langDropDownToggle ? (
                <ul className="nav__submenu">
                  <div
                    className="nav__submenu-item"
                    onClick={() => {
                      setLang("en");
                      setNavbarToggle(!navbarToggle);
                    }}
                  >
                    {content.lang1}
                  </div>
                  <div
                    className="nav__submenu-item"
                    onClick={() => {
                      setLang("hi");
                      setNavbarToggle(!navbarToggle);
                    }}
                  >
                    {content.lang2}
                  </div>
                </ul>
              ) : (
                ""
              )}
            </span>
          </motion.li>
          <motion.li
            className="nav-item nav__menu-item"
            variants={liVariants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link
              to="/faq"
              onClick={() => setNavbarToggle(!navbarToggle)}
              className="navigator"
            >
              {content.faq}
            </Link>
          </motion.li>
          {/*<Link
            className="btn_spacing nav-item btn btn-warning"
            to="/login"
            style={{ textTransform: 'none' }}
            onClick={() => {
              setNavbarToggle(!navbarToggle);
              setPage('login');
            }}
          >
            Login
          </Link>*/}
          {/*
          <Link
            to="/register"
            className="btn_spacing nav-item btn btn-outline-warning"
            onClick={() => {
              setNavbarToggle(!navbarToggle);
              setPage('register');
            }}
          >
            Sign-Up
          </Link>*/}
          <button
            type="button"
            className="btn_spacing nav-item btn btn-outline-warning"
            data-bs-toggle="modal"
            data-bs-target="#how_it_works"
          >
            {lang === "en" ? "How it Works" : "कार्यप्रणाली"}
          </button>
        </motion.ul>
      </motion.nav>
    </div>
  );
};

export default Navbar;
