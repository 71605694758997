import React, { useEffect, useState } from "react";
import axios from "axios";
import locale from "../locale/mapping.json";
import { Link } from "react-router-dom";
import "./Hero.css";

const Hero = ({ lang }) => {
  const [content, setContent] = useState(locale[lang].main);
  const [bannerImages, setBannerImages] = useState([
    {
      imgURL: "/images/hero1.jpg",
    },
    {
      imgURL: "/images/hero2.jpg",
    },
    {
      imgURL: "/images/hero3.jpg",
    },
    {
      imgURL: "/images/hero4.jpg",
    },
  ]);

  useEffect(() => {
    setContent(locale[lang].main);
    getBanners();
  }, [lang]);

  const getBanners = () => {
    axios
      .get(`${process.env.REACT_APP_API}/site/getBannerImages/${lang}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.response?.length) {
            const temp = res.data.response;
            temp.map((r) => {
              r.imgURL = `${process.env.REACT_APP_API}/banner/${lang}/${r.fileId}${r.fileExt}`;
            });
            setBannerImages([...temp]);
          }
        } else {
          // setBannerImages([])
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-pause="false"
      >
        <div className="carousel-indicators">
          {bannerImages.map((img, index) => (
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to={index}
              data-bs-interval="3000"
              aria-current="true"
              aria-label={`Slide ${index + 1}`}
              className={`${index === 0 ? "active" : ""}`}
              key={index}
            />
          ))}
        </div>
        <div className="carousel-inner">
          <Link to="/user-menu">
            <button className="btn-complaint" type="submit">
              {content.complaintBtn}
            </button>
          </Link>
          {bannerImages.map((img, index) => (
            <div
              className={`${index === 0 ? "active" : ""} carousel-item`}
              key={index}
            >
              <div className="herobg">
                <img src={img.imgURL} className="d-block w-100" alt="..." />
              </div>
              <div className="overlay" />
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      {/* <Carousel>
        <Link to="/login">
          <button className="btn-complaint" type="submit">
            {content.complaintBtn}
          </button>
        </Link>
        {
          bannerImages.map((img) => (
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100"
                src={`${process.env.REACT_APP_API}/banner/${lang}/${img.fileId}${img.fileExt}`}
                alt="Slide"
              />
            </Carousel.Item>
          ))
        }
    </Carousel> */}
    </>
  );
};

export default Hero;
