import React, { useState, useEffect } from "react";
import locale from "../locale/mapping.json";
import { Link } from "react-router-dom";

import "./Footer.css";

const Footer = ({ lang }) => {
  const [content, setContent] = useState(locale[lang].footer);
  useEffect(() => {
    setContent(locale[lang].footer);
  }, [lang]);
  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };
  return (
    <footer className="footersection" id="footerdiv">
      <div className="footer">
        {/*<div className="about_sec_heading">
          <h1
            className="gradient__text text-center"
            style={{ color: 'white', WebkitTextFillColor: 'white' }}
          >
            {content.h2}
          </h1>
        </div>*/}
        <div className="row">
          <div
            className="col-lg-4 col-md-5 col-sm-12"
            style={{ paddingTop: "1.5em" }}
          >
            <div className="col-lg-6 col-md-6 col-12 footer-div">
              <div>
                <h3>{content.h1}</h3>
                <p style={{ fontSize: "large" }}>
                  <b>{content.email}:</b> surakshachakra@myambar.org
                  {/* <br />
                  {content.address1}
                  <br />
                  {content.address2}
                  <br />
                  <br />
                  <b>{content.phone}:</b> 91-11-29960931-33
                  <br />
                  <b>{content.fax}:</b> 91-11-29955183 */}
                </p>
              </div>
            </div>
            {
              <div className="col-6 social">
                <a
                  href="https://www.facebook.com/marthafarrellfoundation"
                  aria-label="Facebook"
                  target="_blank"
                >
                  <i
                    className="fab fa-facebook-f fa-2x"
                    style={{ color: "aqua" }}
                  ></i>
                </a>
                <a
                  href="https://twitter.com/FoundationMf"
                  target="_blank"
                  aria-label="Twitter"
                >
                  <i
                    className="fab fa-twitter fa-2x"
                    style={{ color: "aqua" }}
                  ></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCMK2xQa7G1V23iDT5DaPNSA"
                  target="_blank"
                  aria-label="Youtube"
                >
                  <i
                    className="fab fa-youtube fa-2x"
                    style={{ color: "aqua" }}
                  ></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/martha-farrell-foundation/"
                  target="_blank"
                  aria-label="Linkedin"
                >
                  <i
                    className="fab fa-linkedin-in fa-2x"
                    style={{ color: "aqua" }}
                  ></i>
                </a>
              </div>
            }
          </div>

          <div
            className="col-lg-8 col-md-7 col-sm-12"
            style={{ paddingTop: "1.5em", margin: "auto" }}
          >
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-12 footer-div"></div>
              <div className="col-lg-3 col-md-4 col-sm-12 footer-div"></div>
              <div className="col-lg-3 col-md-4 col-sm-12 footer-div">
                <div>
                  <ul>
                    <li>
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#confidential"
                      >
                        {content.d1}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#presence"
                      >
                        {content.d2}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#termsOfUse"
                      >
                        {content.d3}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="text-center copyright">
              <p> © All Rights Reserved Martha Farrell Foundation</p>
            </div>

            <div className="row">
              <div className="col-md-6">
                {/*<p className="">
                  Powered by{' '}
                  <a target="_blank" href="https://adivid.com/">
                    Adivid Technologoies Pvt Ltd
                  </a>
                </p>*/}
              </div>
              <div className="col-md-6">
                <p className="text-end">
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#privacyPolicy"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

{
  /* <div className="col-lg-6 col-md-6 col-12 footer-div">
            <div>
              <h3>CONTACT WITH US</h3>
              <p>
                Email: info@marthafarrellfoundation.org
                <br />
                42 Tughlakabad Institutional Area
                <br />
                New Delhi 110062
                <br />
                <br />
                Phone: 91-11-29960931-33
                <br />
                Fax: 91-11-29955183
              </p>
            </div>
          </div>
<div className="col-lg-3 col-md-6 col-12 footerdiv ">
<div>
  <a
    href="#"
    className="btn btn-primary"
    style={{
      marginTop: '20px',
      fontSize: '17px',
      fontWeight: 'bold',
      backgroundColor: 'blueviolet',
      padding: '5px 10px',
    }}
  >
    Donate
  </a>
</div>
</div> */
}
