import "./App.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import locale from "./locale/mapping.json";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ScrollToTop from "./scrollToTop";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import Counter from "./components/Counter";
import Footer from "./components/Footer";
import Background from "./components/Background";
import Main from "./components/Main";
import Blogs from "./components/Blog";
import Documents from "./components/Document";
import Videos from "./components/Video";
import Partners from "./components/Partners";
import About_Us from "./components/About_Us";
import FAQ from "./components/FAQ";
import Info from "./components/Info";
import Research from "./components/Research";
import Laws from "./components/Laws";
import Articles from "./components/Articles";
import Aos from "aos";
import "aos/dist/aos.css";
import Register from "./components/Register";
import Modals from "./components/Modals";
import ComplaintForm from "./components/ComplaintForm";
import TrackComplaint from "./components/TrackComplaint";
import ShowBlog from "./components/ShowBlog";
import Modal from "./components/Modal";

const App = () => {
  const [lang, setLang] = useState(
    window.localStorage.getItem("mffLang") || "en"
  );
  const [content, setContent] = useState(locale[lang]);
  const [page, setPage] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [modalId, setModalId] = useState("");
  const [modalData, setModalData] = useState("");

  useEffect(() => {
    Aos.init();
    axios
      .get(`${process.env.REACT_APP_API}/blogs/readAll`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.response?.length) {
            setBlogs([...res.data.response]);
          }
        } else {
          setBlogs([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    window.localStorage.setItem("mffLang", lang);
    document.documentElement.setAttribute("lang", lang);
    setContent(locale[lang]);
  }, [lang]);

  const ProtectedRoute = ({ children }) => {
    const [isTokenValid, setIsTokenValid] = useState(true);
    const mffUser = JSON.parse(window.sessionStorage.getItem("mffUser"));
    const user_id = mffUser?.user_id;
    const token = mffUser?.token;

    useEffect(() => {
      if (!Boolean(token)) {
        setIsTokenValid(false);
        return;
      }

      axios
        .post("https://backoffice.myambar.org/api/complaint-details", {
          user_id,
          token,
        })
        .then((response) => {
          if (response.data.status_code === 440) {
            window.sessionStorage.removeItem("mffUser");
            setIsTokenValid(false);
          }
        })
        .catch(() => {
          setIsTokenValid(false);
        });
    }, [user_id, token]);

    if (!isTokenValid) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  return (
    <div>
      <Background />
      <BrowserRouter>
        <ScrollToTop />
        <Navbar setLang={setLang} lang={lang} setPage={setPage} />
        <div>
          <Routes>
            <Route path="/about" element={<About_Us lang={lang} />} />
            <Route path="/faq" element={<FAQ lang={lang} />} />

            <Route path="/laws" element={<Laws lang={lang} />} />
            <Route path="/research" element={<Research lang={lang} />} />
            <Route path="/login" element={<Register lang={lang} />} />
            <Route exact path="/blogs/*" element={<Blogs blogs={blogs} />} />
            {blogs.map((blog, index) => {
              return (
                <Route
                  key={index}
                  exact
                  path={"/blog/" + blog.blogURL}
                  element={<ShowBlog blog={blog} />}
                />
              );
            })}
            <Route path="/videos" element={<Videos />} />
            <Route path="/docs" element={<Documents />} />
            <Route path="/articles" element={<Articles />} />
            <Route
              path="/user-menu"
              element={
                <ProtectedRoute>
                  <Register lang={lang} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/complaint-form"
              element={
                <ProtectedRoute>
                  <ComplaintForm lang={lang} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/track-complaint"
              element={
                <ProtectedRoute>
                  <TrackComplaint lang={lang} />
                </ProtectedRoute>
              }
            />

            <Route
              path="/"
              element={
                <>
                  <Hero lang={lang} />
                  <Main lang={lang} />
                  <About lang={lang} />
                  <Info lang={lang} />
                  <Counter lang={lang} />
                  <Partners lang={lang} />
                </>
              }
            />
          </Routes>
        </div>
        <Footer lang={lang} />
        <Modals lang={lang} />
        <Modal
          id="qa1"
          data="<p>There are two forums for complaint under this law:</p><p><strong>Internal Committee (IC):</strong>formed in organisations with 10 employees or more. Women working in these institutions should file a complaint with their IC first.</p><p><strong>Local Committee (LC):</strong> formed by the District Officer in the District Administration to receive complaints of sexual harassment from establishments where there’s no Internal Committee and/or when the complaint is against the employer themselves (informal women workers).</p>"
        />
      </BrowserRouter>
    </div>
  );
};

export default App;
