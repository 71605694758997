import { useEffect, useState } from "react";
import "./Main.css";
import locale from "../locale/mapping.json";

const Main = ({ lang }) => {
  const [content, setContent] = useState(locale[lang].main);

  useEffect(() => {
    setContent(locale[lang].main);
  }, [lang]);

  return (
    <div
      className="main"
      data-aos="fade-up"
      data-aos-offset="100"
      data-aos-easing="ease"
      data-aos-duration="500"
    >
      <div className="main_heading">
        <h1 className="">{content.title}</h1>
      </div>
      <div className="about_container row" style={{ minHeight: "30em" }}>
        <div
          className="column content"
          data-aos="slide-right"
          data-aos-easing="ease"
          data-aos-duration="800"
          style={{ alignSelf: "center", textAlign: "center" }}
        >
          <p
            style={{
              color: "white",
              marginBottom: "1em",
              textAlign: "justify",
              maxWidth: "40em",
              margin: "auto",
            }}
            id="main-p"
          >
            {content.content}
          </p>
        </div>
        <div
          className="column video"
          data-aos="slide-left"
          data-aos-easing="ease"
          data-aos-duration="800"
          style={{ display: "flex", alignItems: "center", height: "auto" }}
        >
          <video style={{ maxHeight: "25em" }} controls>
            <source
              src="images/Basic_of_Sexual_Harassment_at_workplace.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      {/*<div className="device">
        <div
          className="iphone-x"
          style={{ backgroundImage: "url(images/amabarlogo.png)" }}
        >
          <i></i>
          <b></b>
        </div>
      </div>*/}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="whitesmoke"
          fillOpacity="1"
          d="M0,224L40,202.7C80,181,160,139,240,106.7C320,75,400,53,480,64C560,75,640,117,720,138.7C800,160,880,160,960,181.3C1040,203,1120,245,1200,229.3C1280,213,1360,139,1400,101.3L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
};

export default Main;
