import React, { useState, useEffect } from "react";
import axios from 'axios';
import locale from "../locale/mapping.json";
import "./About.css";
import "./About_Us.css";
import "./Info.css";

const PreviewParagraph = ({ text, wordLimit, lang }) => {
  const [showButton, setShowButton] = useState(false);

  const truncateParagraph = (text, limit) => {
    const words = text.split(' ');

    if (words.length > limit) {
      const truncatedText = words.slice(0, limit).join(' ');
      return `${truncatedText}...`;
    }

    return text;
  };

  const handleButtonClick = () => {
    setShowButton(!showButton);
  };

  const truncatedText = truncateParagraph(text, wordLimit);
  return (
    <div>
      <p
        className={showButton ? 'show' : 'hide'}
        dangerouslySetInnerHTML={{ __html: text }}
      ></p>
      <p
        className={showButton ? 'hide' : 'show'}
        dangerouslySetInnerHTML={{ __html: truncatedText }}
      ></p>      
      <br/>
      {truncatedText !== text && (
        <button className="btn btn-primary" onClick={handleButtonClick} style={{backgroundColor: "rgb(115, 113, 238)"}}>
          {lang==="en" && (showButton ? 'Read Less' : 'Read More')}
          {lang==="hi" && (showButton ? 'कम पढ़ें' : 'और पढ़े')}
        </button>
      )}
    </div>
  );
};

const About = ({ lang }) => {
  const [content, setContent] = useState(locale[lang].aboutUS);
  const [aboutUS, setAboutUS] = useState({})

  const titleMap = {
    aboutMyAmbar: {
      en: "MyAmbar",
      hi: "माय अंबर",
      img: "images/about3.svg"
    },
    aboutMyAmbarSurakshaChakra: {
      en: "MyAmbar Suraksha Chakra",
      hi: "माय अंबर सुरक्षा चक्र",
      img: "images/about1.svg"
    },
    aboutVodafoneIdeaFoundation: {
      en: "Vodafone Idea Foundation",
      hi: "वोडाफोन आइडिया फाउंडेशन",
      img: "images/about5.svg"
    },
    aboutNasscom: {
      en: "NASSCOM Foundation",
      hi: "नैसकॉम फाउंडेशन",
      img: "images/about4.svg"
    },
    aboutMarthaFarrell: {
      en:  "Martha Farrell Foundation",
      hi: "मार्था फैरेल फाउंडेशन",
      img: "images/about2.svg"
    }
  }

  const getAboutUs = () => {
    axios
    .get(`${process.env.REACT_APP_API}/site/getAbout`)
    .then((res) => {
      if (res.status === 200) {
        const JsonData = res.data.response
        if (Object.keys(JsonData).length) {
          setAboutUS({...JsonData})
        }
      } else {
        setAboutUS({})
      }
    })
    .catch((err) => {
      console.log(err)
    });
  }

  useEffect(() => {
    setContent(locale[lang].aboutUS);
    getAboutUs();
  }, [lang]);

  const handleReadMore = (id, cls) => {
    let btn = document.getElementById(id);
    if (lang === "en" && btn.innerHTML === "Read More") {
      document.getElementsByClassName(cls)[0].style.display = "block";
      btn.innerHTML = "Read Less";
    } else if (lang === "en" && btn.innerHTML === "Read Less") {
      document.getElementsByClassName(cls)[0].style.display = "none";
      btn.innerHTML = "Read More";
    }
    if (lang === "hi" && btn.innerHTML === "और पढ़े") {
      document.getElementsByClassName(cls)[0].style.display = "block";
      btn.innerHTML = "कम पढ़ें";
    } else if (lang === "hi" && btn.innerHTML === "कम पढ़ें") {
      document.getElementsByClassName(cls)[0].style.display = "none";
      btn.innerHTML = "और पढ़े";
    }
  };
  return (
    <>
      <div
        data-aos="fade-up"
        data-aos-offset="100"
        data-aos-easing="ease"
        data-aos-duration="500"
      >
        {/* About section Heading */}
        <div className="about_main">
          <div className="about_main-content">
            <h1>{content.title}</h1>
          </div>
        </div>

        <div className="about-container">
          <div
            className="about_sec section__margin"
            style={{ marginTop: "0px" }}
          >
            {
              Object.keys(aboutUS).map((about, index) => (
                <div
                  className="sm:flex items-center"
                  data-aos={`slide-${index % 2 === 0 ? "right" : "left"}`}
                  data-aos-offset="100"
                  data-aos-easing="ease"
                  data-aos-duration="900"
                >
                  {
                    (index % 2 === 0 || window.innerWidth < 650) ?
                    <div className="sm:w-1/2 p-10" style={{ paddingBottom: "0" }}>
                    <div className="image object-center text-center">
                      <div className="sm:flex items-center">
                        <div
                          className="sm:w-1/2"
                          style={{ width: "-webkit-fill-available" }}
                        >
                          <div className="image object-center text-center">
                            <img src={titleMap[about].img} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> : ""
                  }
                  
                  <div className="sm:w-1/2 p-5">
                    <div className="text">
                      <h2 className="mb-4 font-bold text-3xl  sm:text-4xl ">
                        {content.about}{" "}
                        <span className="gradient__text">{titleMap[about][lang]}</span>
                      </h2>
                      <PreviewParagraph text={aboutUS[about][lang]} wordLimit={100} lang={lang} />
                    </div>
                  </div>

                  {
                    (index % 2 !== 0 && window.innerWidth >= 650) ?
                    <div className="sm:w-1/2 p-10" style={{ paddingBottom: "0" }}>
                    <div className="image object-center text-center">
                      <div className="sm:flex items-center">
                        <div
                          className="sm:w-1/2"
                          style={{ width: "-webkit-fill-available" }}
                        >
                          <div className="image object-center text-center">
                            <img src={titleMap[about].img} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> : ""
                  }
                  
                </div>
              ))
            }
            {/* MyAmbar Suraksha Chakra */}
{/* 
            <div
              className="sm:flex items-center"
              data-aos="slide-left"
              data-aos-offset="100"
              data-aos-easing="ease"
              data-aos-duration="900"
            >
              <div className="sm:w-1/2 p-10" style={{ paddingBottom: "0" }}>
                <div className="image object-center text-center">
                  <div className="sm:flex items-center">
                    <div
                      className="sm:w-1/2"
                      style={{ width: "-webkit-fill-available" }}
                    >
                      <div className="image object-center text-center">
                        <img src="images/about1.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:w-1/2 p-5">
                <div className="text">
                  <h2 className="mb-4 font-bold text-3xl  sm:text-4xl ">
                    {content.about}{" "}
                    <span className="gradient__text">{content.h1}</span>
                  </h2>
                  <p>{content.h1p1}</p>
                </div>
              </div>
            </div> */}
            {/* MyAmbar */}
            {/* <div
              className="sm:flex items-center"
              data-aos="slide-right"
              data-aos-offset="100"
              data-aos-easing="ease"
              data-aos-duration="900"
            >
              <div className="sm:w-1/2 p-5">
                <div className="text">
                  <h2 className="mb-4 font-bold text-3xl  sm:text-4xl ">
                    {content.about}{" "}
                    <span className="gradient__text">{content.h3}</span>
                  </h2>
                  <p>{content.h3p1}</p>
                  <br />
                  <p className="sidectn3">
                    {content.h3p2} <br />
                    <ul>
                      {" "}
                      <br />
                      <li
                        style={{
                          paddingLeft: "1em",
                          listStyle: "disc",
                          marginLeft: "1em",
                        }}
                      >
                        {content.h3l1}
                      </li>
                      <li
                        style={{
                          paddingLeft: "1em",
                          listStyle: "disc",
                          marginLeft: "1em",
                        }}
                      >
                        {content.h3l2}
                      </li>
                      <li
                        style={{
                          paddingLeft: "1em",
                          listStyle: "disc",
                          marginLeft: "1em",
                        }}
                      >
                        {content.h3l3}
                      </li>
                      <li
                        style={{
                          paddingLeft: "1em",
                          listStyle: "disc",
                          marginLeft: "1em",
                        }}
                      >
                        {content.h3l4}
                      </li>
                      <li
                        style={{
                          paddingLeft: "1em",
                          listStyle: "disc",
                          marginLeft: "1em",
                        }}
                      >
                        {content.h3l5}
                      </li>
                    </ul>
                    <br />
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary tggl3"
                    style={{ backgroundColor: "#7371ee" }}
                    id="tggl3"
                    onClick={() => handleReadMore("tggl3", "sidectn3")}
                  >
                    {content.readMore}
                  </button>
                </div>
              </div>
              <div className="sm:w-1/2 p-10" style={{ paddingBottom: "0" }}>
                <div className="image object-center text-center">
                  <div className="sm:flex items-center">
                    <div
                      className="sm:w-1/2"
                      style={{ width: "-webkit-fill-available" }}
                    >
                      <div className="image object-center text-center">
                        <img src="images/about3.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* Vodafone Idea Foundation */}
            {/* <div
              className="sm:flex items-center"
              data-aos="slide-left"
              data-aos-offset="100"
              data-aos-easing="ease"
              data-aos-duration="900"
            >
              <div className="sm:w-1/2 p-10">
                <div className="image object-center text-center">
                  <div className="sm:flex items-center">
                    <div
                      className="sm:w-1/2"
                      style={{ width: "-webkit-fill-available" }}
                    >
                      <div className="image object-center text-center">
                        <img src="images/about5.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:w-1/2 p-5" style={{ paddingBottom: "0" }}>
                <div className="text">
                  <h2 className="mb-4 font-bold text-3xl  sm:text-4xl ">
                    {content.about}{" "}
                    <span className="gradient__text">{content.h5}</span>
                  </h2>
                  <p>
                    {content.h5p1}
                    <br />
                  </p>
                  <br />
                  <p className="sidectn5">
                    {content.h5p2}
                    <br />
                    <br />
                    {content.h5p3}
                    <br />
                    {content.h5p4}
                    <br />
                    {content.h5p5}{" "}
                    <a
                      target="_blank"
                      href="https://www.myvi.in/about-us/vodafoneidea-foundation"
                      style={{ color: "blue" }}
                    >
                      {content.h5link1}
                    </a>
                    .
                    <br />
                    <br />
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary tggl5"
                    style={{ backgroundColor: "#7371ee" }}
                    id="tggl5"
                    onClick={() => handleReadMore("tggl5", "sidectn5")}
                  >
                    {content.readMore}
                  </button>
                </div>
              </div>
            </div> */}
            {/* NASSCOM Foundation */}
            {/* <div
              className="sm:flex items-center"
              data-aos="slide-right"
              data-aos-offset="100"
              data-aos-easing="ease"
              data-aos-duration="900"
            >
              <div className="sm:w-1/2 p-5" style={{ paddingBottom: "0" }}>
                <div className="text">
                  <h2 className="mb-4 font-bold text-3xl  sm:text-4xl ">
                    {content.about}{" "}
                    <span className="gradient__text">{content.h4}</span>
                  </h2>
                  <p>
                    {content.h4p1}
                    <br />
                  </p>
                  <br />
                  <p className="sidectn4">
                    {content.h4p2}
                    <br />
                    <br />
                    {content.h4p3}
                    <br />
                    <br />
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary tggl4"
                    style={{ backgroundColor: "#7371ee" }}
                    id="tggl4"
                    onClick={() => handleReadMore("tggl4", "sidectn4")}
                  >
                    {content.readMore}
                  </button>
                </div>
              </div>
              <div className="sm:w-1/2 p-10">
                <div className="image object-center text-center">
                  <div className="sm:flex items-center">
                    <div
                      className="sm:w-1/2"
                      style={{ width: "-webkit-fill-available" }}
                    >
                      <div className="image object-center text-center">
                        <img src="images/about4.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* Martha Farrell Foundation */}
            {/* <div
              className="sm:flex items-center"
              data-aos="slide-left"
              data-aos-offset="100"
              data-aos-easing="ease"
              data-aos-duration="900"
            >
              <div className="sm:w-1/2 p-10">
                <div className="image object-center text-center">
                  <div className="sm:flex items-center">
                    <div
                      className="sm:w-1/2"
                      style={{ width: "-webkit-fill-available" }}
                    >
                      <div className="image object-center text-center">
                        <img src="images/about2.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:w-1/2 p-5" style={{ paddingBottom: "0" }}>
                <div className="text">
                  <h2 className="mb-4 font-bold text-3xl  sm:text-4xl ">
                    {content.about}{" "}
                    <span className="gradient__text">{content.h2}</span>
                  </h2>
                  <p className="maincnt2">{content.h2p1}</p>
                  <br />{" "}
                  <p className="sidectn2">
                    {content.h2p1next}
                    <br />
                    <br />
                    {content.h2p2}
                    <br />
                    <br />
                    {content.h2p3}{" "}
                    <a
                      target="_blank"
                      href="https://www.marthafarrellfoundation.org/"
                      style={{ color: "blue" }}
                    >
                      {content.h2link1}
                    </a>
                    .
                    <br />
                    <br />
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary tggl"
                    id="tggl2"
                    style={{ backgroundColor: "#7371ee" }}
                    onClick={() => handleReadMore("tggl2", "sidectn2")}
                  >
                    {content.readMore}
                  </button>
                </div>
              </div>
            </div> */}
            {/*
        <div className="about_us_wrapper">
          <div className="about_us_container_col">
            <Feature
              title="The Sexual Harassment of Women at Workplace (Prevention, Prohibition, and Redressal) Act, 2013"
              text="This law provides protection against sexual harassment of women at the workplace and outlines the procedure for addressing complaints of harassment. "
            />
            <Feature
              title="The Indian Penal Code"
              text="The IPC includes various sections related to harassment, such as Section 354 (assault or criminal force to woman with intent to outrage her modesty), Section 354A (sexual harassment and punishment for sexual harassment), and Section 509 (word, gesture, or act intended to insult the modesty of a woman). "
            />
            <Feature
              title="The Protection of Women from Domestic Violence Act, 2005"
              text="This law provides protection to women who are subjected to physical, emotional, sexual, verbal, or economic abuse by their partners or family members. "
            />
            <Feature
              title="The Indecent Representation of Women (Prohibition) Act, 1986"
              text="This law prohibits the indecent representation of women through advertisements, books, paintings, films, or any other media. "
            />
            <Feature
              title="The Dowry Prohibition Act, 1961"
              text="This law prohibits the giving or taking of dowry, which is a common form of harassment of women in India. "
            />
          </div>
        </div>
        <div className="about_closing">
          <p>
            It's important to note that while these laws exist, there is still a
            lot of work to be done to ensure their effective implementation and
            to create a safer environment for women in India.
          </p>
        </div>*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
