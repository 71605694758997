import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { stateDistricts, stateDistricts_hi } from "./resources/stateDistrict";
import List from "./List";
import axios from "axios";
import "./ComplaintForm.css";
import EvidenceForm from "./EvidenceForm";
import UpdateEvidenceForm from "./UpdateEvidenceForm";
import WList from "./WList";
import Wform from "./Wform";
import WupdateForm from "./WupdateForm";

let JSTimers = undefined;

const Timer = ({ duration, count }) => {
  const [timer, setTimer] = useState(duration);

  useEffect(() => {
    let intervalId = null;
    if (count !== 0) {
      intervalId = setInterval(() => {
        const minutes = parseInt(timer / 60, 10);
        const seconds = parseInt(timer % 60, 10);
        const display = `${minutes < 10 ? "0" : ""}${minutes}:${
          seconds < 10 ? "0" : ""
        }${seconds}`;
        setTimer((prevTimer) => prevTimer - 1);
        if (timer < 0) {
          clearInterval(intervalId);
          location.reload(true);
        }
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [count, timer]);

  return <div id="time">{`${Math.floor(timer / 60)}:${timer % 60}`}</div>;
};

const ComplaintForm = ({ lang }) => {
  const navigate = useNavigate();
  const mffUser = JSON.parse(window.sessionStorage.getItem("mffUser"));
  const [recording, setRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [audioDuration, setAudioDuration] = useState("");
  const recorder = useRef(null);
  const chunks = useRef([]);
  const [states, setStates] = useState(Object.keys(stateDistricts));
  const [districts, setDistricts] = useState([]);
  const [districtsHi, setDistrictsHi] = useState([]);
  const [pincode, setPincode] = useState({
    code: "",
    district: "",
    state: "",
    error: "",
  });
  const [evidenceDescription, setEvidenceDescription] = useState("");
  const [evidenceType, setEvidenceType] = useState(
    lang === "en" ? "Please select" : "कृपया चयन कीजिए"
  );
  const [evidenceFile, setEvidenceFile] = useState(undefined);
  const [list, setList] = useState([]);
  const [updateData, setUpdateData] = useState("");

  const [wnum, setWnum] = useState("");
  const [wname, setWname] = useState("");
  const [wlist, setWList] = useState([]);
  const [wupdateData, setWUpdateData] = useState("");
  const [submitMsg, setSubmitMsg] = useState("");
  const [step1Allvalid, setStep1AllValid] = useState(false);
  const [step2Allvalid, setStep2AllValid] = useState(false);
  const [step3Allvalid, setStep3AllValid] = useState(false);
  const [step4Allvalid, setStep4AllValid] = useState(false);
  const [step5Allvalid, setStep5AllValid] = useState(false);
  const [isTnCAgree, setIsTnCAgree] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState({
    state: false,
    triggeredFrom: "",
  });
  const [submitpass, setSubmitpass] = useState(true);
  const [haveEvidence, setHaveEvidence] = useState(false);
  const [haveWitness, setHaveWitness] = useState(false);
  const [formData, setFormData] = useState({
    full_name: mffUser.first_name + " " + mffUser.last_name,
    phone_number: mffUser.mobile,
    age: "",
    occupation: "",
    pincode: "",
    state: "",
    district: "",
    description_of_camplaint: "",
    audio_file_camplaint: "",
    respondent_full_name: "",
    respondent_mobile: "",
    respondent_address: "",
    respondent_relation: "",
    respondent_email: "",
    respondent_physical_appearance: "",
    evidence_type: [],
    evidence_description: [],
    evidence_attachment: [],
    witness_name: [],
    witness_contact_number: [],
  });

  const [error, setError] = useState({
    // Already filled
    full_name: {
      valid: "",
      isEmpty: "Default",
      inValid:
        lang === "en"
          ? "Can only contain characters!"
          : "केवल वर्ण हो सकते हैं!",
      isValid: true,
      validation: /^[a-zA-Z ,.'-]+$/,
    },
    // Already filled
    phone_number: {
      valid: "",
      inValid:
        lang === "en"
          ? "Enter correct Phone Number!"
          : "सही फ़ोन नंबर दर्ज करें!",
      isValid: true,
      validation: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    },
    age: {
      valid: "",
      inValid: lang === "en" ? "Enter Valid age!" : "मान्य आयु दर्ज करें!",
      isValid: undefined,
      validation: /^\d{0,2}$/,
    },
    occupation: {
      valid: "",
      inValid: lang === "en" ? "Cannot be Empty!" : "यह फ़ील्ड आवश्यक है!",
      isValid: undefined,
      validation: /^$|^[a-zA-Z ,.'-]+$/,
    },
    pincode: {
      valid: "",
      inValid: lang === "en" ? "Required Field!" : "यह फ़ील्ड आवश्यक है!",
      isValid: undefined,
      validation: /^.+$/,
    },
    state: {
      valid: "",
      inValid: lang === "en" ? "Required Field!" : "यह फ़ील्ड आवश्यक है!",
      isValid: undefined,
      validation: /^.+$/,
    },
    district: {
      valid: "",
      inValid: lang === "en" ? "Required Field!" : "यह फ़ील्ड आवश्यक है!",
      isValid: undefined,
      validation: /^.+$/,
    },
    description_of_camplaint: {
      valid: "",
      inValid: lang === "en" ? "Cannot be Empty!" : "यह फ़ील्ड आवश्यक है!",
      isValid: undefined,
      validation: /^$|^.+$/,
    },
    audio_file_camplaint: {
      isValid: undefined,
    },
    respondent_full_name: {
      valid: "",
      inValid:
        lang === "en"
          ? "Can only contain characters!"
          : "केवल वर्ण हो सकते हैं!",
      isValid: undefined,
      validation: /^$|^[a-zA-Z ,.'-]+$/,
    },
    respondent_mobile: {
      valid: "",
      inValid:
        lang === "en"
          ? "Enter correct Phone Number!"
          : "सही फ़ोन नंबर दर्ज करें!",
      isValid: undefined,
      validation: /^[0-9]{10}$/,
    },
    respondent_address: {
      valid: "",
      inValid: lang === "en" ? "Cannot be Empty" : "यह फ़ील्ड आवश्यक है!",
      isValid: undefined,
      validation: /^$|^.+$/,
    },
    respondent_relation: {
      valid: "",
      inValid: lang === "en" ? "Cannot be Empty" : "यह फ़ील्ड आवश्यक है!",
      isValid: undefined,
      validation: /^$|^.+$/,
    },
    respondent_email: {
      valid: "",
      inValid: lang === "en" ? "Cannot be Empty" : "यह फ़ील्ड आवश्यक है!",
      isValid: undefined,
      validation: /^$|^.+$/,
    },
    respondent_physical_appearance: {
      valid: "",
      inValid: lang === "en" ? "Cannot be Empty" : "यह फ़ील्ड आवश्यक है!",
      isValid: undefined,
      validation: /^$|^.+$/,
    },
    evidence_type: {
      valid: "",
      inValid: lang === "en" ? "Required Field!" : "यह फ़ील्ड आवश्यक है!",
      isValid: undefined,
      validation: /^$|^.+$/,
    },
    evidence_description: {
      valid: "",
      inValid: lang === "en" ? "Required Field!" : "यह फ़ील्ड आवश्यक है!",
      isValid: undefined,
      validation: /^$|^.+$/,
    },
    evidence_attachment: {
      valid: "",
      inValid: lang === "en" ? "Required Field!" : "यह फ़ील्ड आवश्यक है!",
      isValid: undefined,
      validation: /^$|^.+$/,
    },
    witness_name: {
      valid: "",
      inValid:
        lang === "en"
          ? "Can only contain characters!"
          : "केवल वर्ण हो सकते हैं!",
      isValid: undefined,
      validation: /^$|^[a-zA-Z ,.'-]+$/,
    },
    witness_contact_number: {
      valid: "",
      inValid:
        lang === "en"
          ? "Enter correct Phone Number!"
          : "सही फ़ोन नंबर दर्ज करें!",
      isValid: undefined,
      validation: /^$|^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    },
  });

  useEffect(() => {
    if (formData.respondent_mobile.length === 10) {
      setError({
        ...error,
        respondent_mobile: { ...error["respondent_mobile"], isValid: true },
      });
    } else {
      setError({
        ...error,
        respondent_mobile: { ...error["respondent_mobile"], isValid: false },
      });
    }
  }, [formData.respondent_mobile]);

  const handleFormChange = (e) => {
    const value = e.target.value;
    const name = e.target.id || e.target.name;
    if (name === "respondent_mobile") {
      setFormData({ ...formData, [name]: value });
    } else if (error[name].validation.test(value)) {
      setError({ ...error, [name]: { ...error[name], isValid: true } });
      setFormData({ ...formData, [name]: value });
    } else {
      // setError({ ...error, [name]: { ...error[name], isValid: false } });
    }
  };

  const checkvalidations = (step) => {
    let check = true;
    if (step === "step1") {
      if (
        !(
          error["full_name"].isValid &&
          error["phone_number"].isValid &&
          error["age"].isValid &&
          formData.age.length &&
          (error["pincode"].isValid ||
            (error["district"].isValid && formData.district.length))
        )
      ) {
        check = false;
      }
    } else if (step === "step2") {
      if (
        !(
          (error["description_of_camplaint"].isValid &&
            formData.description_of_camplaint.length) ||
          error["audio_file_camplaint"].isValid
        )
      ) {
        check = false;
      }
    } else if (step === "step3") {
      if (
        !(
          error["respondent_full_name"].isValid &&
          formData.respondent_full_name.length &&
          ((error["respondent_mobile"].isValid && formData.respondent_mobile) ||
            (error["respondent_address"].isValid &&
              formData.respondent_address))
        )
      ) {
        check = false;
      }
    } else if (step === "step4") {
      // No validation
    } else {
      // No validation
    }
    return check;
  };

  useEffect(() => {
    checkvalidations("step1")
      ? setStep1AllValid(true)
      : setStep1AllValid(false);
    checkvalidations("step2")
      ? setStep2AllValid(true)
      : setStep2AllValid(false);
    checkvalidations("step3")
      ? setStep3AllValid(true)
      : setStep3AllValid(false);
    checkvalidations("step4")
      ? setStep4AllValid(true)
      : setStep4AllValid(false);
    checkvalidations("step5")
      ? setStep5AllValid(true)
      : setStep5AllValid(false);
  }, [error]);

  useEffect(() => {
    setFormData({
      ...formData,
      pincode: pincode.code,
      state: pincode.state,
      district: pincode.district,
    });
    if (pincode.district) {
      setError({
        ...error,
        pincode: { ...error["pincode"], isValid: true },
        state: { ...error["state"], isValid: true },
        district: { ...error["district"], isValid: true },
      });
    } else {
      setError({
        ...error,
        pincode: { ...error["pincode"], isValid: false },
        state: { ...error["state"], isValid: false },
        district: { ...error["district"], isValid: false },
      });
    }
  }, [pincode]);

  useEffect(() => {
    let evidence_type = list.map((e) => e.evidenceType);
    let evidence_description = list.map((e) => e.evidenceDescription);
    let evidence_attachment = list.map((e) => e.evidenceFile);
    setFormData({
      ...formData,
      evidence_type,
      evidence_description,
      evidence_attachment,
    });
  }, [list]);

  useEffect(() => {
    let witness_name = wlist.map((e) => e.wname);
    let witness_contact_number = wlist.map((e) => e.wnum);
    setFormData({ ...formData, witness_name, witness_contact_number });
  }, [wlist]);

  const addContent = () => {
    if (evidenceType || evidenceDescription || evidenceFile) {
      let num = list.length + 1;
      let newContent = {
        id: num,
        evidenceType: evidenceType,
        evidenceDescription: evidenceDescription,
        evidenceFile: evidenceFile,
      };
      setList([...list, newContent]);
    }
    setEvidenceDescription("");
    setEvidenceType(lang === "en" ? "Please select" : "कृपया चयन कीजिए");
    setEvidenceFile(undefined);
  };

  const updateContent = (evidenceType, evidenceDescription, evidenceFile) => {
    let newContent = {
      id: updateData.id,
      evidenceType: evidenceType,
      evidenceDescription: evidenceDescription,
      evidenceFile: evidenceFile,
    };

    let filterRecords = [...list].filter((task) => task.id !== updateData.id);
    let updatedObject = [...filterRecords, newContent];
    setList(updatedObject);
    setUpdateData("");
  };

  const deleteContent = (id) => {
    let newContent = list.filter((task) => task.id != id);
    setList(newContent);
  };

  const waddContent = () => {
    if (wname && wnum) {
      let num = wlist.length + 1;
      let newContent = {
        id: num,
        wname: wname,
        wnum: wnum,
      };
      setWList([...wlist, newContent]);
    }
    setWname("");
    setWnum("");
  };

  const wupdateContent = (wname, wnum) => {
    let newContent = {
      id: wupdateData.id,
      wname: wname,
      wnum: wnum,
    };

    let filterRecords = [...wlist].filter((task) => task.id !== wupdateData.id);
    let updatedObject = [...filterRecords, newContent];
    setWList(updatedObject);
    setWUpdateData("");
  };

  const wdeleteContent = (id) => {
    let newContent = wlist.filter((task) => task.id != id);
    setWList(newContent);
  };

  const getAudioDuration = (audioBlob) => {
    const audioElement = document.createElement("audio");
    document.body.appendChild(audioElement);

    const objectURL = URL.createObjectURL(audioBlob);
    audioElement.src = objectURL;

    audioElement.addEventListener("loadedmetadata", () => {
      if (
        audioElement.duration === Infinity ||
        isNaN(Number(audioElement.duration))
      ) {
        audioElement.currentTime = 1e101;
        audioElement.addEventListener("timeupdate", getDuration);
      }
    });

    function getDuration() {
      audioElement.currentTime = 0;
      audioElement.removeEventListener("timeupdate", getDuration);
      const timeInSeconds = audioElement.duration;
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.round(timeInSeconds % 60);
      setAudioDuration(`${minutes}m ${seconds}s`);
      document.body.removeChild(audioElement);
    }
  };

  const handleStart = () => {
    const maxRecordingTime = 2 * 60 * 1000;
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      recorder.current = new MediaRecorder(stream);
      recorder.current.addEventListener("dataavailable", (event) => {
        chunks.current.push(event.data);
      });
      recorder.current.addEventListener("stop", () => {
        const audioBlob = new Blob(chunks.current, { type: "audio/wav" });
        setError({
          ...error,
          audio_file_camplaint: {
            ...error["audio_file_camplaint"],
            isValid: true,
          },
        });
        const audioURL = URL.createObjectURL(audioBlob);
        setAudioURL(audioURL);
        getAudioDuration(audioBlob);

        if (!formData.description_of_camplaint.length) {
          setFormData({
            ...formData,
            audio_file_camplaint: audioBlob,
            description_of_camplaint: "Audio description is Provided",
          });
        } else {
          setFormData({ ...formData, audio_file_camplaint: audioBlob });
        }
        chunks.current = [];
        recorder.current?.stream?.getAudioTracks().forEach((track) => {
          track.stop();
        });
      });
      recorder.current.start();
      setRecording(true);
      JSTimers = setTimeout(() => {
        if (recorder.current.state === "recording") {
          recorder.current.stop();
          setRecording(false);
        }
      }, maxRecordingTime);
    });
  };
  const handleStop = () => {
    clearTimeout(JSTimers);
    recorder.current.stop();
    setRecording(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const user_id = mffUser.user_id;
    const token = mffUser.token;
    axios
      .postForm("https://backoffice.myambar.org/api/complaint-form-submit", {
        ...formData,
        user_id,
        token,
        state_id: formData.state,
        district_id: formData.district,
      })
      .then((response) => {
        if (response.data.status_code === 440) {
          navigate("/login");
          window.sessionStorage.removeItem("mffUser");
        } else if (response.data?.result === false) {
          setSubmitpass(false);
          setSubmitMsg(
            response.data?.message ||
              (lang === "en" ? "Submit Failed" : "सबमिट विफल")
          );
        } else {
          setSubmitpass(true);
          setSubmitMsg(response.data?.message);
        }
      })
      .catch((error) => {
        setSubmitpass(false);
        setSubmitMsg(
          lang === "en" ? "Unable to Submit" : "सबमिट करने में असमर्थ"
        );
      });
    setShowSubmitModal({
      state: false,
      triggeredFrom: "",
    });
    if (showSubmitModal.state === "last")
      document.getElementById("hidden-submit-btn").click();
    else {
      const fieldsets = document.querySelectorAll("fieldset");
      const progressBar = document.querySelector(".progress-bar");
      setSteps(fieldsets.length);
      setProgressBar(current);

      function setProgressBar(curStep) {
        const percent = ((100 / steps) * curStep).toFixed();
        progressBar.style.width = percent + "%";
      }

      function hideFieldsetWithAnimation(currentFs, nextFs) {
        currentFs.style.opacity = 0;
        currentFs.style.visibility = "hidden";
        currentFs.style.position = "relative";
        currentFs.style.transition = "opacity 0.5s ease-in-out";
        currentFs.style.display = "none";
        nextFs.style.opacity = 1;
        nextFs.style.visibility = "visible";
        nextFs.style.position = "relative";
        nextFs.style.transition = "opacity 0.5s ease-in-out";
      }

      const currentFs = document.querySelectorAll("fieldset")[current - 1];
      const nextFs =
        document.querySelectorAll("fieldset")[fieldsets.length - 1];
      if (current === 1) {
        if (!allowMoveForward("step1") || !checkvalidations("step1")) return;
      } else if (current === 2) {
        if (!allowMoveForward("step2") || !checkvalidations("step2")) return;
      } else if (current === 3) {
        if (!allowMoveForward("step3") || !checkvalidations("step3")) return;
      }
      nextFs.style.display = "block";
      hideFieldsetWithAnimation(currentFs, nextFs);
      setCurrent(fieldsets.length + 1);
    }
  };

  const SubmitModal = () => {
    return (
      <>
        <div
          className={`modal ${showSubmitModal.state ? "show" : ""} fade`}
          tabIndex="-1"
          style={{ display: showSubmitModal.state ? "block" : "none" }}
          id="submitModal"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {lang === "en" ? "Submit Form" : "फार्म जमा करें"}
                </h5>
                <button
                  className="close"
                  type="button"
                  onClick={() =>
                    setShowSubmitModal({
                      state: false,
                      triggeredFrom: "",
                    })
                  }
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ marginLeft: "1rem" }}>
                  {lang === "en"
                    ? "Make sure you are fully prepared to submit your complaint by providing all the necessary details."
                    : "सभी आवश्यक विवरण प्रदान करके सुनिश्चित करें कि आप अपनी शिकायत सबमिट करने के लिए पूरी तरह से तैयार हैं।"}
                </div>
              </div>
              <div className="modal-footer">
                <div
                  style={{
                    marginLeft: "1rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsTnCAgree(!isTnCAgree);
                    }}
                    style={{ marginRight: "1em", boxShadow: "0px 0px 2px" }}
                    checked={isTnCAgree}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={""}
                    style={{ marginTop: "2px" }}
                  >
                    {lang === "en"
                      ? "By clicking on this, you acknowledge and agree to share all the necessary details of your complaint on this platform."
                      : "इस पर क्लिक करके, आप इस प्लेटफ़ॉर्म पर अपनी शिकायत के सभी आवश्यक विवरणों को साझा करने के लिए स्वीकार करते हैं और सहमत होते हैं।"}
                  </label>
                </div>
                <button
                  className="btn btn-secondary"
                  type="button"
                  id="TableModalClose"
                  onClick={() =>
                    setShowSubmitModal({
                      state: false,
                      triggeredFrom: "",
                    })
                  }
                >
                  {lang === "en" ? "Cancel" : "रद्द करें"}
                </button>
                <button
                  type="submit"
                  name="next"
                  onClick={handleFormSubmit}
                  className="btn btn-primary action-button"
                  disabled={!isTnCAgree}
                >
                  {lang === "en" ? "Submit" : "सबमिट करे"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal-backdrop ${
            showSubmitModal.state ? "show" : ""
          } fade`}
          style={{ display: showSubmitModal.state ? "block" : "none" }}
        ></div>
      </>
    );
  };
  const handleStateChange = (event) => {
    let chosenState = event.target.value;
    setPincode({ ...pincode, state: chosenState });
    setDistricts(stateDistricts[chosenState]);
    setDistrictsHi(
      stateDistricts_hi[
        Object.keys(stateDistricts_hi)[
          Object.keys(stateDistricts).indexOf(chosenState)
        ]
      ]
    );
  };

  const handleDistrictChange = (event) => {
    let chosenDistrict = event.target.value;
    setPincode({ ...pincode, district: chosenDistrict });
    // setDistricts(stateDistricts[chosenState]);
  };
  const handlePincode = (event) => {
    let currCode = event.target.value;

    if (currCode === "") {
      setPincode((prevData) => ({
        ...prevData,
        state: "",
        district: "",
        error: lang === "en" ? "Required!" : "आवश्यक!",
      }));
    }
    setPincode((prevData) => ({
      ...prevData,
      code: currCode,
    }));

    if (currCode.length === 6) {
      setPincode((prevData) => ({
        ...prevData,
        error: "",
      }));

      axios
        .get(`https://api.postalpincode.in/pincode/${currCode}`)
        .then((res) => {
          setPincode((prevData) => ({
            ...prevData,
            state: res.data[0].PostOffice
              ? res.data[0].PostOffice[0].State
              : "",
            district: res.data[0].PostOffice
              ? res.data[0].PostOffice[0].District
              : "",
          }));
          if (res.data[0].PostOffice == null) {
            setPincode((prevData) => ({
              ...prevData,
              state: "",
              district: "",
              error:
                lang === "en"
                  ? "Please enter a valid PINCODE"
                  : "कृपया एक मान्य पिनकोड दर्ज करें",
            }));
          }
        })
        .catch((err) => {
          setPincode((prevData) => ({
            ...prevData,
            state: "",
            district: "",
            error:
              lang === "en"
                ? "Please enter a valid PINCODE"
                : "कृपया एक मान्य पिनकोड दर्ज करें",
          }));
        });
    } else if (currCode.length === 0) {
      setPincode((prevData) => ({
        ...prevData,
        error: "",
      }));
    } else {
      setPincode((prevData) => ({
        ...prevData,
        state: "",
        district: "",
        error:
          lang === "en"
            ? "PINCODE must be of 6 digits"
            : "पिनकोड 6 अंकों का होना चाहिए",
      }));
    }
  };

  const [current, setCurrent] = useState(1);
  const [steps, setSteps] = useState(0);
  const [step1err, setStep1err] = useState([]);
  const [step2err, setStep2err] = useState([]);
  const [step3err, setStep3err] = useState([]);

  const allowMoveForward = (step) => {
    if (step == "step1") {
      if (step1Allvalid) {
        setStep1err([]);
        return true;
      } else {
        let k1 = lang === "en" ? "age is required" : "आयु आवश्यक फ़ील्ड है";
        let k2 =
          lang === "en"
            ? "either enter pincode or select district"
            : "या तो पिनकोड दर्ज करें या जिला चुनें";
        let temp = [];
        if (!error["age"].isValid || !formData.age.length) {
          temp.push(k1);
        }
        if (!error["pincode"].isValid && !error["district"].isValid) {
          temp.push(k2);
        }
        setStep1err(temp);
        return false;
      }
    } else if (step == "step2") {
      if (step2Allvalid) {
        setStep2err([]);
        return true;
      } else {
        let k1 =
          lang === "en"
            ? "Either enter description of complaint or record audio"
            : "या तो शिकायत का विवरण दर्ज करें या ऑडियो रिकॉर्ड करें";
        let temp = [];
        if (
          !error["description_of_camplaint"].isValid ||
          !formData.description_of_camplaint.length
        ) {
          temp.push(k1);
        }
        setStep2err(temp);
        return false;
      }
    } else if (step == "step3") {
      if (step3Allvalid) {
        setStep3err([]);
        return true;
      } else {
        let k1 =
          lang === "en"
            ? "Respondent Name is Required"
            : "उत्तरदाता का नाम आवश्यक है";
        let k2 =
          lang === "en"
            ? "Either Respondent Phone or Address is Required"
            : "या तो उत्तरदाता का फोन या पता आवश्यक है";
        let temp = [];
        if (
          !error["respondent_full_name"].isValid ||
          !formData.respondent_full_name.length
        ) {
          temp.push(k1);
        }
        if (
          (!error["respondent_address"].isValid ||
            !formData.respondent_address.length) &&
          (!error["respondent_mobile"].isValid || !formData.respondent_mobile)
        ) {
          temp.push(k2);
        }
        setStep3err(temp);
        return false;
      }
    }
  };

  useEffect(() => {
    const fieldsets = document.querySelectorAll("fieldset");
    const progressBar = document.querySelector(".progress-bar");
    const progressItems = document.querySelectorAll("#progressbar li");
    setSteps(fieldsets.length);
    setProgressBar(current);

    function setProgressBar(curStep) {
      const percent = ((100 / steps) * curStep).toFixed();
      progressBar.style.width = percent + "%";
      progressItems[curStep - 1]?.classList?.add("active");
    }

    function hideFieldsetWithAnimation(currentFs, nextFs) {
      currentFs.style.opacity = 0;
      currentFs.style.visibility = "hidden";
      currentFs.style.position = "relative";
      currentFs.style.transition = "opacity 0.5s ease-in-out";
      currentFs.style.display = "none";
      nextFs.style.opacity = 1;
      nextFs.style.visibility = "visible";
      nextFs.style.position = "relative";
      nextFs.style.transition = "opacity 0.5s ease-in-out";
    }

    function showNextFieldset() {
      const currentFs = document.querySelectorAll("fieldset")[current - 1];
      const nextFs = document.querySelectorAll("fieldset")[current];
      if (current === 1) {
        if (!allowMoveForward("step1") || !checkvalidations("step1")) return;
      } else if (current === 2) {
        if (!allowMoveForward("step2") || !checkvalidations("step2")) return;
      } else if (current === 3) {
        if (!allowMoveForward("step3") || !checkvalidations("step3")) return;
      }
      progressItems[current]?.classList?.add("active");
      nextFs.style.display = "block";
      hideFieldsetWithAnimation(currentFs, nextFs);
      setCurrent(current + 1);
    }

    function showPreviousFieldset() {
      const currentFs = document.querySelectorAll("fieldset")[current - 1];
      const previousFs = document.querySelectorAll("fieldset")[current - 2];

      progressItems[current - 1].classList.remove("active");
      previousFs.style.display = "block";
      hideFieldsetWithAnimation(currentFs, previousFs);
      setCurrent(current - 1);
    }

    const nextButton =
      document.querySelectorAll(".next")[current - 1] || undefined;
    const previousButton =
      document.querySelectorAll(".previous")[current - 2] || undefined;
    if (nextButton) {
      nextButton.addEventListener("click", showNextFieldset);
    }

    if (previousButton) {
      previousButton.addEventListener("click", showPreviousFieldset);
    }
    return () => {
      if (nextButton) {
        nextButton.removeEventListener("click", showNextFieldset);
      }
      if (previousButton) {
        previousButton.removeEventListener("click", showPreviousFieldset);
      }
    };
  }, [current, steps, step1Allvalid, error, step2Allvalid, step3Allvalid]);

  return (
    <>
      <SubmitModal />
      <div className="complaint-form">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-7  text-center p-0 mt-3 mb-2">
              <div
                href="#"
                className="float"
                onClick={() => navigate("/user-menu")}
              >
                <i className="fa-solid fa-house-user my-float fa-2x"></i>
                {lang === "en" ? "Return Home" : "होमपेज पर वापस जाएँ "}
              </div>
              <div className="card px-0 pt-4 pb-0 mt-3 mb-3">
                <h2 id="heading">
                  {lang === "en"
                    ? "File your complaint"
                    : "अपनी शिकायत दर्ज करें"}
                </h2>
                <p>
                  {lang === "en"
                    ? "Fill all required form field to go to next step"
                    : "अगले चरण पर जाने के लिए सभी आवश्यक प्रपत्र फ़ील्ड भरें"}
                </p>
                <div id="msform">
                  {/* progressbar */}
                  <ul id="progressbar">
                    <li className="active" id="personal">
                      <strong>{lang === "en" ? "Personal" : "निजी"}</strong>
                    </li>
                    <li id="account">
                      <strong>{lang === "en" ? "Complaint" : "शिकायत"}</strong>
                    </li>
                    <li id="payment">
                      <strong>
                        {lang === "en" ? "Respondent" : "प्रतिवादी"}
                      </strong>
                    </li>
                    <li id="confirm">
                      <strong>{lang === "en" ? "Evidence" : "प्रमाण"}</strong>
                    </li>
                    <li id="witness">
                      <strong>{lang === "en" ? "Witness" : "गवाह"}</strong>
                    </li>
                  </ul>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>{" "}
                  <br /> {/* fieldsets */}
                  <fieldset>
                    <div className="form-card">
                      <div className="row">
                        <div className="col-7">
                          <h2 className="fs-title">
                            {lang === "en"
                              ? "Personal Details:"
                              : "व्यक्तिगत विवरण:"}
                          </h2>
                        </div>
                        <div className="col-5">
                          <h2 className="steps">
                            {lang === "en" ? "Step 1 - 5" : "चरण 1 - 5"}
                          </h2>
                        </div>
                      </div>{" "}
                      <div className="form-grid">
                        <div className="form-inline">
                          <label className="fieldlabels" htmlFor="full_name">
                            {lang === "en"
                              ? "Complainant's Full Name: *"
                              : "शिकायतकर्ता का पूरा नाम: *"}
                          </label>{" "}
                          <input
                            type="text"
                            name="full_name"
                            id="full_name"
                            placeholder={
                              lang === "en" ? "Enter name" : "नाम दर्ज करें"
                            }
                            value={formData.full_name}
                            onChange={handleFormChange}
                            required
                            disabled
                          />
                          {error.full_name.isValid === false && (
                            <div
                              className="invalid-feedback"
                              style={{
                                display: "block",
                                marginTop: "-1.5rem",
                                marginBottom: "0.2rem",
                              }}
                            >
                              {error.full_name.inValid}
                            </div>
                          )}
                        </div>
                        <div className="form-inline">
                          <label className="fieldlabels" htmlFor="phone_number">
                            {lang === "en" ? "Phone Number: *" : "फ़ोन नंबर: *"}
                          </label>{" "}
                          <input
                            type="tel"
                            name="phone_number"
                            placeholder={
                              lang === "en"
                                ? "Enter phone number"
                                : "फ़ोन नंबर दर्ज करें"
                            }
                            id="phone_number"
                            value={formData.phone_number}
                            onChange={handleFormChange}
                            required
                            disabled
                          />
                          {error.phone_number.isValid === false && (
                            <div
                              className="invalid-feedback"
                              style={{
                                display: "block",
                                marginTop: "-1.5rem",
                                marginBottom: "0.2rem",
                              }}
                            >
                              {error.phone_number.inValid}
                            </div>
                          )}
                        </div>

                        <div className="form-inline">
                          <label className="fieldlabels" htmlFor="age">
                            {lang === "en" ? "Age: *" : "आयु: *"}
                          </label>{" "}
                          <input
                            type="text"
                            name="age"
                            id="age"
                            value={formData.age}
                            placeholder={
                              lang === "en" ? "Enter Age" : "उम्र दर्ज करें"
                            }
                            onChange={handleFormChange}
                            required
                          />
                          {error.age.isValid === false && (
                            <div
                              className="invalid-feedback"
                              style={{
                                display: "block",
                                marginTop: "-1.5rem",
                                marginBottom: "0.2rem",
                              }}
                            >
                              {error.age.inValid}
                            </div>
                          )}
                        </div>
                        <div className="form-inline">
                          <label className="fieldlabels" htmlFor="occupation">
                            {lang === "en" ? "Occupation:" : "व्यवसाय:"}
                          </label>{" "}
                          <input
                            type="text"
                            name="occupation"
                            id="occupation"
                            value={formData.occupation}
                            onChange={handleFormChange}
                            placeholder={
                              lang === "en"
                                ? "Enter Occupation"
                                : "व्यवसाय दर्ज करें"
                            }
                          />
                          {error.occupation.isValid === false && (
                            <div
                              className="invalid-feedback"
                              style={{
                                display: "block",
                                marginTop: "-1.5rem",
                                marginBottom: "0.2rem",
                              }}
                            >
                              {error.occupation.inValid}
                            </div>
                          )}
                        </div>
                        <div className="form-inline">
                          <label className="fieldlabels">
                            {lang === "en" ? "Pincode:" : "पिन कोड:"}
                          </label>{" "}
                          <input
                            maxLength={6}
                            minLength={6}
                            name="pincode"
                            placeholder={
                              lang === "en"
                                ? "Enter Pincode"
                                : "पिन कोड दर्ज करें"
                            }
                            onChange={(e) => handlePincode(e)}
                            id="pincode"
                            className="pin"
                            value={pincode.code}
                            required
                          />
                          {pincode.error ? (
                            <div
                              className="invalid-feedback"
                              style={{
                                display: "block",
                                marginTop: "-1.5rem",
                                marginBottom: "0.2rem",
                              }}
                            >
                              {pincode.error}
                            </div>
                          ) : null}
                          <label className="fieldlabels" htmlFor="inputState">
                            {lang === "en" ? "State:" : "राज्य:"}{" "}
                          </label>
                          {pincode.code.length > 0 &&
                          pincode.error.length === 0 ? (
                            <input
                              type="String"
                              placeholder=" "
                              readOnly
                              value={pincode.state}
                              className="inp"
                              required
                            />
                          ) : (
                            <select
                              className="form-control"
                              id="inputState"
                              required
                              value={pincode.state}
                              onChange={handleStateChange}
                            >
                              <option value="">
                                {lang === "en" ? "Select State" : "राज्य चुनें"}
                              </option>

                              {states.map((state, index) => (
                                <option value={state} key={state}>
                                  {lang === "en"
                                    ? state
                                    : Object.keys(stateDistricts_hi)[index]}
                                </option>
                              ))}
                            </select>
                          )}
                          {/* <select
                        className="form-control"
                        id="inputState"
                        required=""
                        value={pincode.code}
                        onChange={handleChange}
                      >
                        <option value="">Select State</option>

                        {states.map((state) => (
                          <option value={state} key={state}>
                            {state}
                          </option>
                        ))}
                        {/* <option
                          disabled=""
                          style={{ backgroundColor: '#aaa', color: '#fff' }}
                        >
                          UNION Territories
                        </option> */}
                          {/* </select> */}
                        </div>
                        <div className="form-inline">
                          <label
                            className="fieldlabels"
                            htmlFor="inputDistrict"
                          >
                            {lang === "en" ? "District: *" : "ज़िला: *"}{" "}
                          </label>
                          {pincode.code.length > 0 &&
                          pincode.error.length === 0 ? (
                            <input
                              type="String"
                              placeholder=" "
                              value={pincode.district}
                              onChange={handleDistrictChange}
                              className="inp"
                              required
                            />
                          ) : (
                            <select
                              className="form-control"
                              id="inputDistrict"
                              value={pincode.district}
                              onChange={handleDistrictChange}
                            >
                              <option value="">
                                {lang === "en"
                                  ? "Select District"
                                  : "ज़िला चुनें"}
                              </option>
                              {districts.map((district, index) => (
                                <option value={district} key={district}>
                                  {lang === "en"
                                    ? district
                                    : districtsHi[index]}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                      </div>
                    </div>{" "}
                    <ul>
                      {step1err.length
                        ? step1err.map((err, index) => (
                            <li
                              key={index}
                              className="invalid-feedback"
                              style={{
                                display: "list-item",
                                textAlign: "left",
                                listStyle: "square",
                                marginLeft: "3em",
                              }}
                            >
                              {err}
                            </li>
                          ))
                        : ""}
                    </ul>
                    <input
                      type="button"
                      name="next"
                      className="btn next action-button"
                      defaultValue={lang === "en" ? "Next" : "अगला"}
                      // disabled={step1Allvalid ? false : true}
                      // onClick={() => handleNextButtonClick('step1')}
                    />
                  </fieldset>
                  <fieldset>
                    <div className="form-card">
                      <div className="row">
                        <div className="col-7">
                          <h2 className="fs-title">
                            {lang === "en"
                              ? "Complaint Details"
                              : "शिकायत विवरण"}
                          </h2>
                        </div>
                        <div className="col-5">
                          <h2 className="steps">
                            {lang === "en" ? "Step 2 - 5" : "चरण 2 - 5"}
                          </h2>
                        </div>
                      </div>
                      <label
                        className="fieldlabels"
                        htmlFor="description_of_camplaint"
                      >
                        {lang === "en"
                          ? "Description of Complaint: *"
                          : "शिकायत का विवरण: *"}
                      </label>
                      <textarea
                        id="description_of_camplaint"
                        name="description_of_camplaint"
                        value={formData.description_of_camplaint}
                        onChange={handleFormChange}
                        placeholder=""
                        required
                      />
                      {error.description_of_camplaint.isValid === false && (
                        <div
                          className="invalid-feedback"
                          style={{
                            display: "block",
                            marginTop: "-1.5rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          {error.description_of_camplaint.inValid}
                        </div>
                      )}
                      <span className="microphone-input">
                        {!recording ? (
                          <div
                            style={{
                              width: "fit-content",
                              textAlign: "center",
                            }}
                          >
                            <i
                              className="fa-solid fa-microphone"
                              style={{
                                marginTop: 33,
                                fontSize: 48,
                                color: "#009688",
                                cursor: "pointer",
                              }}
                              onClick={handleStart}
                            />
                            <p style={{ marginTop: "5px" }}>
                              {lang === "en"
                                ? "Start Recording"
                                : "रिकॉर्डिंग शुरू करें"}
                            </p>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "fit-content",
                              textAlign: "center",
                            }}
                          >
                            <i
                              className="fa-solid fa-microphone-slash"
                              style={{
                                marginTop: 33,
                                fontSize: 48,
                                color: "#009688",
                                cursor: "pointer",
                              }}
                              onClick={handleStop}
                            />
                            <p style={{ marginTop: "5px" }}>
                              {lang === "en"
                                ? "Stop Recording"
                                : "रिकॉर्डिंग बंद करो"}
                            </p>
                            <Timer duration={120} count={120} />
                          </div>
                        )}
                        {audioURL && (
                          <div>
                            <audio controls src={audioURL} />
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                marginTop: "5px",
                              }}
                            >
                              <p>
                                {lang === "en" ? "Duration" : "अवधि"}:{" "}
                                {audioDuration}
                              </p>
                              <a
                                href={audioURL}
                                download
                                style={{ color: "blue" }}
                              >
                                {lang === "en"
                                  ? "Download Audio"
                                  : "ऑडियो डाउनलोड करें"}
                              </a>
                            </span>
                          </div>
                        )}
                      </span>
                    </div>{" "}
                    <ul>
                      {step2err.length
                        ? step2err.map((err, index) => (
                            <li
                              key={index}
                              className="invalid-feedback"
                              style={{
                                display: "list-item",
                                textAlign: "left",
                                listStyle: "square",
                                marginLeft: "3em",
                              }}
                            >
                              {err}
                            </li>
                          ))
                        : ""}
                    </ul>
                    <p
                      className=""
                      style={{ fontStyle: "oblique", fontWeight: "bolder" }}
                    >
                      {lang === "en"
                        ? "You can submit your complaint here, to provide additional information go to the next page"
                        : "आप अपनी शिकायत यहां दर्ज कर सकते हैं, अतिरिक्त जानकारी प्रदान करने के लिए अगले पृष्ठ पर जाएं"}
                    </p>
                    <input
                      type="button"
                      name="next"
                      className="btn next action-button"
                      defaultValue={lang === "en" ? "Next" : "अगला"}
                      // disabled={step2Allvalid ? false : true}
                      // onClick={() => handleNextButtonClick('step2')}
                    />{" "}
                    <input
                      type="button"
                      name="previous"
                      className="previous action-button-previous"
                      defaultValue={lang === "en" ? "Previous" : "पिछला"}
                    />
                    <input
                      type="button"
                      name="next"
                      className="btn action-button"
                      defaultValue={lang === "en" ? "Submit" : "सबमिट"}
                      // onClick={handleFormSubmit}
                      style={{
                        background: step2Allvalid ? "" : "#616161",
                        cursor: step2Allvalid ? "pointer" : "default",
                      }}
                      onClick={() =>
                        setShowSubmitModal({
                          state: true,
                          triggeredFrom: "step2",
                        })
                      }
                      disabled={step2Allvalid ? false : true}
                    />
                  </fieldset>
                  <fieldset>
                    <div className="form-card">
                      <div className="row">
                        <div className="col-7">
                          <h2 className="fs-title">
                            {lang === "en"
                              ? "Respondent Details"
                              : "प्रतिवादी विवरण:"}
                          </h2>{" "}
                        </div>
                        <div className="col-5">
                          <h2 className="steps">
                            {lang === "en" ? "Step 3 - 5" : "चरण 3 - 5"}
                          </h2>
                        </div>
                      </div>
                      <label
                        className="fieldlabels"
                        htmlFor="respondent_full_name"
                      >
                        {lang === "en"
                          ? "Respondent Name: *"
                          : "प्रतिवादी का नाम: *"}
                      </label>{" "}
                      <input
                        type="text"
                        name="respondent_full_name"
                        id="respondent_full_name"
                        value={formData.respondent_full_name}
                        onChange={handleFormChange}
                        placeholder={
                          lang === "en"
                            ? "Enter Respondent Name"
                            : "प्रतिवादी का नाम दर्ज करें"
                        }
                        required
                      />
                      {error.respondent_full_name.isValid === false && (
                        <div
                          className="invalid-feedback"
                          style={{
                            display: "block",
                            marginTop: "-1.5rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          {error.respondent_full_name.inValid}
                        </div>
                      )}
                      <label
                        className="fieldlabels"
                        htmlFor="respondent_mobile"
                      >
                        {lang === "en"
                          ? "Respondent contact number: *"
                          : "प्रतिवादी संपर्क नंबर: *"}
                      </label>{" "}
                      <input
                        type="text"
                        name="respondent_mobile"
                        id="respondent_mobile"
                        value={formData.respondent_mobile}
                        onChange={handleFormChange}
                        placeholder={
                          lang === "en"
                            ? "Enter Respondent contact number"
                            : "प्रतिवादी का संपर्क नंबर दर्ज करें"
                        }
                      />
                      {error.respondent_mobile.isValid === false && (
                        <div
                          className="invalid-feedback"
                          style={{
                            display: "block",
                            marginTop: "-1.5rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          {error.respondent_mobile.inValid}
                        </div>
                      )}
                      <label className="fieldlabels" htmlFor="respondent_email">
                        {lang === "en"
                          ? "Enter Respondent Email:"
                          : "प्रतिवादी का ईमेल दर्ज करें?"}
                      </label>{" "}
                      <input
                        type="text"
                        name="respondent_email"
                        id="respondent_email"
                        value={formData.respondent_email}
                        onChange={handleFormChange}
                        placeholder={
                          lang === "en" ? "Enter Email" : "ईमेल दर्ज करें"
                        }
                      />
                      {error.respondent_email.isValid === false && (
                        <div
                          className="invalid-feedback"
                          style={{
                            display: "block",
                            marginTop: "-1.5rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          {error.respondent_email.inValid}
                        </div>
                      )}
                      <label
                        className="fieldlabels"
                        htmlFor="respondent_relation"
                      >
                        {lang === "en"
                          ? "How do you know the respondent?:"
                          : "आप प्रतिवादी को कैसे जानते हैं?"}
                      </label>{" "}
                      <input
                        type="text"
                        name="respondent_relation"
                        id="respondent_relation"
                        value={formData.respondent_relation}
                        onChange={handleFormChange}
                        placeholder={
                          lang === "en" ? "Enter Relation" : "संबंध दर्ज करें"
                        }
                      />
                      {error.respondent_relation.isValid === false && (
                        <div
                          className="invalid-feedback"
                          style={{
                            display: "block",
                            marginTop: "-1.5rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          {error.respondent_relation.inValid}
                        </div>
                      )}
                      <label
                        className="fieldlabels"
                        htmlFor="respondent_physical_appearance"
                      >
                        {lang === "en"
                          ? "What is physical appearance of respondent?:"
                          : "प्रतिवादी की शारीरिक बनावट क्या है??"}
                      </label>{" "}
                      <input
                        type="text"
                        name="respondent_physical_appearance"
                        id="respondent_physical_appearance"
                        value={formData.respondent_physical_appearance}
                        onChange={handleFormChange}
                        placeholder={
                          lang === "en"
                            ? "Enter Physical Appearance"
                            : "शारीरिक उपस्थिति दर्ज करें"
                        }
                      />
                      {error.respondent_physical_appearance.isValid ===
                        false && (
                        <div
                          className="invalid-feedback"
                          style={{
                            display: "block",
                            marginTop: "-1.5rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          {error.respondent_physical_appearance.inValid}
                        </div>
                      )}
                      <label
                        className="fieldlabels"
                        htmlFor="respondent_address"
                      >
                        {lang === "en"
                          ? "Respondent Address:"
                          : "प्रतिवादी का पता:"}
                      </label>{" "}
                      <input
                        type="text"
                        name="respondent_address"
                        id="respondent_address"
                        value={formData.respondent_address}
                        onChange={handleFormChange}
                        placeholder={
                          lang === "en" ? "Enter Address" : "पता दर्ज करें"
                        }
                      />
                      {lang === "en"
                        ? "Note: Either Respondent Phone or Address is Required"
                        : "नोट: या तो प्रतिवादी का फ़ोन या पता आवश्यक है"}
                      {error.respondent_address.isValid === false && (
                        <div
                          className="invalid-feedback"
                          style={{
                            display: "block",
                            marginTop: "-1.5rem",
                            marginBottom: "0.2rem",
                          }}
                        >
                          {error.respondent_address.inValid}
                        </div>
                      )}
                    </div>{" "}
                    <ul>
                      {step3err.length
                        ? step3err.map((err, index) => (
                            <li
                              key={index}
                              className="invalid-feedback"
                              style={{
                                display: "list-item",
                                textAlign: "left",
                                listStyle: "square",
                                marginLeft: "3em",
                              }}
                            >
                              {err}
                            </li>
                          ))
                        : ""}
                    </ul>
                    <input
                      type="button"
                      name="next"
                      className="btn next action-button"
                      defaultValue={lang === "en" ? "Next" : "अगला"}
                    />{" "}
                    <input
                      type="button"
                      name="previous"
                      className="previous action-button-previous"
                      defaultValue={lang === "en" ? "Previous" : "पिछला"}
                    />
                  </fieldset>
                  <fieldset>
                    {haveEvidence ? (
                      <div className="form-card">
                        <div className="row">
                          <div className="col-7">
                            <h2 className="fs-title">
                              {lang === "en"
                                ? "Evidence Details:"
                                : "साक्ष्य विवरण"}
                            </h2>
                          </div>
                          <div className="col-5">
                            <h2 className="steps">
                              {lang === "en" ? "Step 4 - 5" : "चरण 4 - 5"}
                            </h2>
                          </div>
                        </div>
                        <div style={{ overflow: "auto" }}>
                          <table
                            className="table"
                            style={{ color: "#fff !important" }}
                          >
                            <thead style={{ backgroundColor: "#009688" }}>
                              {lang === "en" ? (
                                <tr>
                                  <td>Sr. No.</td>
                                  <td>Evidence Type</td>
                                  <td>Evidence Description</td>
                                  <td>Attachment</td>
                                  <td>Action</td>
                                </tr>
                              ) : (
                                <tr>
                                  <td>क्रमांक</td>
                                  <td>साक्ष्य प्रकार</td>
                                  <td>साक्ष्य विवरण</td>
                                  <td>अटैचमेंट</td>
                                  <td>कार्य</td>
                                </tr>
                              )}
                            </thead>
                            <tbody id="evidence_table">
                              <List
                                list={list}
                                deleteContent={deleteContent}
                                setUpdateData={setUpdateData}
                                lang={lang}
                              />
                            </tbody>
                            {list && list.length ? null : (
                              <tbody id="evidence_table">
                                <tr>
                                  <td colSpan={5}>
                                    <h6
                                      style={{
                                        textAlign: "center",
                                        color: "black",
                                      }}
                                    >
                                      {lang === "en"
                                        ? "No Evidence"
                                        : "कोई सबूत नहीं"}
                                    </h6>
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                          <input
                            type="button"
                            name="haveEvidence"
                            className="btn action-button"
                            defaultValue={lang === "en" ? "Hide" : "हाइड"}
                            onClick={() => {
                              setHaveEvidence(false);
                            }}
                            style={{ float: "right" }}
                          />
                        </div>

                        {/* form starts */}
                        {updateData && updateData ? (
                          <UpdateEvidenceForm
                            updateContent={updateContent}
                            setUpdateData={setUpdateData}
                            updateData={updateData}
                            error={error}
                            setError={setError}
                            lang={lang}
                          />
                        ) : (
                          <EvidenceForm
                            evidenceType={evidenceType}
                            evidenceDescription={evidenceDescription}
                            setEvidenceDescription={setEvidenceDescription}
                            addContent={addContent}
                            setEvidenceType={setEvidenceType}
                            evidenceFile={evidenceFile}
                            setEvidenceFile={setEvidenceFile}
                            error={error}
                            setError={setError}
                            lang={lang}
                          />
                        )}
                      </div>
                    ) : (
                      <div className="form-card">
                        <div
                          className="row"
                          style={{ justifyContent: "flex-start" }}
                        >
                          <div className="col-7">
                            <h2 className="fs-title">
                              {lang === "en"
                                ? "Do you have Evidence(es)?"
                                : "क्या आपके पास सबूत है?"}
                            </h2>
                            <input
                              type="button"
                              name="haveEvidence"
                              className="btn action-button"
                              defaultValue={lang === "en" ? "Yes" : "हाँ"}
                              onClick={() => {
                                setHaveEvidence(true);
                              }}
                              style={{ float: "left" }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <input
                      type="button"
                      name="next"
                      className="btn next action-button"
                      defaultValue={
                        lang === "en"
                          ? haveEvidence
                            ? "Next"
                            : "No"
                          : haveEvidence
                          ? "अगला"
                          : "नहीं"
                      }
                      disabled={step4Allvalid ? false : true}
                    />{" "}
                    <input
                      type="button"
                      name="previous"
                      className="previous action-button-previous"
                      defaultValue={lang === "en" ? "Previous" : "पिछला"}
                    />
                  </fieldset>
                  <fieldset>
                    {haveWitness ? (
                      <div className="form-card">
                        <div className="row">
                          <div className="col-7">
                            <h2 className="fs-title">
                              {lang === "en"
                                ? "Witness Details:"
                                : "गवाह विवरण:"}
                            </h2>
                          </div>
                          <div className="col-5">
                            <h2 className="steps">
                              {lang === "en" ? "Step 5 - 5" : "चरण 5 - 5"}
                            </h2>
                          </div>
                        </div>
                        <div style={{ overflow: "auto" }}>
                          <table
                            className="table"
                            style={{ color: "#fff !important" }}
                          >
                            <thead style={{ backgroundColor: "#009688" }}>
                              {lang === "en" ? (
                                <tr>
                                  <td>Sr. No.</td>
                                  <td>Witness Name</td>
                                  <td>Witness contact number</td>
                                  <td>Action</td>
                                </tr>
                              ) : (
                                <tr>
                                  <td>क्रमांक</td>
                                  <td>साक्षी का नाम</td>
                                  <td>साक्षी संपर्क नंबर</td>
                                  <td>कार्य</td>
                                </tr>
                              )}
                            </thead>
                            <tbody id="evidence_table">
                              <WList
                                wlist={wlist}
                                wdeleteContent={wdeleteContent}
                                setWUpdateData={setWUpdateData}
                                lang={lang}
                              />
                            </tbody>
                            {wlist && wlist.length ? null : (
                              <tbody id="evidence_table">
                                <tr>
                                  <td colSpan={5}>
                                    <h6
                                      style={{
                                        textAlign: "center",
                                        color: "black",
                                      }}
                                    >
                                      {lang === "en"
                                        ? "No Witness"
                                        : "कोई गवाह नहीं"}
                                    </h6>
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                          <input
                            type="button"
                            name="haveWitness"
                            className="btn action-button"
                            defaultValue={lang === "en" ? "Hide" : "हाइड"}
                            onClick={() => {
                              setHaveWitness(false);
                            }}
                            style={{ float: "right" }}
                          />
                        </div>
                        {wupdateData && wupdateData ? (
                          <WupdateForm
                            wupdateContent={wupdateContent}
                            setWUpdateData={setWUpdateData}
                            wupdateData={wupdateData}
                            error={error}
                            setError={setError}
                            lang={lang}
                          />
                        ) : (
                          <Wform
                            wname={wname}
                            wnum={wnum}
                            setWname={setWname}
                            setWnum={setWnum}
                            waddContent={waddContent}
                            setWUpdateData={setWUpdateData}
                            error={error}
                            setError={setError}
                            lang={lang}
                          />
                        )}
                        {/* <button
                        className="btn btn-sm btn-info"
                        type="button"
                        style={{ padding: 8 }}
                        id="add-more-evidence"
                        data-toggle="modal"
                        data-target="#addWitnessModal"
                      >
                        Add More Witness
                      </button> */}
                      </div>
                    ) : (
                      <div className="form-card">
                        <div
                          className="row"
                          style={{ justifyContent: "flex-start" }}
                        >
                          <div className="col-7">
                            <h2 className="fs-title">
                              {lang === "en"
                                ? "Do you have Witness(es)?"
                                : "क्या आपके पास गवाह है?"}
                            </h2>
                            <input
                              type="button"
                              name="haveWitness"
                              className="btn action-button"
                              defaultValue={lang === "en" ? "Yes" : "हाँ"}
                              onClick={() => {
                                setHaveWitness(true);
                              }}
                              style={{ float: "left" }}
                            />
                            <input
                              type="button"
                              name="haveWitness"
                              className="btn action-button"
                              defaultValue={lang === "en" ? "No" : "नहीं"}
                              onClick={() =>
                                setShowSubmitModal({
                                  state: true,
                                  triggeredFrom: "last",
                                })
                              }
                              style={{ float: "left" }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <input
                      type="button"
                      name="next"
                      className="btn action-button"
                      defaultValue={lang === "en" ? "Submit" : "सबमिट"}
                      // onClick={handleFormSubmit}
                      // disabled={!allvalid}
                      // style={{background: (allvalid ? "" :"#616161"), cursor: (allvalid ? pointer : "default")}}
                      id="add-more-evidence"
                      onClick={() =>
                        setShowSubmitModal({
                          state: true,
                          triggeredFrom: "last",
                        })
                      }
                      disabled={step5Allvalid ? false : true}
                    />
                    <input
                      type="button"
                      name="previous"
                      className="previous action-button-previous"
                      defaultValue={lang === "en" ? "Previous" : "पिछला"}
                    />
                    <input
                      type="button"
                      name="next"
                      id="hidden-submit-btn"
                      className="next action-button"
                      defaultValue={lang === "en" ? "Submit" : "सबमिट"}
                      style={{ display: "none" }}
                    />
                  </fieldset>
                  <fieldset>
                    <div className="form-card">
                      <div className="row">
                        <div className="col-7">
                          <h2 className="fs-title">
                            {lang === "en" ? "Finish:" : "खत्म"}
                          </h2>
                        </div>
                        <div className="col-5">
                          <h2 className="steps">
                            {/* {lang === "en" ? "Step 5 - 5" : "चरण 5 - 5"} */}
                          </h2>
                        </div>
                      </div>{" "}
                      <br />
                      <br />
                      <h2 className="purple-text text-center">
                        <strong>
                          {lang === "en"
                            ? submitpass
                              ? "SUCCESS !"
                              : "FAILED !"
                            : submitpass
                            ? "सफल !"
                            : "असफल !"}{" "}
                        </strong>
                      </h2>{" "}
                      <br />
                      <div className="row justify-content-center">
                        <div className="col-3" style={{ textAlign: "center" }}>
                          {" "}
                          {submitpass ? (
                            <i
                              className="fa-solid fa-check"
                              style={{ fontSize: 100, color: "#009688" }}
                            />
                          ) : (
                            <i
                              className="fa-solid fa-xmark"
                              style={{ fontSize: 100, color: "#b02a37" }}
                            />
                          )}{" "}
                        </div>
                      </div>{" "}
                      <br />
                      <br />
                      <div className="row justify-content-center">
                        <div className="col-7 text-center">
                          <h5
                            className="purple-text text-center"
                            dangerouslySetInnerHTML={{ __html: submitMsg }}
                          ></h5>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComplaintForm;
