import './Blog.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, Route, Routes } from 'react-router-dom';

const Blog = ({blogs}) => {
  const [category, setCategory] = useState({});
  const [nblogs, setNBlogs] = useState(4);
  const [tags, setTags] = useState([])
  const [totalBlogs, setTotalBlogs] = useState(blogs.length);
  const [recentBlogs, setRecentBlogs] = useState([])

  function isMobileOrTablet() {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
  }

  const mapTags = (tags) => {
    return tags.map((t,i)=>({
      id: t.id,
      sid: i+1,
      name: t.name
    }))
  }

  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_API}/blogs/getTags`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.response?.length) {
          const data = mapTags(res.data.response)
          setTags([...data])
        }
      } else {
        setTags([])
      }
    })
    .catch((err) => {
      console.log(err)
    });

    axios
    .get(`${process.env.REACT_APP_API}/blogs/getRecent`)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.response?.length) {
          setRecentBlogs([...res.data.response])
        }
      } else {
        setRecentBlogs([])
      }
    })
    .catch((err) => {
      console.log(err)
    });
  }, [])

  const share = (e) => {
    Array.from(document.getElementsByClassName('share-icons')).map(
      (ele, ind) => {
        if (ind === e.target.id - 1 && !ele.classList.contains('active')) {
          ele.classList.add('active');
        } else if (ele.classList.contains('active')) {
          ele.classList.remove('active');
        } else {
          ele.classList.remove('active');
        }
      }
    );
  };
  const getBlogs = (type) => {
    return type.map((blog, ind) => {
      if (nblogs > ind)
        return (
          <div className="card" key={blog.id}>
            <div className="card-left">
              <div
                className="card-img"
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_API}/blogs/demo/${blog.demoImageId}${blog.demoImageExt})`,
                }}
              >
                <div className="floating-share">
                  <button className="share-btn" id="share-btn">
                    <i
                      className="fa fa-share-alt"
                      id={ind+1}
                      onClick={share}
                    ></i>
                  </button>
                  <div className="share-icons">
                    <a
                      className="share-icon"
                      href={
                        'https://twitter.com/share?url=' +
                        encodeURIComponent(
                          window.origin + '/blog/' + blog.shareLink
                        )
                      }
                      target="_blank"
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a
                      className="share-icon"
                      href={
                        'https://www.facebook.com/share.php?u=' +
                        encodeURIComponent(
                          window.origin + '/blog/' + blog.shareLink
                        )
                      }
                      target="_blank"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a
                      className="share-icon"
                      href={
                        'https://www.linkedin.com/shareArticle?url=' +
                        encodeURIComponent(
                          window.origin + '/blog/' + blog.shareLink
                        )
                      }
                      target="_blank"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                    <a
                      className="share-icon"
                      href={
                        'whatsapp://send?text=' +
                        encodeURIComponent(
                          String(window.origin + '/blog/' + blog.shareLink)
                        )
                      }
                      data-action="share/whatsapp/share"
                      target="_blank"
                    >
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-right">
              <Link
                className="card-title fw-7"
                style={{ cursor: 'pointer' }}
                to={'/blog/' + blog.blogURL}
              >
                {blog.title}
              </Link>
              {/*<div className="card-text fw-5">{blog.shortDesp}</div>*/}
              <div className="card-right-body">
                <div className="card-rb-1">
                  <i className="fas fa-user-alt fa-2x"></i>
                </div>
                <div className="card-rb-2 ">
                  <div className="card-rb-2-title fw-7">{blog.authors}</div>
                  <div className="card-rb-2-text fw-5">
                    {new Date(blog.date).toLocaleDateString('en-US', {
                      year: 'numeric', month: 'long', day: 'numeric'
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    });
  };

  const handleCategory = ({sid, name}) => {
    const elements = document.getElementsByClassName('ctg');

    for (let i = 0; i < elements.length; i++) {
      if (sid === i + 1 && sid !== category.id) {
        elements[i].style.backgroundColor = '#009688';
      } else {
        elements[i].style.backgroundColor = 'cadetblue';
      }
    }
    setNBlogs(4);
    if (sid === category.id) {
      setCategory({});
    } else {
      setCategory({
        id: sid,
        name
      });
    }
  };

  useEffect(()=>{
    const len = (blogs.filter(b=>b?.tag?.includes(category.name))).length
    // Might be no tags are present then also it will be empty
    if (len===0 && blogs.length) {
      setTotalBlogs(blogs.length);
    } else{
      setTotalBlogs(len);
    }
  }, [category, blogs])

  return (
    <div
      className="blog-container"
      data-aos="fade-up"
      data-aos-offset="100"
      data-aos-easing="ease"
      data-aos-duration="500"
    >
      <div className="blog_container">
        <div className="blog_content">
          <div className="left_content">
            {
              totalBlogs===blogs.length ? getBlogs(blogs) : getBlogs(blogs.filter(b=>b?.tag?.includes(category.name)))
            }
          </div>
          {nblogs >= totalBlogs ? (
            <button className="btn1 load-btn" disabled>
              No more blogs
            </button>
          ) : (
            <button
              className="btn1 load-btn"
              onClick={() => {
                setNBlogs(nblogs + 4);
              }}
            >
              Load more
            </button>
          )}
        </div>

        <div className="blog_content right_content">
          <div className="columns categories">
            <span className="title">Categories</span>
            <section>
              {
                tags.map((tag)=> {
                  return <a
                    href="#"
                    onClick={() => handleCategory(tag)}
                    className="ctg"
                  >
                    {tag.name}
                  </a>
                })
              }
            </section>
          </div>

          <div className="columns posts">
            <span className="title">Recent Posts</span>
            <section>
              {
                recentBlogs.map((blog) => (
                <Link
                  to={'/blog/' + blog.blogURL}
                >
                  <img
                    src={`${process.env.REACT_APP_API}/blogs/demo/${blog.demoImageId}${blog.demoImageExt}`}
                    alt=""
                    loading="lazy"
                  />{' '}
                  <div>
                    <p>
                      {blog.title}
                    </p>
                  </div>
                </Link>
                ))
              }
            </section>
          </div>

          {/*  <div className="columns archives">
            <span className="title">Archives</span>
            <section>
              <ul>
                <li>Jan 2023</li>
                <li>Feb 2023</li>
              </ul>
            </section>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Blog;
