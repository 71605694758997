import React, { useState, useEffect } from 'react';
import locale from '../locale/mapping.json';

const Modals = ({ lang }) => {
  const [content, setContent] = useState(locale[lang]);

  useEffect(() => {
    setContent(locale[lang]);
  }, [lang]);

  return (
    <>
      {/* about_readmore */}
      <div
        className="modal fade"
        id="about_readmore"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: '#00796B' }}
            >
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ color: 'white', fontWeight: '600' }}
              >
                {content.context.heading}
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: 'justify' }}>
              <i> {content.context.d1}</i> <br />
              <ul>
                <li style={{ paddingLeft: '1.5em' }}>
                  <i>{content.context.d2}</i>
                </li>
              </ul>
              <br />
              <br />
              {content.context.d3}
              <br />
              <br />
              {content.context.d4}
              <br />
              <ul>
                <li style={{ paddingLeft: '1.5em' }}>{content.context.d5}</li>
                <li style={{ paddingLeft: '1.5em' }}>
                  {content.context.d6}
                  <ul>
                    <li style={{ paddingLeft: '1.5em' }}>
                      {content.context.d7}
                    </li>
                    <li style={{ paddingLeft: '1.5em' }}>
                      {content.context.d8}
                    </li>
                  </ul>
                </li>
              </ul>
              <br />
              <br />
              {content.context.d9}
              <br />
              <br />
              {content.context.d10}
            </div>
          </div>
        </div>
      </div>
      {/* confidential */}
      <div
        className="modal fade"
        id="confidential"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: '#00796B' }}
            >
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ color: 'white', fontWeight: '600' }}
              >
                {content.footer.d3}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ alignSelf: 'self-end', padding: '10px' }}
              ></button>
            </div>

            <div className="modal-body" style={{ textAlign: 'justify' }}>
              {content.confidentiality.p}
            </div>
          </div>
        </div>
      </div>
      {/* presence */}
      <div
        className="modal fade"
        id="presence"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: '#00796B' }}
            >
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ color: 'white', fontWeight: '600' }}
              >
                {content.footer.d4}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ alignSelf: 'self-end', padding: '10px' }}
              ></button>
            </div>

            <div className="modal-body" style={{ textAlign: 'justify' }}>
              {content.presence.p}
            </div>
          </div>
        </div>
      </div>
      {/* privacyPolicy */}
      <div
        className="modal fade"
        id="privacyPolicy"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: '#00796B' }}
            >
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ color: 'white', fontWeight: '600' }}
              >
                {content.privacyPolicy.h1}
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: 'justify' }}>
              <h4 style={{ marginBottom: '.3em' }}>
                {content.privacyPolicy.h1}
              </h4>
              <p>{content.privacyPolicy.p1}</p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>
                {content.privacyPolicy.h2}
              </h4>
              <p>{content.privacyPolicy.p2}</p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>
                {content.privacyPolicy.h3}
              </h4>
              <p>{content.privacyPolicy.p3}</p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>
                {content.privacyPolicy.h4}
              </h4>
              <p>{content.privacyPolicy.p4}</p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>
                {content.privacyPolicy.h5}
              </h4>
              <p>{content.privacyPolicy.p5}</p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>
                {content.privacyPolicy.h6}
              </h4>
              <p>{content.privacyPolicy.p6}</p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>
                {content.privacyPolicy.h7}
              </h4>
              <p>{content.privacyPolicy.p7}</p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>
                {content.privacyPolicy.h8}
              </h4>
              <p>{content.privacyPolicy.p8}</p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>
                {content.privacyPolicy.h9}
              </h4>
              <p>{content.privacyPolicy.p9}</p>
            </div>
          </div>
        </div>
      </div>
      {/* how_it_works */}
      <div
        className="modal fade"
        id="how_it_works"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: '#00796B' }}
            >
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ color: 'white', fontWeight: '600' }}
              >
                How it Works
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: 'justify' }}>
              <div className="modal_img">
                <img src="images/how_it_works.jpeg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* qa1 */}
      {/* <div
        className="modal fade"
        id="qa1"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#00796B" }}
            >
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ color: "white", fontWeight: "600" }}
              >
                Q & A
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: "justify" }}>
              <h3>1. {content.faq.q2}</h3>
              <p>{content.faq.a2}</p>
              <br />
              <h3>2. {content.faq.q5}</h3>
              <p>{content.faq.a5p1}</p>
              <ul>
                <li>
                  <p>
                    <b>{content.faq.a5p2h1}</b>
                    {content.faq.a5p2}
                  </p>
                </li>
                <li>
                  <p>
                    <b>{content.faq.a5p3h1}</b>
                    {content.faq.a5p3}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
      {/* qa2 */}
      <div
        className="modal fade"
        id="qa2"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: '#00796B' }}
            >
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ color: 'white', fontWeight: '600' }}
              >
                Q & A
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: 'justify' }}>
              <h3>1. {content.faq.q3}</h3>
              <p>{content.faq.a3}</p>
              <br />
              <h3>2. {content.faq.q7}</h3>
              <p>{content.faq.a7}</p>
            </div>
          </div>
        </div>
      </div>
      {/* qa3 */}
      <div
        className="modal fade"
        id="qa3"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: '#00796B' }}
            >
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ color: 'white', fontWeight: '600' }}
              >
                Q & A
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: 'justify' }}>
              <h3>1. {content.faq.q4}</h3>
              <p>{content.faq.a4p1}</p>
              <ul
                style={{
                  lineHeight: '1.6em',
                  fontSize: '14px',
                  color: 'rgba(1, 1, 1, 0.7)',
                }}
              >
                <li style={{ paddingLeft: '1.5em' }}>{content.faq.a4l1}</li>
                <li style={{ paddingLeft: '1.5em' }}>{content.faq.a4l2}</li>
                <li style={{ paddingLeft: '1.5em' }}>{content.faq.a4l3}</li>
                <li style={{ paddingLeft: '1.5em' }}>{content.faq.a4l4}</li>
                <li style={{ paddingLeft: '1.5em' }}>{content.faq.a4l5}</li>
              </ul>
              <br />
              <h3>2. {content.faq.q1}</h3>
              <p>{content.faq.a3}</p>
            </div>
          </div>
        </div>
      </div>
      {/* qa4 */}
      <div
        className="modal fade"
        id="qa4"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: '#00796B' }}
            >
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ color: 'white', fontWeight: '600' }}
              >
                Q & A
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body" style={{ textAlign: 'justify' }}>
              <h3>1. {content.faq.q9}</h3>
              <p>{content.faq.a9p1}</p>
              <p>{content.faq.a9p2}</p>
              <p>{content.faq.a9p3}</p>
            </div>
          </div>
        </div>
      </div>
      {/* qa5 */}
      <div
        className="modal fade"
        id="qa5"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: '#00796B' }}
            >
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ color: 'white', fontWeight: '600' }}
              >
                Q & A
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: 'justify' }}>
              <h3>1. {content.faq.q10}</h3>
              <p>{content.faq.a10p1}</p>
              <p>{content.faq.a10p2}</p>
              <p>{content.faq.a10p3}</p>
              <br />
              <h3>2. {content.faq.q11}</h3>
              <p>{content.faq.a11p1}</p>
              <p>{content.faq.a11p2}</p>
            </div>
          </div>
        </div>
      </div>
      {/* qa6 */}
      <div
        className="modal fade"
        id="qa6"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: '#00796B' }}
            >
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ color: 'white', fontWeight: '600' }}
              >
                Q & A
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: 'justify' }}>
              <h3>1. {content.faq.q6}</h3>
              <p>{content.faq.a6p1}</p>
              <p>{content.faq.a6p2}</p>
            </div>
          </div>
        </div>
      </div>
      {/* qa7 */}
      <div
        className="modal fade"
        id="qa7"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: '#00796B' }}
            >
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ color: 'white', fontWeight: '600' }}
              >
                Q & A
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: 'justify' }}>
              <h3>1. {content.faq.q12}</h3>
              <p>{content.faq.a12p1}</p>
              <p>{content.faq.a12p2}</p>
              <br />
              <h3>2. {content.faq.q13}</h3>
              <p>{content.faq.a11p1}</p>
            </div>
          </div>
        </div>
      </div>
      {/* qa8 */}
      <div
        className="modal fade"
        id="qa8"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: '#00796B' }}
            >
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ color: 'white', fontWeight: '600' }}
              >
                Q & A
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: 'justify' }}>
              <h3>1. {content.faq.q14}</h3>
              <p>{content.faq.a14p1}</p>
              <p>{content.faq.a14p2}</p>
            </div>
          </div>
        </div>
      </div>
      {/* Terms of Use */}
      <div
        className="modal fade"
        id="termsOfUse"
        data-bs-backdrop="static"
        data-bs-keyboard="true"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: '#00796B' }}
            >
              <h5
                className="modal-title"
                id="staticBackdropLabel"
                style={{ color: 'white', fontWeight: '600' }}
              >
                {content.termsOfUse.heading}
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: 'justify' }}>
              <p>{content.termsOfUse.top_para}</p>
              <br />
              <h4
                style={{
                  fontWeight: '500',
                }}
              >
                {content.termsOfUse.b1}
              </h4>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>{content.termsOfUse.h1}</h4>
              <p>{content.termsOfUse.p1}</p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>{content.termsOfUse.h2}</h4>
              <p style={{ marginBottom: '.8em' }}>{content.termsOfUse.p2v1}</p>
              <p>{content.termsOfUse.p2v2}</p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>{content.termsOfUse.h3}</h4>
              <p style={{ color: 'red' }}>{content.termsOfUse.p3}</p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>{content.termsOfUse.h4}</h4>
              <p style={{ marginBottom: '.8em' }}>{content.termsOfUse.p4v1}</p>
              <p>{content.termsOfUse.p4v2}</p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>{content.termsOfUse.h5}</h4>
              <p style={{ marginBottom: '.8em' }}>{content.termsOfUse.p5v1}</p>
              <p style={{ marginBottom: '.8em' }}>{content.termsOfUse.p5v2}</p>
              <p style={{ marginBottom: '.8em' }}>{content.termsOfUse.p5v3}</p>
              <p style={{ marginBottom: '.8em' }}>{content.termsOfUse.p5v4}</p>
              <p style={{ marginBottom: '.8em', color: 'red' }}>
                {content.termsOfUse.p5v5}
              </p>
              <p style={{ marginBottom: '.8em' }}>{content.termsOfUse.p5v6}</p>
              <p style={{ marginBottom: '.8em' }}>{content.termsOfUse.p5v7}</p>
              <p style={{ marginBottom: '.8em' }}>{content.termsOfUse.p5v8}</p>
              <p>{content.termsOfUse.p5v9}</p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>{content.termsOfUse.h6}</h4>
              <p>
                {content.termsOfUse.p6v1}
                <span style={{ color: 'red' }}>{content.termsOfUse.p6v2}</span>
                {content.termsOfUse.p6v3}
              </p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>{content.termsOfUse.h7}</h4>
              <p style={{ marginBottom: '.8em' }}>{content.termsOfUse.p7v1}</p>
              <p style={{ marginBottom: '.8em' }}>{content.termsOfUse.p7v2}</p>
              <p style={{ marginBottom: '.8em', color: 'red' }}>
                {content.termsOfUse.p7v3}
              </p>
              <p style={{ color: 'red' }}>{content.termsOfUse.p7v4}</p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>{content.termsOfUse.h8}</h4>
              <p>{content.termsOfUse.p8}</p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>{content.termsOfUse.h9}</h4>
              <p>{content.termsOfUse.p9}</p>
              <br />
              <h4 style={{ marginBottom: '.3em' }}>{content.termsOfUse.h10}</h4>
              <p>
                {content.termsOfUse.p10v1}
                <span style={{ color: 'red' }}>{content.termsOfUse.p10v2}</span>
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Modals;
